import _ from "lodash";

/**
 * Initializes WPD Page Manager.
 */
function init(wpd, wpdWindow, wpdDocument, dataEntryVM) {
	wpd.PDFManager = class extends wpd.PageManager {
		constructor() {
			super();

			this.textLayer = wpdDocument.getElementById("pdf-text-layer");

			// add debounced version of class method
			this.renderTextLayer = _.debounce(
				this.#renderTextLayer.bind(this),
				300
			);

			// attach scroll listener to graphics container
			this.attachScrollHandler();
		}

		static lowQualityScale = 3;
		static highQualityScale = 6;

		textLayer;

		#currentScale = this.defaultScale;

		get #scale() {
			return this.#currentScale;
		}

		set #scale(newScale) {
			this.#currentScale = newScale;
		}

		get defaultScale() {
			return wpd.PDFManager.lowQualityScale;
		}

		get scaleFactor() {
			return (
				wpd.PDFManager.highQualityScale / wpd.PDFManager.lowQualityScale
			);
		}

		get currentScaleFactor() {
			return this.#scale / wpd.PDFManager.lowQualityScale;
		}

		resetScale() {
			this.#scale = this.defaultScale;
		}

		isHighQualityRender() {
			return this.currentScaleFactor > 1;
		}

		getPage(pageNumber) {
			return this.handle.getPage(pageNumber);
		}

		getPageLabels() {
			return this.handle.getPageLabels();
		}

		pageCount() {
			return this.handle.numPages;
		}

		renderHighQualityPage(pageNumber) {
			this.#scale = wpd.PDFManager.highQualityScale;
			return this.renderPage(pageNumber, true);
		}

		renderLowQualityPage(pageNumber) {
			this.#scale = wpd.PDFManager.lowQualityScale;
			return this.renderPage(pageNumber, true);
		}

		async switch(pageNumber = 1) {
			// reset scale
			this.resetScale();

			return await super.switch(pageNumber);
		}

		async _pageRenderer(page, resolve, reject) {
			const self = this;

			let viewport = page.getViewport({
				scale: this.#scale,
			});

			const $canvas = wpdDocument.createElement("canvas");
			const context = $canvas.getContext("2d");

			$canvas.width = viewport.width;
			$canvas.height = viewport.height;
			$canvas.style.width = viewport.width + "px";
			$canvas.style.height = viewport.height + "px";

			const renderContext = {
				canvasContext: context,
				viewport: viewport,
			};

			try {
				await page.render(renderContext).promise;

				let url = $canvas.toDataURL();
				await wpd.imageManager.loadFromURL(url, true);

				wpd.graphicsWidget.setRotation(self.getRotation());
				wpd.graphicsWidget.rotateAndResize();

				await this.renderTextLayer();

				resolve();
			} catch (error) {
				console.log(error);
				wpd.busyNote.close();
				reject(error);
			}
		}

		attachScrollHandler() {
			const container = wpdDocument.getElementById("graphicsContainer");

			container.addEventListener(
				"scroll",
				function () {
					this.updateTextLayerScroll();
				}.bind(this)
			);
		}

		updateTextLayerScroll() {
			const container = wpdDocument.getElementById("graphicsContainer");
			const canvasDiv = wpdDocument.getElementById("canvasDiv");

			const { scrollTop, scrollLeft } = container;
			const { top: containerTop, left: containerLeft } =
				container.getBoundingClientRect();
			const { top: canvasTop, left: canvasLeft } =
				canvasDiv.getBoundingClientRect();

			this.textLayer.style.top =
				canvasTop - containerTop + scrollTop + "px";
			this.textLayer.style.left =
				canvasLeft - containerLeft + scrollLeft + "px";
		}

		async #renderTextLayer() {
			const canvasDiv = wpdDocument.getElementById("canvasDiv");

			// clear text layer content
			this.textLayer.innerHTML = "";

			// setup textLayer position and dimensions
			this.textLayer.style.width = canvasDiv.clientWidth + "px";
			this.textLayer.style.height = canvasDiv.clientHeight + "px";

			// update scroll position
			this.updateTextLayerScroll();

			// use the viewport to set the correct scale
			const page = await this.getPage(this.currentPage());
			const viewport = page.getViewport({
				rotation: wpd.graphicsWidget.getRotation(),
				scale: this.#scale * wpd.graphicsWidget.getZoomRatio(),
			});

			// Pass the data to the method for rendering of text over the pdf canvas.
			if (!dataEntryVM.isUnmounted) {
				return wpdWindow.pdfjsLib.renderTextLayer({
					textContentStream: page.streamTextContent(),
					container: this.textLayer,
					viewport: viewport,
					textDivs: [],
					enhanceTextSelection: true,
				}).promise;
			}
		}
	};
}

export { init };
