<template>
	<v-app class="overflow-y-hidden">
		<template v-if="!appReady">
			<div class="app-progress-container">
				<v-progress-circular
					indeterminate
					size="48"
					color="primary"
				/>
			</div>
		</template>
		<template v-else>
			<v-app-bar
				id="dactyl-header"
				color="white"
				height="84"
				style="padding: 0px 16px"
			>
				<div class="d-flex align-start">
					<v-img
						alt="Dactyl Logo"
						class="shrink cursor-pointer"
						src="@/assets/images/dactyl_logo.png"
						transition="scale-transition"
						width="56"
						eager
						@click="redirectToDashboard"
					/>
				</div>

				<h1 class="dactyl-logo-text font-weight-bold text-primary">
					DACTYL
				</h1>

				<div class="d-sm-block d-none mx-3 text-primary">by</div>

				<div class="d-sm-flex d-sm-flex d-none align-start">
					<v-img
						alt="Human Predictions Logo"
						class="shrink mr-2 mb-2"
						src="@/assets/images/human-predictions-logo-150px.png"
						transition="scale-transition"
						eager
						width="150"
					/>
				</div>
				<v-spacer />

				<v-container
					v-if="isAuthenticated"
					class="d-flex justify-end pa-0 align-center"
					height="100%"
				>
					<v-btn
						v-if="inTraining || inDataEntry"
						class="bg-primary mr-2"
						elevation="2"
						:disabled="saving || validating"
						:loading="saving || validating"
						@click="triggerDataEntryValidate"
					>
						Validate
					</v-btn>

					<v-btn
						v-if="inTraining || inDataEntry"
						class="bg-primary mr-2"
						elevation="2"
						:disabled="saving"
						:loading="saving"
						@click="triggerDataEntrySave"
					>
						Save
					</v-btn>

					<v-btn
						v-if="inQualityControl"
						class="bg-primary mr-2"
						elevation="2"
						:disabled="qcSaving"
						:loading="qcSaving"
						@click="triggerQualityControlSave"
					>
						Save
					</v-btn>

					<v-btn
						v-if="inTraining || inDataEntry"
						class="bg-primary mr-2"
						elevation="2"
						:disabled="saving"
						:loading="saving"
						@click="triggerSaveExit"
					>
						Save & Close
					</v-btn>

					<v-btn
						v-if="inQualityControl"
						class="bg-primary mr-2"
						elevation="2"
						:disabled="qcSaving"
						:loading="qcSaving"
						@click="triggerQualityControlSaveExit"
					>
						Save & Close
					</v-btn>

					<v-dialog
						v-if="!inTraining && (inDataEntry || inQualityControl)"
						v-model="saveAndCompleteDialog"
						max-width="600"
					>
						<template #activator="{ props }">
							<v-btn
								v-bind="props"
								class="bg-primary"
								:class="{ 'mr-2': isAdmin }"
								:disabled="saving || qcSaving"
								:loading="saving || qcSaving"
								elevation="2"
							>
								Save & Mark Completed
							</v-btn>
						</template>

						<v-card
							class="pb-5"
							:disabled="!!saving"
							:loading="saving"
						>
							<v-toolbar
								color="primary"
								dark
								flat
							>
								<v-toolbar-title>
									Save & Mark Completed
								</v-toolbar-title>

								<v-spacer />

								<v-tooltip location="bottom">
									<template #activator="{ props }">
										<v-btn
											v-bind="props"
											icon
											size="large"
											@click="close"
										>
											<v-icon>mdi-close</v-icon>
										</v-btn>
									</template>

									<span>Close</span>
								</v-tooltip>
							</v-toolbar>

							<v-card-text
								class="pa-10 text-center text-subtitle-1"
							>
								{{ saveAndCompleteText }}
							</v-card-text>

							<v-card-actions>
								<v-spacer />

								<v-btn
									v-if="inDataEntry"
									class="mr-3 bg-secondary text-white"
									elevation="2"
									@click="triggerSaveDone"
								>
									Mark Complete
								</v-btn>
								<v-btn
									v-if="inQualityControl"
									class="mr-3 bg-secondary text-white"
									elevation="2"
									@click="triggerSaveDoneQC"
								>
									Mark Complete
								</v-btn>

								<v-btn
									class="mr-3 bg-grey"
									elevation="2"
									@click="close"
								>
									Cancel
								</v-btn>

								<v-spacer />
							</v-card-actions>
						</v-card>
					</v-dialog>

					<v-dialog
						v-if="inDataEntry && isAdmin"
						v-model="discardDataDialog"
						max-width="600"
					>
						<template #activator="{ props }">
							<v-btn
								v-bind="props"
								:disabled="saving"
								:loading="saving"
								class="bg-primary"
								elevation="2"
							>
								Discard Data
							</v-btn>
						</template>

						<v-card
							class="pb-5"
							:disabled="!!saving"
							:loading="saving"
						>
							<v-toolbar
								color="primary"
								dark
								flat
							>
								<v-toolbar-title>
									Discard Extracted Data
								</v-toolbar-title>
								<v-spacer />
								<v-tooltip location="bottom">
									<template #activator="{ props }">
										<v-btn
											v-bind="props"
											icon
											size="large"
											@click="closeDiscardDialog"
										>
											<v-icon>mdi-close</v-icon>
										</v-btn>
									</template>

									<span>Close</span>
								</v-tooltip>
							</v-toolbar>

							<v-card-text
								class="pa-10 text-center text-subtitle-1"
							>
								Are you sure you want to discard extracted data
								in this paper? This action cannot be undone.
							</v-card-text>

							<v-card-actions>
								<v-spacer />

								<v-btn
									class="bg-secondary text-white"
									elevation="2"
									@click="triggerDiscard"
								>
									Discard Extracted Data
								</v-btn>

								<v-btn
									elevation="2"
									@click="closeDiscardDialog"
								>
									Cancel
								</v-btn>

								<v-spacer />
							</v-card-actions>
						</v-card>
					</v-dialog>

					<v-dialog
						v-if="inTraining"
						v-model="resetDataDialog"
						max-width="600"
					>
						<template #activator="{ props }">
							<v-btn
								v-bind="props"
								:disabled="saving"
								:loading="saving"
								class="bg-primary"
								elevation="2"
							>
								Reset Data
							</v-btn>
						</template>

						<v-card
							class="pb-5"
							:disabled="!!saving"
							:loading="saving"
						>
							<v-toolbar
								color="primary"
								dark
								flat
							>
								<v-toolbar-title>Reset Data</v-toolbar-title>
								<v-spacer />
								<v-tooltip location="bottom">
									<template #activator="{ props }">
										<v-btn
											v-bind="props"
											icon
											size="large"
											@click="closeResetDialog"
										>
											<v-icon>mdi-close</v-icon>
										</v-btn>
									</template>

									<span>Close</span>
								</v-tooltip>
							</v-toolbar>

							<v-card-text
								class="pa-10 text-center text-subtitle-1"
							>
								Are you sure you want to reset extracted data in
								this training paper? This action cannot be
								undone.
							</v-card-text>

							<v-card-actions>
								<v-spacer />

								<v-btn
									class="bg-secondary text-white"
									elevation="2"
									@click="triggerReset"
								>
									Reset Data
								</v-btn>

								<v-btn
									elevation="2"
									@click="closeResetDialog"
								>
									Cancel
								</v-btn>

								<v-spacer />
							</v-card-actions>
						</v-card>
					</v-dialog>

					<v-divider
						v-if="inTraining || inDataEntry || inQualityControl"
						class="mx-5"
						inset
						vertical
					/>
				</v-container>

				<v-login
					v-if="!isAuthenticated"
					ref="login"
					@activate-activity-timer="activateActivityTracker"
				/>

				<v-logout
					v-else
					ref="logout"
					@deactivate-activity-timer="deactivateActivityTracker"
				/>

				<v-dialog
					v-model="userActivityDialog"
					max-width="600"
				>
					<v-card class="pb-5">
						<v-toolbar
							color="primary"
							dark
							flat
						>
							<v-toolbar-title>Session timeout</v-toolbar-title>
						</v-toolbar>

						<v-spacer />

						<v-card-text class="pa-10 text-center">
							Your session will timeout in
							{{ countdownString }}.
							<br />
							<br />
							Are you still there?
						</v-card-text>

						<v-card-actions>
							<v-spacer />

							<v-btn
								class="bg-secondary text-white"
								elevation="2"
								@click="userStillActive"
							>
								Yes, I am
							</v-btn>

							<v-spacer />
						</v-card-actions>
					</v-card>
				</v-dialog>

				<user-manual-dialog ref="userManual" />
			</v-app-bar>

			<v-notification />

			<v-main>
				<v-banner
					v-if="isAuthenticated && showBanner"
					class="bg-primary"
					lines="one"
					:text="systemMessage"
				>
					<template #prepend>
						<v-icon
							color="secondary"
							icon="mdi-cookie"
							size="30"
						></v-icon>
					</template>
					<template #actions>
						<v-btn
							class="mb-1 bg-secondary text-white"
							elevation="2"
							@click="dismissBanner('cookie')"
						>
							Acknowledge
						</v-btn>
					</template>
				</v-banner>

				<router-view
					ref="router"
					v-model:login="login"
					:show-banner="showBanner"
					@token="handleToken"
					@enter-data-entry-training="inTraining = true"
					@leave-data-entry-training="inTraining = false"
					@enter-data-entry="inDataEntry = true"
					@leave-data-entry="inDataEntry = false"
					@enter-quality-control="inQualityControl = true"
					@leave-quality-control="inQualityControl = false"
					@activate-activity-timer="activateActivityTracker"
					@deactivate-activity-timer="deactivateActivityTracker"
					@check-dirty="
						(checkDirtyMethod) => (checkDirty = checkDirtyMethod)
					"
					@handle-logout-save="
						(logoutSaveMethod) => (logoutSave = logoutSaveMethod)
					"
				/>
			</v-main>
		</template>
	</v-app>
</template>

<script>
import _ from "lodash";
import { mapMutations } from "vuex";
import { useLDReady } from "launchdarkly-vue-client-sdk";

import auth from "@/auth";

import Timer from "@/assets/scripts/session-timer.worker.js";

import VLogin from "@/components/VLogin";
import VLogout from "@/components/VLogout";
import VNotification from "@/components/VNotification";

import UserManualDialog from "@/pages/UserManualDialog";

const cookieCheckInterval = 30000; // 30 sec
const countdownWait = 300000; // 5 mins
const userActivityDebounceWait = 500; // 0.5 sec
const userInactiveWait = 600000; // 10 mins

// Prevent vue3 ResizeObserver errors with a debounce
const debounce = (callback, delay) => {
	let tid;
	return function (...args) {
		const ctx = this;
		if (tid) clearTimeout(tid);
		tid = setTimeout(() => {
			callback.apply(ctx, args);
		}, delay);
	};
};

const OriginalResizeObserver = window.ResizeObserver;
window.ResizeObserver = class extends OriginalResizeObserver {
	constructor(callback) {
		super(debounce(callback, 20));
	}
};

export default {
	name: "App",

	components: {
		UserManualDialog,
		VLogin,
		VLogout,
		VNotification,
	},

	data() {
		return {
			checkDirty: null,
			confirmationText: "Are you sure you want to log out?",
			countdown: -1,
			countdownInterval: null,
			currentDialog: {},
			discardDataDialog: false,
			// elements that are targets for dialog dragging
			draggableClasses: ["drag-handle"],
			triggerLogin: false,
			inDataEntry: false,
			inTraining: false,
			inQualityControl: false,
			isAdmin: false,
			login: false,
			logoutSave: null,
			resetDataDialog: false,
			timer: null,
			saveAndCompleteDialog: false,
			saveAndCompleteText: `
				Click “Mark Complete” to finish this document. You will not be able to edit this document any longer.
			`,
			sessionInterval: null,
			sessionLastChecked: null,
			systemMessage: `
				Dactyl uses cookies to improve user experience. By continuing to use this website, you agree to our use of
				Strictly Necessary cookies.
			`,
			userActivityDialog: false,
			userActivityTimeout: null,
		};
	},

	computed: {
		appReady() {
			return useLDReady();
		},
		countdownString() {
			return this.millisecondsToMinutesAndSeconds(this.countdown);
		},
		help() {
			return this.$store.getters.help;
		},
		isAuthenticated() {
			return this.$store.getters.isAuthenticated;
		},
		qcSaving() {
			return this.$store.getters.qcSave;
		},
		saving() {
			return this.$store.getters.saveInProgress;
		},
		user() {
			return this.$store.getters.user;
		},
		validating() {
			return this.$store.getters.validate;
		},
		showBanner() {
			return !this.user.cookie_notice_dismissed_at;
		},
	},

	watch: {
		login(val) {
			if (val) {
				this.$refs.login.open();

				// consume the signal
				this.login = false;
			}
		},
		help(val) {
			if (val) {
				this.$refs.userManual.open(val);
			} else {
				// close the manual
				this.$refs.userManual.close();
			}
		},
	},

	async created() {
		if (this.isAuthenticated) {
			await this.isAdminUser();
		}
	},

	mounted() {
		// Record the active session, then ping minutely to record activity
		// this.activityTimer = setInterval(() => {
		// 	this.$http.post("/users/record-active-session");
		// }, 60000);

		this.sessionLastChecked = new Date().getTime();

		// check auth cookie at regular interval
		this.sessionInterval = setInterval(
			() => this.checkCookie(),
			cookieCheckInterval
		);

		if (!this.isAuthenticated) {
			// do not run the user activity tracker when not authenticated
			this.deactivateActivityTracker();
		} else {
			// refresh the session
			this.refreshSession();

			// start the user activity tracker
			this.activateActivityTracker();
		}

		// make vuetify dialogs draggable
		this.initializeDialogDrag();
	},

	beforeUnmount() {
		this.deactivateActivityTracker();

		this.sessionInterval = null;
	},

	methods: {
		...mapMutations([
			"clearHelp",
			"reset",
			"sendValidate",
			"sendSave",
			"setAuthenticated",
			"setUser",
			"showNotification",
		]),
		close: function () {
			this.saveAndCompleteDialog = false;
		},
		closeDiscardDialog: function () {
			this.discardDataDialog = false;
		},
		closeResetDialog: function () {
			this.resetDataDialog = false;
		},
		redirectToDashboard() {
			this.$router.push("/home/dashboard");
		},
		dialogMousedownHandler(event) {
			const closestDialog = event.target.closest(".v-overlay__content");
			if (
				event.button === 0 &&
				closestDialog != null &&
				this.isDraggable(event.target)
			) {
				event.target.classList.add("grabbing");

				// element that should be moved
				this.currentDialog.el = closestDialog;

				// element that was clicked on
				this.currentDialog.handle = event.target;

				this.currentDialog.mouseStartX = event.clientX;
				this.currentDialog.mouseStartY = event.clientY;

				this.currentDialog.elStartX =
					this.currentDialog.el.getBoundingClientRect().left;
				this.currentDialog.elStartY =
					this.currentDialog.el.getBoundingClientRect().top;

				this.currentDialog.el.style.margin = 0;
				this.currentDialog.el.style.position = "fixed";
				this.currentDialog.el.style.transition = "none";

				this.currentDialog.oldTransition =
					this.currentDialog.el.style.transition;
			}
		},
		dialogMousemoveHandler(event) {
			if (this.currentDialog.el === undefined) {
				return;
			}

			this.currentDialog.el.style.left =
				Math.min(
					Math.max(
						this.currentDialog.elStartX +
							event.clientX -
							this.currentDialog.mouseStartX,
						0
					),
					window.innerWidth -
						this.currentDialog.el.getBoundingClientRect().width
				) + "px";
			this.currentDialog.el.style.top =
				Math.min(
					Math.max(
						this.currentDialog.elStartY +
							event.clientY -
							this.currentDialog.mouseStartY,
						0
					),
					window.innerHeight -
						this.currentDialog.el.getBoundingClientRect().height
				) + "px";

			this.currentDialog.handle.classList.add("grabbing");
		},
		dialogMouseupHandler() {
			if (this.currentDialog.el === undefined) {
				return;
			}

			this.currentDialog.el.style.transition =
				this.currentDialog.oldTransition;
			this.currentDialog.el = undefined;

			this.currentDialog.handle.classList.remove("grabbing");
		},
		initializeDialogDrag() {
			document.removeEventListener(
				"mousedown",
				this.dialogMousedownHandler
			);
			document.removeEventListener(
				"mousemove",
				this.dialogMousemoveHandler
			);
			document.removeEventListener("mouseup", this.dialogMouseupHandler);

			document.addEventListener("mousedown", this.dialogMousedownHandler);
			document.addEventListener("mousemove", this.dialogMousemoveHandler);
			document.addEventListener("mouseup", this.dialogMouseupHandler);

			setInterval(() => {
				// prevent out of bounds
				const dialog = document.querySelector(
					".v-dialog.v-dialog--active"
				);

				if (dialog === null) {
					return;
				}

				dialog.style.left =
					Math.min(
						parseInt(dialog.style.left, 10),
						window.innerWidth - dialog.getBoundingClientRect().width
					) + "px";
				dialog.style.top =
					Math.min(
						parseInt(dialog.style.top, 10),
						window.innerHeight -
							dialog.getBoundingClientRect().height
					) + "px";
			}, 100);
		},
		isDraggable(element) {
			for (const draggableClass of this.draggableClasses) {
				if (element.classList.contains(draggableClass)) {
					return true;
				}
			}

			return false;
		},
		triggerDataEntryValidate: function () {
			this.sendValidate();
		},
		triggerDataEntrySave: function () {
			this.sendSave({ type: "data_entry" });
		},
		triggerQualityControlSave: function () {
			this.sendSave({ type: "quality_control" });
		},
		triggerSaveExit: function () {
			this.sendSave({
				exit: true,
				type: "data_entry",
			});
		},
		triggerQualityControlSaveExit: function () {
			this.sendSave({
				exit: true,
				type: "quality_control",
			});
		},
		triggerSaveDone: function () {
			this.close();

			this.sendSave({
				done: true,
				exit: true,
				type: "data_entry",
			});
		},
		triggerSaveDoneQC: function () {
			this.close();

			this.sendSave({
				done: true,
				exit: true,
				type: "quality_control",
			});
		},
		startSessionTimeoutCountdown: function () {
			// stop auto-save timer if user currently in data extraction
			if (this.inDataEntry) {
				const currentComponent =
					this.$router?.currentRoute?.value?.matched[0]?.components
						?.default;

				if (currentComponent) {
					currentComponent.methods.deactivateAutoSave();
				}
			}
			this.countdown = countdownWait;

			this.userActivityDialog = true;

			this.timer.postMessage({
				action: "start_countdown",
				duration: countdownWait,
				interval: 1000,
			});
		},
		resetUserActivityTimer: _.debounce(function () {
			// if past time threshold since last check, check auth cookie
			const elapsed = new Date().getTime() - this.sessionLastChecked;
			if (elapsed > cookieCheckInterval) {
				this.checkCookie();
			}

			this.timer?.postMessage({
				action: "start_timer",
				// wait for 10 mins before starting the dialog countdown
				duration: userInactiveWait,
			});
		}, userActivityDebounceWait), // start inactivity timer 5 secs after last movement
		checkCookie: function () {
			// skip cookie check for unauthed routes
			if (this.$route.meta && this.$route.meta.guest) {
				return;
			}

			this.sessionLastChecked = new Date().getTime();

			this.$http.get("/ping").finally(async () => {
				if (!auth.isAuthenticated()) {
					this.setAuthenticated(false);
					await this.autoLogout();
				}
			});
		},
		iframeMessageHandler: function (event) {
			if (
				["mousemove", "scroll", "keydown", "resize"].includes(
					event.data
				)
			) {
				this.resetUserActivityTimer();
			}
		},
		refreshSessionTimeoutCountdown: async function () {
			this.countdown -= 1000;

			if (this.countdown < 1) {
				await this.autoLogout();
			}
		},
		activateActivityTracker: function () {
			this.deactivateActivityTracker();

			// create the worker
			this.timer = new Timer();

			// listen for timer messages
			this.timer.onmessage = ({ data }) => {
				// if not authenticated, stop the timer and close the dialog
				if (!this.isAuthenticated) {
					this.deactivateActivityTracker();
					this.userActivityDialog = false;
					return;
				}

				// threshold reached for countdown dialog
				if (data === "timed_out") {
					this.startSessionTimeoutCountdown();
				}

				// process countdown ticks
				if (data === "countdown_tick") {
					this.refreshSessionTimeoutCountdown();
				}

				// escape hatch
				if (data === "timer_elapsed" || data === "countdown_elapsed") {
					this.countdown = 0;
					this.refreshSessionTimeoutCountdown();
				}
			};

			window.addEventListener("mousemove", this.resetUserActivityTimer);
			window.addEventListener("scroll", this.resetUserActivityTimer);
			window.addEventListener("keydown", this.resetUserActivityTimer);
			window.addEventListener("resize", this.resetUserActivityTimer);

			window.addEventListener("message", this.iframeMessageHandler);
		},
		deactivateActivityTracker: function () {
			window.removeEventListener(
				"mousemove",
				this.resetUserActivityTimer
			);
			window.removeEventListener("scroll", this.resetUserActivityTimer);
			window.removeEventListener("keydown", this.resetUserActivityTimer);
			window.removeEventListener("resize", this.resetUserActivityTimer);

			window.removeEventListener("message", this.iframeMessageHandler);

			// clear session timeout and countdown
			this.timer?.postMessage({ action: "stop_all" });

			// terminate the worker
			this.timer?.terminate();
			this.timer = null;
		},
		userStillActive: function () {
			this.userActivityDialog = false;

			// refresh session
			this.refreshSession();

			// start auto-save timer if user currently in data extraction
			if (this.inDataEntry) {
				const currentComponent =
					this.$router?.currentRoute?.value?.matched[0]?.components
						?.default;

				if (currentComponent) {
					currentComponent.methods.activateAutoSave();
				}
			}

			// stop all clocks
			this.timer.postMessage({ action: "stop_all" });

			// restart the activity timer
			this.resetUserActivityTimer();
		},
		refreshSession() {
			this.$http
				.post("/refresh-session")
				.catch((error) => console.error(error));
		},
		millisecondsToMinutesAndSeconds: function (ms) {
			if (ms < 0) {
				return "[invalid value]";
			} else {
				const minutes = Math.floor(ms / 60000);
				const seconds = ((ms % 60000) / 1000).toFixed(0);

				return seconds === 60
					? minutes + 1 + ":00"
					: minutes + ":" + (seconds < 10 ? "0" : "") + seconds;
			}
		},
		dismissBanner(type) {
			if (type === "cookie") {
				// save user cookie banner dismissal
				this.$http
					.post("/users/record-action", { type })
					.then((response) => {
						const payload = response.data.payload;

						// update logged in user
						this.setUser(payload.user);
					})
					.catch(() => {
						// do not display error
					});
			}
		},
		handleToken(token) {
			this.$refs.login.login(token);
		},

		async triggerReset() {
			await new Promise(
				function (resolve) {
					this.sendSave({
						resolve: resolve,
						type: "reset_data_entry",
					});
				}.bind(this)
			);
			return this.closeResetDialog();
		},
		async triggerDiscard() {
			await new Promise(
				function (resolve) {
					this.sendSave({
						resolve: resolve,
						type: "discard_data_entry",
					});
				}.bind(this)
			);
			return this.closeDiscardDialog();
		},
		async triggerSave() {
			if (this.checkDirty) {
				const dirty = this.checkDirty();
				if (dirty) {
					//TODO: When QC is implemented, the new autosave behavior will need to be added via
					// a "checkDirty" and "logoutSave" function; leaving the previous code here as reference
					if (this.logoutSave) {
						await this.logoutSave();
					} else {
						return new Promise(
							function (resolve) {
								const payload = { resolve: resolve };
								if (this.inDataEntry) {
									payload.type = "data_entry";
								} else if (this.inQualityControl) {
									payload.type = "quality_control";
								}

								this.sendSave(payload);
							}.bind(this)
						);
					}
				}
			}

			return new Promise((resolve) => resolve());
		},
		async autoLogout() {
			// save work
			if (this.isAuthenticated) {
				await this.triggerSave();
			}

			// clear cookie and store
			this.reset();

			// close countdown window
			this.userActivityDialog = false;
			this.inDataEntry = false;
			this.inQualityControl = false;
			this.inTraining = false;

			// stop timers
			this.timer?.postMessage({ action: "stop_all" });

			// redirect to login page
			if (this.isAuthenticated) {
				this.$refs.logout.logout();
			} else {
				this.$router.push("/login");
				this.showNotification({
					message: "Session authentication expired",
				});
			}

			return Promise.resolve();
		},
		async isAdminUser() {
			await this.$http
				.get(`/users/${this.user.uuid}/is-admin`)
				.then((response) => {
					this.isAdmin = response.data;
				});
		},
	},
};
</script>

<style lang="scss">
html {
	overflow-y: auto;
}

.dactyl-logo-text {
	letter-spacing: 1px;
}

@mixin overrides {
	color: #f58634 !important;
	caret-color: #f58634 !important;
}

// override for some components that do not adhere to given options
.v-application .primary--text {
	&.v-input,
	&.v-list-item {
		@include overrides;

		.primary--text {
			@include overrides;
		}
	}
}

.v-data-table-header .text-center.sortable {
	padding-right: 2px;
}

.cursor-pointer {
	cursor: pointer;
}

.cursor-default {
	cursor: default !important;
}

.cursor-not-allowed {
	cursor: not-allowed !important;
}

.v-stepper.v-stepper--alt-labels {
	.v-stepper__label {
		text-align: center !important;
	}
}

.v-menu__content.theme--light.v-small-dialog__menu-content {
	margin-top: 30px;
}

.drag-handle {
	cursor: grab;

	&.grabbing {
		cursor: grabbing;
	}
}

.v-banner__actions {
	margin-bottom: 0px;
	margin-right: 8px;
}

.v-banner__prepend {
	height: 100%;
	display: flex;
	align-items: center;
}

.app-progress-container {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
}
</style>
