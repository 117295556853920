import { JSONPath } from "jsonpath-plus";

function base64ToBlob(base64Data, contentType = "", sliceSize = 512) {
	const byteCharacters = atob(base64Data);
	const byteArrays = [];

	for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
		const slice = byteCharacters.slice(offset, offset + sliceSize);

		const byteNumbers = new Array(slice.length);
		for (let i = 0; i < slice.length; i++) {
			byteNumbers[i] = slice.charCodeAt(i);
		}

		const byteArray = new Uint8Array(byteNumbers);
		byteArrays.push(byteArray);
	}

	const blob = new Blob(byteArrays, { type: contentType });
	return blob;
}

function isNumber(key) {
	if (!["number", "string"].includes(typeof key)) {
		return false;
	}

	if (typeof key === "string" && key.trim() === "") {
		return false;
	}

	return !isNaN(key);
}

function truncateText(text, maxLength = 50) {
	if (text.length <= maxLength) {
		return text;
	}
	const half = Math.floor((maxLength - 3) / 2);
	const start = text.slice(0, half);
	const end = text.slice(text.length - half);
	return `${start}...${end}`;
}

function queryJsonByUUID({ uuid, data, parentLevel }) {
	const pathQuery =
		`$..items[?(@.uuid === "${uuid}")]` + "^".repeat(parentLevel);
	return JSONPath({
		path: pathQuery,
		json: data,
	})[0];
}

function queryJsonByType({ type, data }) {
	return JSONPath({
		path: `$..items[?(@.type === "${type}")]`,
		json: data,
	});
}

async function compressJson(json) {
	const jsonBytes = new TextEncoder().encode(json);
	const compressedStream = new Blob([jsonBytes])
		.stream()
		.pipeThrough(new CompressionStream("gzip"));

	const compressedBlob = await new Response(compressedStream).blob();
	const compressedBuffer = await compressedBlob.arrayBuffer();
	const uint8Array = new Uint8Array(compressedBuffer);

	let binaryString = "";
	for (let i = 0; i < uint8Array.length; i++) {
		binaryString += String.fromCharCode(uint8Array[i]);
	}

	const base64Compressed = btoa(binaryString);
	return Promise.resolve(base64Compressed);
}

async function decompressJson(json) {
	const binaryString = atob(json);
	const bytes = new Uint8Array(binaryString.length);
	for (let i = 0; i < binaryString.length; i++) {
		bytes[i] = binaryString.charCodeAt(i);
	}
	const decompressedStream = new Blob([bytes])
		.stream()
		.pipeThrough(new DecompressionStream("gzip"));

	const decompressedBlob = await new Response(decompressedStream).blob();
	const decompressedText = await decompressedBlob.text();

	return Promise.resolve(decompressedText);
}

class Cancellable {
	constructor(executor) {
		this.promise = new Promise((resolve, reject) => {
			this._reject = reject;
			return executor(resolve, reject);
		});
	}

	cancel() {
		this._reject(new Error("Promise cancelled"));
	}
}

export default {
	Cancellable,
	base64ToBlob,
	isNumber,
	truncateText,
	queryJsonByType,
	queryJsonByUUID,
	compressJson,
	decompressJson,
};
