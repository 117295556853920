function apply(wpd, wpdDocument, elementIds) {
	addXYGraphEditSidebar(wpdDocument, elementIds.editXYGraphSidebar);
	addXYGraphAdjustPointsSidebar(
		wpdDocument,
		elementIds.editXYGraphAdjustPointsSidebar
	);
	addXYGraphSetValuesDialog(wpdDocument);
}

function addXYGraphEditSidebar(wpdDocument, sidebarId) {
	// get the default calibration sidebar
	const axisSidebar = wpdDocument.getElementById("axes-calibration-sidebar");

	// insert table axis calibration sidebar
	axisSidebar.insertAdjacentHTML(
		"beforebegin",
		`
		<div id="${sidebarId}" class="sidebar" style="display: none;">
			<p class="sidebar-title">Edit XY Graph</p>
			<p align="left" style="font-weight: bold;">Axis Points</p>
			<p align="left">Edit the selected graph's axis points:</p>
			<p align="left" style="margin-top:5px">
				<input
					type="button"
					value="Adjust positions"
					style="display:block; margin-bottom: 5px; margin-top: 5px;"
					onclick="wpd.xyGraphEdit.adjustAxisPoints();"
				>
				<input
					type="button"
					value="Set data types"
					style="display:block; margin-bottom: 5px; margin-top: 5px;"
					onclick="wpd.xyGraphEdit.openSetDataTypesDialog();"
				>
				<input
					type="button"
					value="Set values"
					style="display:block; margin-bottom: 5px; margin-top: 5px;"
					onclick="wpd.xyGraphEdit.openSetAxisPointValuesDialog();"
				>
			</p>
			<p align="left" style="margin-top:20px; font-weight: bold;">Study Branches</p>
			<p align="left">Edit the study branches associated with the selected graph:</p>
			<p align="left" style="margin-top:5px">
				<input
					type="button"
					value="Set study branches"
					style="display:block; margin-bottom: 5px; margin-top: 5px;"
					onclick="wpd.xyGraphEdit.openSetStudyBranchesDialog();"
				>
			</p>
			<p align="left" style="margin-top:30px;">Note that adjustments can impact existing extracted data for this graph.</p>
			<p align="left" style="margin-top:20px">
				<input
					type="button"
					value="Close"
					style="display:block; margin-bottom: 5px; margin-top: 5px;"
					onclick="wpd.xyGraphEdit.closeXYGraphEdit();"
				>
			</p>			
		</div>
	`
	);
}

function addXYGraphAdjustPointsSidebar(wpdDocument, sidebarId) {
	// get the default calibration sidebar
	const axisSidebar = wpdDocument.getElementById("axes-calibration-sidebar");

	// insert table axis calibration sidebar
	axisSidebar.insertAdjacentHTML(
		"beforebegin",
		`
		<div id="${sidebarId}" class="sidebar" style="display: none;">
   			<p class="sidebar-title">Adjust Axis Points</p>
    		<p>Click points to select and use cursor keys to adjust positions. Use Shift+Arrow for faster movement. Click complete when finished.</p>
			<br>
			<p>Note that adjusting axis positions can impact the values of existing data points.</p>
    		<p align="center"><input type="button" value="Complete" style="width: 120px;" onclick="wpd.xyGraphEdit.completeAdjustAxisPoints();" /></p>
		</div>
	`
	);
}

function addXYGraphSetValuesDialog(wpdDocument) {
	wpdDocument.getElementById("point-group-settings-popup").insertAdjacentHTML(
		"afterend",
		`
		<div id="xy-graph-set-values-dialog" class="popup" style="width: 650px; left: 533px; top: 60px;">
			<div class="popupheading">X and Y Axes Calibration</div>
			<p>&nbsp;</p>
			<p align="center">Enter X-values of the two points clicked on X-axis and Y-values of the two points clicked on Y-axes</p>
			<center>
				<table padding="10">
					<tr>
						<td></td>
						<td align="center" valign="bottom">Point 1</td>
						<td align="center" valign="bottom" width="80">Point 2</td>
						<td align="center" valign="bottom" width="82">Log Scale</td>
					</tr>
					<tr>
						<td align="center">X-Axis:</td>
						<td align="center"><input type="text" size="8" id="xy-edit-xmin" value="0" onfocus="wpd.xyGraphEdit.zoomCalibrationPoint(0);" /></td>
						<td align="center"><input type="text" size="8" id="xy-edit-xmax" value="1" onfocus="wpd.xyGraphEdit.zoomCalibrationPoint(1);" /></td>
						<td align="center"><input type="checkbox" id="xy-edit-xlog"></td>
					</tr>
					<tr>
						<td align="center">Y-Axis:</td>
						<td align="center"><input type="text" size="8" id="xy-edit-ymin" value="0" onfocus="wpd.xyGraphEdit.zoomCalibrationPoint(2);" /></td>
						<td align="center"><input type="text" size="8" id="xy-edit-ymax" value="1" onfocus="wpd.xyGraphEdit.zoomCalibrationPoint(3);" /></td>
						<td align="center"><input type="checkbox" id="xy-edit-ylog" /></td>
					</tr>
				</table>
				<span class="xy-edit-log-validation-message" hidden>Values on a log scale axis can not be zero, since log(0) is undefined</span>
				<span class="xy-edit-validation-message" hidden>Please enter valid values</span>
				<br />
				<p align="center"><label><input type="checkbox" id="xy-edit-axes-no-rotation" />Assume axes are perfectly aligned with image coordinates (skip rotation correction)</label></p>
				<br />
				<p align="center" class="footnote">*For dates, use yyyy/mm/dd hh:ii:ss format, where ii denotes minutes (e.g. 2013/10/23 or 2013/10 or 2013/10/23 10:15 or just 10:15). For exponents, enter values as 1e-3 for 10^-3.</p>
				<p>&nbsp;</p>
				<p align="center">Note that updating axis values will impact the values of existing data points.</p>
				<br />
				<p align="center">
					<input type="button" value="OK" onclick="wpd.xyGraphEdit.confirmSetAxisPointValues();" />
					<input type="button" value="Cancel" onclick="wpd.xyGraphEdit.closeSetAxisPointValuesDialog();" />
				</p>
			</center>
		</div>`
	);
}

export { apply };
