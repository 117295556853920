<template>
	<v-row
		align-content="start"
		justify="start"
	>
		<v-col cols="12">TODO</v-col>
	</v-row>
</template>

<script>
export default {
	name: "DataViewer",
	components: {},
};
</script>

<style scoped lang="scss"></style>
