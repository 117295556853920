/**
 * Initializes the manual selection tool in WPD.
 */
function init(wpd, wpdDocument) {
	// cannot pick and choose functions to override due to how it was written
	// completely overwrite the manual selection tool into a js class
	wpd.TextSelectionTool = class {
		constructor(resolve, keydownHandler) {
			this.resolve = resolve;
			this.keydownHandler = keydownHandler;
			this.textLayer = wpdDocument.getElementById("pdf-text-layer");
			this.selectedText = "";
		}

		#enableTextLayer() {
			this.textLayer.style["z-index"] = 10;
		}

		#disableTextLayer() {
			this.textLayer.style["z-index"] = "auto";
		}

		#addEventListeners() {
			this.#removeEventListeners();
			this.textLayer.addEventListener("mouseup", this.#onMouseUp);
			wpdDocument.addEventListener("keydown", this.#onKeyDown);
		}

		#removeEventListeners() {
			this.textLayer.removeEventListener("mouseup", this.#onMouseUp);
			wpdDocument.removeEventListener("keydown", this.#onKeyDown);
		}

		#textSelected() {
			this.resolve(this.selectedText);

			this.detach();
		}

		// fat-arrow function allows "this" to be the instance of this class
		// instead of having it deteremined by the caller
		#onMouseUp = () => {
			const selection = wpdDocument.getSelection();
			this.selectedText = selection
				.toString()
				.trim()
				.replace(/\s+/g, " ")
				.replace(/-\s/g, "-");

			if (this.selectedText.length > 0) {
				// handle selected text
				this.#textSelected();

				// clear the selection
				selection.empty();
			}
		};

		#onKeyDown = (event) => {
			this?.keydownHandler(event);
		};

		attach() {
			this.#addEventListeners();

			this.#enableTextLayer();
		}

		detach() {
			this.#disableTextLayer();

			this.#removeEventListeners();
		}
	};
}

export { init };
