const createCompositeKeyUtils = (tags) => {
	const compositeKeyDelimiter = "||";
	const referenceArmTag = "Reference study arm abbreviation";

	const getCompositeKeyTags = (tags) => {
		return tags
			.filter((tag) => tag.isCompositeKeyMember)
			.sort(
				(tagA, tagB) => tagA.compositeKeyIndex - tagB.compositeKeyIndex
			);
	};

	const compTags = getCompositeKeyTags(tags);

	const studyTag = compTags[0].name;
	const studyPartTag = compTags[1].name;
	const studyArmTag = compTags[2].name;
	const populationTag = compTags[3].name;
	const subgroupTag = compTags[4].name;

	const branchTags = [
		studyTag,
		studyPartTag,
		studyArmTag,
		populationTag,
		subgroupTag,
	];

	const tagKeyMap = {
		[studyTag]: 0,
		[studyPartTag]: 1,
		[studyArmTag]: 2,
		[populationTag]: 3,
		[subgroupTag]: 4,
	};

	const studyKey = "study";
	const studyPartKey = "studyPart";
	const studyArmKey = "studyArm";
	const populationKey = "population";
	const subgroupKey = "subgroup";

	const parsedKeys = [
		studyKey,
		studyPartKey,
		studyArmKey,
		populationKey,
		subgroupKey,
	];

	const studyTagGroup = "Study";
	const studyPartTagGroup = "Study part";
	const studyArmTagGroup = "Study arm";
	const populationTagGroup = "Population";
	const subgroupTagGroup = "Subgroup";
	const branchTagGroups = [
		studyTagGroup,
		studyPartTagGroup,
		studyArmTagGroup,
		populationTagGroup,
		subgroupTagGroup,
	];

	// Constructs a composite key
	const generateCompositeKey = (
		studyKey,
		studyPartKey,
		studyArmKey,
		populationKey,
		subgroupKey
	) => {
		return (
			(studyKey ? `${studyKey}` : ``) +
			(studyPartKey
				? ` ${compositeKeyDelimiter} ${studyPartKey}`
				: ` ${compositeKeyDelimiter}`) +
			(studyArmKey
				? ` ${compositeKeyDelimiter} ${studyArmKey}`
				: ` ${compositeKeyDelimiter}`) +
			(populationKey
				? ` ${compositeKeyDelimiter} ${populationKey}`
				: ` ${compositeKeyDelimiter}`) +
			(subgroupKey
				? ` ${compositeKeyDelimiter} ${subgroupKey}`
				: ` ${compositeKeyDelimiter} `)
		);
	};

	// Parses a composite key in an object; e.g.
	// {
	//   study: "study1",
	//   studyPart: "part1",
	//   studyArm: "arm1"
	//   population: "pop1",
	//   subgroup: "subgroup1"
	// }
	const parseCompositeKey = (key) => {
		const splitKey = splitCompositeKey(key);
		return {
			key,
			[studyKey]: splitKey[tagKeyMap[studyTag]],
			[studyPartKey]: splitKey[tagKeyMap[studyPartTag]],
			[studyArmKey]: splitKey[tagKeyMap[studyArmTag]],
			[populationKey]: splitKey[tagKeyMap[populationTag]],
			[subgroupKey]: splitKey[tagKeyMap[subgroupTag]],
		};
	};

	// Splits a composite key into a list of individual keys
	const splitCompositeKey = (key) => {
		return key.split(compositeKeyDelimiter).map((part) => part.trim());
	};

	// Joins a list of keys into a composite key string
	const joinCompositeKey = (keyParts) => {
		return keyParts
			.map((part, index) => {
				if (!part) return " ";
				if (index === 0) return `${part} `;
				if (index === keyParts.length - 1) return ` ${part}`;
				return ` ${part} `;
			})
			.join(`${compositeKeyDelimiter}`);
	};

	// True if key1 is a "child" of key2; e.g.
	// "study1 || part1 || " is a child of "study1 || || "
	// "study1 || part1 || arm1" is a child of "study1 || part1 || "
	// "study1 || part1 || " is NOT a child of "study1 || part1 || arm1"
	// "study1 || || arm1" is a child of "study1 || || "
	// "study1 || || arm1" is NOT a child of "study1 || part1 || arm1"
	const isChildKey = (key1, key2) => {
		const child = parseCompositeKey(key1);
		const parent = parseCompositeKey(key2);

		for (const key of parsedKeys) {
			if (!parent[key] && !child[key]) {
				continue;
			}

			// If key is present in both but the values different, then key1
			// is not a child
			if (parent[key] && child[key] && parent[key] !== child[key]) {
				return false;
			}

			// If key is present in key2 but not key1, then key1 is not a child
			if (parent[key] && !child[key]) {
				return false;
			}
		}

		return true;
	};

	// Modifies a single value in a composite key, and returns the updated key
	const updateKey = (key, tag, value) => {
		if (!Object.keys(tagKeyMap).includes(tag))
			throw new Error(
				`Tag '${tag}' is not a valid part of a composite key`
			);

		const splitKey = splitCompositeKey(key);
		splitKey[tagKeyMap[tag]] = value;
		return joinCompositeKey(splitKey);
	};

	const getValueAtBranch = (key, tag) => {
		if (!Object.keys(tagKeyMap).includes(tag))
			throw new Error(
				`Tag '${tag}' is not a valid part of a composite key`
			);

		const splitKey = splitCompositeKey(key);
		return splitKey[tagKeyMap[tag]];
	};

	const isStudy = (key) => {
		const { study, studyPart, studyArm, population, subgroup } =
			parseCompositeKey(key);
		return !!study && !studyPart && !studyArm && !population && !subgroup;
	};

	const isStudyPart = (key) => {
		const { studyPart, studyArm, population, subgroup } =
			parseCompositeKey(key);
		return !!studyPart && !studyArm && !population && !subgroup;
	};

	const isStudyArm = (key) => {
		const { studyArm, population, subgroup } = parseCompositeKey(key);
		return !!studyArm && !population && !subgroup;
	};

	const isStudyPopulation = (key) => {
		const { population, subgroup } = parseCompositeKey(key);
		return !!population && !subgroup;
	};

	const isStudySubgroup = (key) => {
		const { subgroup } = parseCompositeKey(key);
		return !!subgroup;
	};

	const isEmpty = (key) => {
		const { study, studyPart, studyArm, population, subgroup } =
			parseCompositeKey(key);
		return !study && !studyPart && !studyArm && !population && !subgroup;
	};

	const isBranchTag = (tagName) => {
		return branchTags.includes(tagName);
	};
	const isReferenceTag = (tagName) => {
		return tagName === referenceArmTag;
	};

	const getTagGroupName = (key) => {
		const { study, studyPart, studyArm, population, subgroup } =
			parseCompositeKey(key);

		if (subgroup) {
			return subgroupTagGroup;
		} else if (population) {
			return populationTagGroup;
		} else if (studyArm) {
			return studyArmTagGroup;
		} else if (studyPart) {
			return studyPartTagGroup;
		} else if (study) {
			return studyTagGroup;
		}
	};

	// Gets the most specific value from a composite key; for example,
	// if the key is "study1 || part1 || arm1 || pop1 || ", then the value
	// would be "pop1"
	const getCompositeKeyValue = (compositeKey) => {
		const parsedKey = parseCompositeKey(compositeKey);

		if (parsedKey.subgroup) {
			return parsedKey.subgroup;
		} else if (parsedKey.population) {
			return parsedKey.population;
		} else if (parsedKey.studyArm) {
			return parsedKey.studyArm;
		} else if (parsedKey.studyPart) {
			return parsedKey.studyPart;
		} else {
			return parsedKey.study;
		}
	};

	return {
		compositeKeyDelimiter,
		studyTag,
		studyPartTag,
		studyArmTag,
		populationTag,
		subgroupTag,
		referenceArmTag,
		branchTags,
		branchTagGroups,
		tagKeyMap,
		studyKey,
		studyPartKey,
		studyArmKey,
		populationKey,
		subgroupKey,
		parsedKeys,
		generateCompositeKey,
		splitCompositeKey,
		joinCompositeKey,
		parseCompositeKey,
		isChildKey,
		updateKey,
		getValueAtBranch,
		isStudy,
		isStudyPart,
		isStudyArm,
		isStudyPopulation,
		isStudySubgroup,
		isEmpty,
		isBranchTag,
		isReferenceTag,
		getTagGroupName,
		getCompositeKeyValue,
	};
};

export default createCompositeKeyUtils;
