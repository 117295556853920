import * as Elements from "./elements.js";

function init(wpd, wpdDocument, dataEntryVM) {
	wpd.barGraphEdit = {};

	let calibrator = null;

	const elementIds = {
		editBarGraphSidebar: "edit-bar-graph-sidebar",
		editBarGraphAdjustBarAxisPointsSidebar:
			"edit-bar-graph-adjust-bar-axis-points-sidebar",
		editBarGraphAdjustCategoricalAxisPointsSidebar:
			"edit-bar-graph-adjust-categorical-axis-points-sidebar",
		editBarGraphSetBarAxisValuesDialog:
			"edit-bar-graph-set-bar-axis-values-dialog",
		editBarGraphSetBarAxisDatatypesDialog: "bar-graph-axes-label-popup",
		editBarGraphAddCategorySidebar: "edit-bar-graph-add-category-dialog",
		editBarGraphRemoveCategorySidebar:
			"edit-bar-graph-remove-category-dialog",
	};

	Elements.apply(wpd, wpdDocument, elementIds);

	const unselectPoints = function () {
		const axis = wpd.tree.getActiveAxes();
		const metadata = axis.getMetadata();
		if (metadata.barHeaders) {
			metadata.barHeaders.p1.isSelected = false;
			metadata.barHeaders.p2.isSelected = false;
		}

		axis.calibration.unselectAll();
		axis.setMetadata(metadata);

		calibrator = new wpd.BarAxesCalibrator(axis.calibration, true);
		calibrator.reload();
	};

	const resetRepainter = function () {
		const axis = wpd.tree.getActiveAxes();
		const plotData = wpd.appData.getPlotData();
		const datasets = plotData.getDatasetsForAxis(axis);
		const axes = Array(datasets.length).fill(axis);
		wpd.graphicsWidget.setRepainter(
			new wpd.MultipltDatasetRepainter(axes, datasets)
		);
	};

	wpd.barGraphEdit.adjustBarAxisPoints = function () {
		unselectPoints();
		const axis = wpd.tree.getActiveAxes();

		wpd.graphicsWidget.removeTool();
		wpd.graphicsWidget.removeRepainter();
		wpd.graphicsWidget.resetData();

		wpd.graphicsWidget.setTool(
			new wpd.AxesCornersTool(axis.calibration, true)
		);
		wpd.graphicsWidget.setRepainter(
			new wpd.AlignmentCornersRepainter(axis.calibration)
		);
		wpd.graphicsWidget.forceHandlerRepaint();

		wpd.sidebar.clear();
		wpd.sidebar.show(elementIds.editBarGraphAdjustBarAxisPointsSidebar);
	};

	wpd.barGraphEdit.completeAdjustBarAxisPoints = function () {
		const axis = wpd.tree.getActiveAxes();
		const metadata = axis.getMetadata();

		updateGraphPointsWithAxisPointValues();

		wpd.graphicsWidget.removeTool();
		wpd.graphicsWidget.removeRepainter();
		wpd.graphicsWidget.resetData();

		wpd.graphicsWidget.setTool(
			new wpd.BarHeadersTool(axis, dataEntryVM, true)
		);

		resetRepainter();
		wpd.barGraphEdit.rebuildDividersAndHeaders(axis, metadata);
		wpd.graphicsWidget.forceHandlerRepaint();

		wpd.sidebar.clear();
		wpd.sidebar.show(elementIds.editBarGraphSidebar);
	};

	wpd.barGraphEdit.adjustCategoricalAxisPoints = function () {
		unselectPoints();
		const axis = wpd.tree.getActiveAxes();

		wpd.graphicsWidget.removeTool();
		wpd.graphicsWidget.removeRepainter();
		wpd.graphicsWidget.resetData();

		wpd.graphicsWidget.setTool(new wpd.BarCornersTool(axis));
		wpd.graphicsWidget.forceHandlerRepaint();

		wpd.sidebar.clear();
		wpd.sidebar.show(
			elementIds.editBarGraphAdjustCategoricalAxisPointsSidebar
		);
	};

	wpd.barGraphEdit.completeAdjustCategoricalAxisPoints = function () {
		const axis = wpd.tree.getActiveAxes();
		const metadata = axis.getMetadata();

		wpd.graphicsWidget.removeTool();
		wpd.graphicsWidget.removeRepainter();
		wpd.graphicsWidget.resetData();

		wpd.graphicsWidget.setTool(
			new wpd.BarHeadersTool(axis, dataEntryVM, true)
		);
		resetRepainter();
		wpd.barGraphEdit.rebuildDividersAndHeaders(axis, metadata);
		wpd.graphicsWidget.forceHandlerRepaint();

		wpd.sidebar.clear();
		wpd.sidebar.show(elementIds.editBarGraphSidebar);
	};

	wpd.barGraphEdit.rebuildDividersAndHeaders = function (axis, metadata) {
		wpd.barChartUtils.drawAxisLine(
			wpd.graphicsWidget.getAllContexts().dataCtx,
			axis,
			metadata
		);
		wpd.barChartUtils.buildHeaderDividers(axis, metadata, true);
		wpd.barChartUtils.drawHeaderDividers(
			wpd.graphicsWidget.getAllContexts().dataCtx,
			metadata.barHeaders.headerDividers
		);
		wpd.barChartUtils.buildHeaderAreas(axis, metadata, true);
		wpd.barChartUtils.drawHeaderGradients(
			metadata,
			wpd.graphicsWidget.getAllContexts().dataCtx
		);
	};

	wpd.barGraphEdit.openSetBarAxisValuesDialog = function () {
		setValueFieldValues();
		wpd.popup.show(elementIds.editBarGraphSetBarAxisValuesDialog);
	};

	wpd.barGraphEdit.completeSetBarAxisValues = function () {
		const axis = wpd.tree.getActiveAxes();
		saveAxisPointValues();
		updateGraphPointsWithAxisPointValues();
		wpd.graphicsWidget.removeTool();
		wpd.graphicsWidget.setTool(
			new wpd.BarHeadersTool(axis, dataEntryVM, true)
		);
		wpd.popup.close(elementIds.editBarGraphSetBarAxisValuesDialog);
	};

	wpd.barGraphEdit.cancelSetBarAxisValues = function () {
		wpd.popup.close(elementIds.editBarGraphSetBarAxisValuesDialog);
	};

	wpd.barGraphEdit.openSetBarAxisDatatypesDialog = function () {
		setDataTypeFieldValues();

		wpdDocument
			.getElementById("bar-graph-datatype-buttons")
			.setAttribute("hidden", "true");
		wpdDocument
			.getElementById("edit-bar-graph-datatype-buttons")
			.removeAttribute("hidden");

		wpd.alignAxes.initializeBarGraphFields();
		wpd.popup.show(elementIds.editBarGraphSetBarAxisDatatypesDialog);
	};

	wpd.barGraphEdit.completeSetBarAxisDatatypes = async function () {
		const dataTypes = wpd.alignAxes.saveAxesData(false);
		const axis = wpd.tree.getActiveAxes();

		updateGraphPointsWithAxisDataTypes(dataTypes);
		updateGraphPointsWithAxisPointValues();
		wpd.graphicsWidget.removeTool();
		wpd.graphicsWidget.setTool(
			new wpd.BarHeadersTool(axis, dataEntryVM, true)
		);
		wpd.graphicsWidget.forceHandlerRepaint();
	};

	wpd.barGraphEdit.cancelSetBarAxisDatatypes = function () {
		wpd.popup.close(elementIds.editBarGraphSetBarAxisDatatypesDialog);
	};

	wpd.barGraphEdit.addCategory = function () {
		wpd.sidebar.clear();
		wpd.sidebar.show(elementIds.editBarGraphAddCategorySidebar);

		const axis = wpd.tree.getActiveAxes();
		wpd.graphicsWidget.removeTool();
		wpd.graphicsWidget.resetData();
		wpd.graphicsWidget.forceHandlerRepaint();
		wpd.graphicsWidget.setTool(new wpd.AddBarHeaderTool(axis, dataEntryVM));
	};

	wpd.barGraphEdit.cancelAddCategory = function () {
		wpd.sidebar.clear();
		wpd.sidebar.show(elementIds.editBarGraphSidebar);

		const axis = wpd.tree.getActiveAxes();
		wpd.graphicsWidget.removeTool();
		wpd.graphicsWidget.resetData();
		wpd.graphicsWidget.forceHandlerRepaint();
		wpd.graphicsWidget.setTool(
			new wpd.BarHeadersTool(axis, dataEntryVM, true)
		);
	};

	wpd.barGraphEdit.removeCategory = function () {
		wpd.sidebar.clear();
		wpd.sidebar.show(elementIds.editBarGraphRemoveCategorySidebar);

		const axis = wpd.tree.getActiveAxes();
		wpd.graphicsWidget.removeTool();
		wpd.graphicsWidget.resetData();
		wpd.graphicsWidget.forceHandlerRepaint();

		wpd.graphicsWidget.setTool(
			new wpd.RemoveBarHeaderTool(axis, dataEntryVM)
		);
	};

	wpd.barGraphEdit.cancelRemoveCategory = function () {
		wpd.sidebar.clear();
		wpd.sidebar.show(elementIds.editBarGraphSidebar);

		const axis = wpd.tree.getActiveAxes();
		wpd.graphicsWidget.removeTool();
		wpd.graphicsWidget.resetData();
		wpd.graphicsWidget.forceHandlerRepaint();
		wpd.graphicsWidget.setTool(
			new wpd.BarHeadersTool(axis, dataEntryVM, true)
		);
	};

	wpd.barGraphEdit.openSetStudyBranchesDialog = function () {
		wpd.dataSeriesManagement.showAddDataset(true);
		setStudyBranchFieldValues();
	};

	wpd.barGraphEdit.closeBarGraphEdit = function () {
		const axis = wpd.tree.getActiveAxes();
		const calibration = axis.calibration;
		calibration.unselectAll();

		wpd.graphicsWidget.removeTool();
		wpd.graphicsWidget.removeRepainter();
		wpd.graphicsWidget.resetData();
		wpd.graphicsWidget.forceHandlerRepaint();
		wpd.sidebar.clear();
	};

	const setStudyBranchFieldValues = function () {
		let axis = wpd.tree.getActiveAxes();
		const datasets = wpd.appData.getPlotData().getDatasetsForAxis(axis);
		if (_.isEmpty(datasets)) {
			return;
		}

		for (let i = 0; i < datasets.length - 1; i++) {
			wpd.dataSeriesManagement.addRow();
		}
		const rowContainer = wpdDocument.getElementById(
			"add-study-branch-inputs"
		);

		const rows = Array.from(rowContainer.children);
		for (let i = 0; i < rows.length; i++) {
			const row = rows[i];
			const ds = datasets[i];
			const md = ds.getMetadata();
			const dsInput = row.querySelector(".add-dataset-study-arm-input");
			const tgInput = row.querySelector(".add-dataset-tag-group-input");
			dsInput.setAttribute(
				"data-composite-key",
				md.studyData.compositeKey
			);
			dsInput.value = wpd.CompositeKeyUtils.getCompositeKeyValue(
				md.studyData.compositeKey
			);
			tgInput.value = md.studyData.tagGroup.name;
		}
	};

	const saveAxisPointValues = function () {
		const values = {
			min: wpdDocument.getElementById("edit-bar-axes-p1").value,
			max: wpdDocument.getElementById("edit-bar-axes-p2").value,
			log: wpdDocument.getElementById("edit-bar-axes-log-scale").checked,
			rotated: wpdDocument.getElementById("edit-bar-axes-rotated")
				.checked,
		};
		let axis = wpd.tree.getActiveAxes();
		let calibration = axis.calibration;
		calibration.setDataAt(0, 0, values.min);
		calibration.setDataAt(1, 0, values.max);
		axis.calibrate(calibration, values.log, values.rotated);
	};

	const setValueFieldValues = function () {
		const axis = wpd.tree.getActiveAxes();
		const calibration = axis.calibration;
		wpdDocument.getElementById("edit-bar-axes-p1").value =
			calibration.getPoint(0).dy;
		wpdDocument.getElementById("edit-bar-axes-p2").value =
			calibration.getPoint(1).dy;
		wpdDocument.getElementById("edit-bar-axes-log-scale").checked =
			axis.isLog();
		wpdDocument.getElementById("edit-bar-axes-rotated").checked =
			axis.isRotated();
	};

	const setDataTypeFieldValues = function () {
		const axis = wpd.tree.getActiveAxes();
		const metadata = axis.getMetadata();
		const barData = metadata.axesLabelData.bar;
		wpdDocument.getElementById("bar-axis-data-type").value =
			barData.dataType;
		wpdDocument.getElementById("bar-axis-unit").value = barData.unit;
		wpdDocument.getElementById("bar-axis-change-from-baseline").checked =
			barData.changeFromBaseline;
		wpdDocument.getElementById("bar-axis-decimals").value =
			barData.decimals;
	};

	const updateGraphPointsWithAxisDataTypes = function (dataTypes) {
		let axis = wpd.tree.getActiveAxes();
		const datasets = wpd.appData.getPlotData().getDatasetsForAxis(axis);
		const changeFromBaselineTag = _.find(wpd.tagGroups.tags, {
			name: "Change from baseline",
		});
		const valueUnitTag = wpd.tagGroups.findTag({
			name: "Unit",
		});
		const timeUnitTag = wpd.tagGroups.findTag({
			name: "Time (unit)",
		});

		datasets.forEach((ds) => {
			const pixels = ds.getAllPixels();
			pixels.forEach((px) => {
				let maxIndex = 0;

				let hasChangeFromBaseline = false;
				const filteredMetadata = Object.keys(px.metadata).filter(
					(key) => !isNaN(key) && !_.isNil(px.metadata[key].axisType)
				);

				const axisKey = filteredMetadata.find(
					(key) => px.metadata[key].axis
				);
				const axis = px.metadata[axisKey].axis;

				filteredMetadata.forEach((key) => {
					const dp = px.metadata[key];
					maxIndex = Math.max(maxIndex, parseInt(key, 10));

					if (dp.axisType === "unit") {
						dp.tag =
							dataTypes.barAxisDataType === "Time (number)"
								? timeUnitTag
								: valueUnitTag;

						dp.value = dataTypes.barAxisUnit;
					} else if (dp.axisType === "value") {
						dp.tag = _.pick(
							_.find(wpd.tagGroups.tags, {
								name: dataTypes.barAxisDataType,
							}),
							["name", "uuid"]
						);

						dp.value = wpd.utils.toDecimalPlaces(
							dp.value,
							dataTypes.barAxisDecimals
						);
					} else if (dp.axisType === "changeFromBaseline") {
						hasChangeFromBaseline = true;
						dp.value = dataTypes.barAxisChangeFromBaseline
							? "Yes"
							: "No";
					}

					px.metadata[key] = dp;
				});

				if (
					dataTypes.barAxisChangeFromBaseline &&
					!hasChangeFromBaseline
				) {
					px.metadata[++maxIndex] = {
						tag: changeFromBaselineTag,
						value: "Yes",
						axis,
						axisType: "changeFromBaseline",
					};
				}
			});
		});
	};

	const updateGraphPointsWithAxisPointValues = function () {
		let axis = wpd.tree.getActiveAxes();
		const metadata = axis.getMetadata();
		const datasets = wpd.appData.getPlotData().getDatasetsForAxis(axis);
		datasets.forEach((ds) => {
			const pixels = ds.getAllPixels();
			pixels.forEach((px) => {
				const data = axis.pixelToData(px.x, px.y);
				Object.keys(px.metadata)
					.filter((key) => !isNaN(key))
					.forEach((key) => {
						const dp = px.metadata[key];
						if (dp.axis === "x" && dp.axisType === "value") {
							dp.value = wpd.utils.toDecimalPlaces(
								data[0],
								metadata?.axesLabelData?.x?.decimals ?? 2
							);
						} else if (dp.axis === "y" && dp.axisType === "value") {
							dp.value = wpd.utils.toDecimalPlaces(
								data[1],
								metadata?.axesLabelData?.y?.decimals ?? 2
							);
						}
					});
			});
		});
	};
}

export { init };
