function apply(wpd, wpdDocument, elementIds) {
	addBarGraphEditSidebar(wpdDocument, elementIds.editBarGraphSidebar);
	addBarGraphAdjustBarAxisPointsSidebar(
		wpdDocument,
		elementIds.editBarGraphAdjustBarAxisPointsSidebar
	);
	addBarGraphAdjustCategoricalAxisPointsSidebar(
		wpdDocument,
		elementIds.editBarGraphAdjustCategoricalAxisPointsSidebar
	);
	addBarGraphSetBarAxisValuesDialog(
		wpdDocument,
		elementIds.editBarGraphSetBarAxisValuesDialog
	);

	addBarGraphAddCategorySidebar(
		wpdDocument,
		elementIds.editBarGraphAddCategorySidebar
	);

	addBarGraphRemoveCategorySidebar(
		wpdDocument,
		elementIds.editBarGraphRemoveCategorySidebar
	);
}

function addBarGraphEditSidebar(wpdDocument, sidebarId) {
	// get the default calibration sidebar
	const axisSidebar = wpdDocument.getElementById("axes-calibration-sidebar");

	// insert table axis calibration sidebar
	axisSidebar.insertAdjacentHTML(
		"beforebegin",
		`
		<div id="${sidebarId}" class="sidebar" style="display: none;">
			<p class="sidebar-title">Edit Bar Graph</p>
			<p align="left" style="font-weight: bold;">Bar Axis</p>
			<p align="left">Edit the selected graph's bar axis points:</p>
			<p align="left" style="margin-top:5px">
				<input
					type="button"
					value="Adjust positions"
					onclick="wpd.barGraphEdit.adjustBarAxisPoints();"
				>
				<input
					type="button"
					value="Set data types"
					style="display:block; margin-bottom: 5px; margin-top: 5px;"
					onclick="wpd.barGraphEdit.openSetBarAxisDatatypesDialog();"
				>
				<input
					type="button"
					value="Set values"
					style="display:block; margin-bottom: 5px; margin-top: 5px;"
					onclick="wpd.barGraphEdit.openSetBarAxisValuesDialog();"
				>
			</p>
			<p align="left" style="font-weight: bold; margin-top: 5px;">Categorical Axis</p>
			<p align="left">Edit the selected graph's categorical axis points:</p>
			<p align="left" style="margin-top:5px">
				<input
					type="button"
					value="Adjust positions"
					style="display:block; margin-bottom: 5px;"
					onclick="wpd.barGraphEdit.adjustCategoricalAxisPoints();"
				>
				<input
					type="button"
					value="Add category"
					style="width: 120px;"
					onclick="wpd.barGraphEdit.addCategory();"
				>
				<input
					type="button"
					value="Remove category"
					style="width: 120px;"
					onclick="wpd.barGraphEdit.removeCategory();"
				>
			</p>
			<p align="left" style="margin-top:10px; font-weight: bold;">Study Branches</p>
			<p align="left">Edit the study branches associated with the selected graph:</p>
			<p align="left" style="margin-top:5px">
				<input
					type="button"
					value="Set study branches"
					style="display:block; margin-bottom: 5px;"
					onclick="wpd.barGraphEdit.openSetStudyBranchesDialog();"
				>
			</p>
			<p align="left" style="margin-top:20px;">Note that adjustments can impact existing extracted data for this graph.</p>
			<p align="left" style="margin-top:10px">
				<input
					type="button"
					value="Close"
					style="display:block; margin-bottom: 5px; margin-top: 5px;"
					onclick="wpd.barGraphEdit.closeBarGraphEdit();"
				>
			</p>			
		</div>
	`
	);
}

function addBarGraphAdjustBarAxisPointsSidebar(wpdDocument, sidebarId) {
	// get the default calibration sidebar
	const axisSidebar = wpdDocument.getElementById("axes-calibration-sidebar");

	// insert table axis calibration sidebar
	axisSidebar.insertAdjacentHTML(
		"beforebegin",
		`
		<div id="${sidebarId}" class="sidebar" style="display: none;">
			   <p class="sidebar-title">Adjust Bar Axis Points</p>
			<p>Click points to select and use cursor keys to adjust positions. Use Shift+Arrow for faster movement. Click complete when finished.</p>
			<br>
			<p>Note that adjusting axis positions can impact the values of existing data points.</p>
			<p align="center"><input type="button" value="Complete" style="width: 120px;" onclick="wpd.barGraphEdit.completeAdjustBarAxisPoints();" /></p>
		</div>
	`
	);
}

function addBarGraphAddCategorySidebar(wpdDocument, sidebarId) {
	// get the default calibration sidebar
	const axisSidebar = wpdDocument.getElementById("axes-calibration-sidebar");

	// insert table axis calibration sidebar
	axisSidebar.insertAdjacentHTML(
		"beforebegin",
		`
		<div id="${sidebarId}" class="sidebar" style="display: none;">
			<p class="sidebar-title">Add Bar Graph Category</p>
			<p>
				Move your cursor to the desired location on the graph, and click to add a new category at the highlighted area.
				<br><br>
				Click "Cancel" to return to the graph edit tool without adding a new category.
			</p>
			<br>
			<p align="center"><input type="button" value="Cancel" style="width: 120px;" onclick="wpd.barGraphEdit.cancelAddCategory();" /></p>
		</div>
	`
	);
}

function addBarGraphRemoveCategorySidebar(wpdDocument, sidebarId) {
	// get the default calibration sidebar
	const axisSidebar = wpdDocument.getElementById("axes-calibration-sidebar");

	// insert table axis calibration sidebar
	axisSidebar.insertAdjacentHTML(
		"beforebegin",
		`
		<div id="${sidebarId}" class="sidebar" style="display: none;">
			<p class="sidebar-title">Remove Bar Graph Category</p>
			<p>
				Move your cursor to the desired location on the graph, and click to remove the highlighted category.
				<br><br>
				Click "Cancel" to return to the graph edit tool without removing a category.
			</p>
			<br>
			<p align="center"><input type="button" value="Cancel" style="width: 120px;" onclick="wpd.barGraphEdit.cancelRemoveCategory();" /></p>
		</div>
	`
	);
}

function addBarGraphAdjustCategoricalAxisPointsSidebar(wpdDocument, sidebarId) {
	// get the default calibration sidebar
	const axisSidebar = wpdDocument.getElementById("axes-calibration-sidebar");

	// insert table axis calibration sidebar
	axisSidebar.insertAdjacentHTML(
		"beforebegin",
		`
		<div id="${sidebarId}" class="sidebar" style="display: none;">
			   <p class="sidebar-title">Adjust Categorical Axis Points</p>
			<p>Click points to select and use cursor keys to adjust positions. Use Shift+Arrow for faster movement. Click complete when finished.</p>
			<br>
			<p>Note that adjusting axis positions can impact the values of existing data points.</p>
			<p align="center"><input type="button" value="Complete" style="width: 120px;" onclick="wpd.barGraphEdit.completeAdjustCategoricalAxisPoints();" /></p>
		</div>
	`
	);
}

function addBarGraphSetBarAxisValuesDialog(wpdDocument, popupId) {
	wpdDocument.getElementById("point-group-settings-popup").insertAdjacentHTML(
		"afterend",
		`<div id="${popupId}" class="popup" style="width: 420px;">
			<div class="popupheading">Edit Bar Axis Values</div>
			<p align="center">Enter the values at the two points selected on the continuous axes along the bars</p>
			<center>
				<table padding="10">
					<tr>
						<td align="center" valign="bottom">Point 1</td>
						<td align="center" valign="bottom" width="80">Point 2</td>
						<td align="center" valign="bottom" width="80">Log Scale</td>
					</tr>
					<tr>
						<td align="center"><input type="text" size="8" id="edit-bar-axes-p1"/></td>
						<td align="center"><input type="text" size="8" id="edit-bar-axes-p2"/></td>
						<td align="center"><input type="checkbox" id="edit-bar-axes-log-scale" /></td>
					</tr>
				</table>
			</center>
			<p align="center"><label><input type="checkbox" id="edit-bar-axes-rotated" /> Rotated axes (not exactly vertical or horizontal)</label></p>
			<br />
			<p align="center">
				<input type="button" value="OK" onclick="wpd.barGraphEdit.completeSetBarAxisValues();" />
				<input type="button" value="Cancel" onclick="wpd.barGraphEdit.cancelSetBarAxisValues();" />
			</p>
		</div>`
	);
}

export { apply };
