/**
 * Initializes the adjust data point tool in WPD.
 */
function init(wpd, wpdDocument) {
	// cannot pick and choose functions to override due to how it was written
	// completely overwrite the manual selection tool into a js class
	wpd.AdjustDataPointTool = class {
		constructor(axis, dataset) {
			this.axis = axis;
			this.dataset = dataset;

			// multi-select box
			this.isMouseDown = false;
			this.isSelecting = false;
			this._drawTimer = null;
			this.p1 = null;
			this.p2 = null;
			this.imageP1 = null;
			this.imageP2 = null;
		}

		static #$button = wpdDocument.getElementById("manual-adjust-button");

		onAttach() {
			wpd.AdjustDataPointTool.#$button.classList.add("pressed-button");
			wpd.graphicsWidget.setRepainter(
				new wpd.DataPointsRepainter(this.axis, this.dataset)
			);
			wpd.toolbar.show("adjustDataPointsToolbar");

			if (wpd.tagGroups.activeTagGroup) {
				// display tag group ui
				// active tag group is only set when in text extraction mode
				wpd.tagGroups.showControls();
			} else {
				// show point group controls if set
				if (this.dataset.hasPointGroups()) {
					wpd.pointGroups.showControls();
					wpd.pointGroups.refreshControls();
				}
			}

			if (wpd.custom.isGraphAxis(this.axis)) {
				const pixelIndexes = this.dataset.getSelectedPixels();

				if (pixelIndexes.length > 0) {
					// immediately engage the metadata dialog if this is a graph
					// and there are data point(s) selected
					wpd.events.dispatch("wpd.dataset.point.select", {
						axes: this.axis,
						dataset: this.dataset,
						indexes: pixelIndexes,
					});
				}
			}
		}

		onRemove() {
			// clear selected data points
			this.dataset.unselectAll();

			wpd.graphicsWidget.forceHandlerRepaint();

			wpd.AdjustDataPointTool.#$button.classList.remove("pressed-button");
			wpd.toolbar.clear();
		}

		onMouseDown(ev, pos, imagePos) {
			this.isMouseDown = true;

			// record the first selection rectangle point
			this.p1 = pos;
			this.imageP1 = imagePos;

			// unselect everything
			this.dataset.unselectAll();
		}

		onMouseUp(ev, pos) {
			if (this.isSelecting === true) {
				// reset hover context to remove selection box drawing
				wpd.graphicsWidget.resetHover();

				// select points within the selection rectangle
				this.dataset.selectPixelsInRectangle(
					this.imageP1,
					this.imageP2
				);
				this.#onSelect(ev, this.dataset.getSelectedPixels());

				// clear the draw timer
				clearTimeout(this._drawTimer);

				// push these reset statements to the bottom of the events message queue
				setTimeout(
					function () {
						this.isSelecting = false;
						this.isMouseDown = false;
						this.p1 = null;
						this.p2 = null;

						// reset hover context to remove previous selection box
						wpd.graphicsWidget.resetHover();
					}.bind(this)
				);
			} else {
				this.isMouseDown = false;
				this.p1 = null;
				this.p2 = null;

				// reset hover context to remove previous selection box
				wpd.graphicsWidget.resetHover();
			}
		}

		onMouseMove(ev, pos, imagePos) {
			if (this.isMouseDown === true) {
				this.isSelecting = true;

				// record the new position as the second selection rectangle point
				this.p2 = pos;
				this.imageP2 = imagePos;

				// refresh the selection rectangle every 1 ms
				clearTimeout(this._drawTimer);
				this._drawTimer = setTimeout(
					function () {
						this.drawSelectionBox();
					}.bind(this),
					1
				);
			}
		}

		drawSelectionBox() {
			// reset hover context to remove previous selection box
			wpd.graphicsWidget.resetHover();

			// fetch the hover context
			const ctx = wpd.graphicsWidget.getAllContexts().hoverCtx;

			// draw a black rectangle
			if (this.p1 != null && this.p2 != null) {
				ctx.strokeStyle = wpd.Colors.default;
				ctx.strokeRect(
					this.p1.x,
					this.p1.y,
					this.p2.x - this.p1.x,
					this.p2.y - this.p1.y
				);
			}
		}

		#onSelect(ev, pixelIndexes) {
			wpd.graphicsWidget.forceHandlerRepaint();
			wpd.graphicsWidget.updateZoomOnEvent(ev);
			wpd.events.dispatch("wpd.dataset.point.select", {
				axes: this.axis,
				dataset: this.dataset,
				indexes: pixelIndexes,
			});
		}

		onMouseClick(ev, pos, imagePos) {
			if (this.isSelecting === false) {
				this.dataset.unselectAll();
				const pixelIndex = this.dataset.selectNearestPixel(
					imagePos.x,
					imagePos.y
				);
				this.#onSelect(ev, [pixelIndex]);
			}
		}

		onKeyDown(ev) {
			if (wpd.acquireData.isToolSwitchKey(ev.keyCode)) {
				wpd.acquireData.switchToolOnKeyPress(
					String.fromCharCode(ev.keyCode).toLowerCase()
				);
				return;
			}

			const selIndexes = this.dataset.getSelectedPixels();
			if (selIndexes.length < 1) {
				return;
			}

			// key strokes that do not need each point processed
			if (wpd.keyCodes.isAlphabet(ev.keyCode, "r")) {
				wpd.dataPointValueOverrideEditor.show(
					this.dataset,
					this.axis,
					selIndexes,
					this
				);
				return;
			}

			// key strokes that need each point processed
			let lastPtCoord = {
				x: null,
				y: null,
			};
			selIndexes.forEach(
				function (selIndex) {
					const stepSize =
						ev.shiftKey === true
							? 5 / wpd.graphicsWidget.getZoomRatio()
							: 0.5 / wpd.graphicsWidget.getZoomRatio();

					let selPoint = this.dataset.getPixel(selIndex);
					if (!selPoint) return;
					let pointPx = selPoint.x;
					let pointPy = selPoint.y;

					// rotate to current rotation
					const currentRotation = wpd.graphicsWidget.getRotation();
					let { x, y } = wpd.graphicsWidget.getRotatedCoordinates(
						0,
						currentRotation,
						pointPx,
						pointPy
					);

					if (wpd.keyCodes.isUp(ev.keyCode)) {
						y = y - stepSize;
					} else if (wpd.keyCodes.isDown(ev.keyCode)) {
						y = y + stepSize;
					} else if (wpd.keyCodes.isLeft(ev.keyCode)) {
						x = x - stepSize;
					} else if (wpd.keyCodes.isRight(ev.keyCode)) {
						x = x + stepSize;
					} else if (selIndexes.length === 1) {
						// single selected point operations
						if (wpd.keyCodes.isAlphabet(ev.keyCode, "q")) {
							this.dataset.selectPreviousPixel();
							selIndex = this.dataset.getSelectedPixels()[0];
							selPoint = this.dataset.getPixel(selIndex);
							pointPx = selPoint.x;
							pointPy = selPoint.y;
							({ x, y } =
								wpd.graphicsWidget.getRotatedCoordinates(
									0,
									currentRotation,
									pointPx,
									pointPy
								));
						} else if (wpd.keyCodes.isAlphabet(ev.keyCode, "w")) {
							this.dataset.selectNextPixel();
							selIndex = this.dataset.getSelectedPixels()[0];
							selPoint = this.dataset.getPixel(selIndex);
							pointPx = selPoint.x;
							pointPy = selPoint.y;
							({ x, y } =
								wpd.graphicsWidget.getRotatedCoordinates(
									0,
									currentRotation,
									pointPx,
									pointPy
								));
						} else if (wpd.keyCodes.isAlphabet(ev.keyCode, "e")) {
							if (this.axis.dataPointsHaveLabels) {
								selIndex = this.dataset.getSelectedPixels()[0];
								ev.preventDefault();
								ev.stopPropagation();
								wpd.dataPointLabelEditor.show(
									this.dataset,
									selIndex,
									this
								);
								return;
							}
						} else if (
							wpd.keyCodes.isDel(ev.keyCode) ||
							wpd.keyCodes.isBackspace(ev.keyCode)
						) {
							this.dataset.removePixelAtIndex(selIndex);
							this.dataset.unselectAll();
							if (
								this.dataset.findNearestPixel(
									pointPx,
									pointPy
								) >= 0
							) {
								this.dataset.selectNearestPixel(
									pointPx,
									pointPy
								);
								selIndex = this.dataset.getSelectedPixels()[0];
								selPoint = this.dataset.getPixel(selIndex);
								pointPx = selPoint.x;
								pointPy = selPoint.y;
								({ x, y } =
									wpd.graphicsWidget.getRotatedCoordinates(
										0,
										currentRotation,
										pointPx,
										pointPy
									));
							}
							wpd.graphicsWidget.resetData();
							wpd.graphicsWidget.forceHandlerRepaint();
							wpd.graphicsWidget.updateZoomToImagePosn(
								pointPx,
								pointPy
							);
							wpd.dataPointCounter.setCount(
								this.dataset.getCount()
							);
							ev.preventDefault();
							ev.stopPropagation();
							return;
						} else {
							return;
						}
					} else {
						return;
					}

					// rotate back to original rotation
					({ x, y } = wpd.graphicsWidget.getRotatedCoordinates(
						currentRotation,
						0,
						x,
						y
					));
					this.dataset.setPixelAt(selIndex, x, y);
					lastPtCoord = {
						x: x,
						y: y,
					};
				}.bind(this)
			);

			wpd.graphicsWidget.forceHandlerRepaint();
			if (lastPtCoord.x != null) {
				wpd.graphicsWidget.updateZoomToImagePosn(
					lastPtCoord.x,
					lastPtCoord.y
				);
			}
			ev.preventDefault();
			ev.stopPropagation();
		}
	};
}

export { init };
