import _ from "lodash";
import { v4 as uuidv4 } from "uuid";

/**
 * Sync row or column headers after modifying calibration. Mutates newCalibration parameter.
 * @param {Array} oldCalibration - Existing table axis calibration
 * @param {Array} newCalibration - New table axis calibration
 */
function syncHeaders(oldCalibration, newCalibration) {
	const emptyHeaderValue = null;

	// process row headers
	if (oldCalibration?.rows.headers.length) {
		// number of existing row headers is different than the new number of rows
		if (oldCalibration.rows.headers.length < newCalibration.rows.num) {
			// fill in the rest with nulls
			newCalibration.rows.headers.length = newCalibration.rows.num;
			newCalibration.rows.headers.fill(
				emptyHeaderValue,
				oldCalibration.rows.headers.length
			);
		} else if (
			oldCalibration.rows.headers.length > newCalibration.rows.num
		) {
			// discard the extra headers
			newCalibration.rows.headers.splice(newCalibration.rows.num);
		}
		// do nothing if headers are equal
	} else {
		// no existing row headers, fill in row headers with nulls
		newCalibration.rows.headers.length = newCalibration.rows.num;
		newCalibration.rows.headers.fill(emptyHeaderValue, 0);
	}

	// process column headers
	if (oldCalibration?.cols.headers.length) {
		// number of existing column headers is different than the new number of columns
		if (oldCalibration.cols.headers.length < newCalibration.cols.num) {
			// fill in the rest with nulls
			newCalibration.cols.headers.length = newCalibration.cols.num;
			newCalibration.cols.headers.fill(
				emptyHeaderValue,
				oldCalibration.cols.headers.length
			);
		} else if (
			oldCalibration.cols.headers.length > newCalibration.cols.num
		) {
			// discard the extra headers
			newCalibration.cols.headers.splice(newCalibration.cols.num);
		}
		// do nothing if headers are equal
	} else {
		// no existing column headers, fill in column headers with nulls
		newCalibration.cols.headers.length = newCalibration.cols.num;
		newCalibration.cols.headers.fill(emptyHeaderValue, 0);
	}
}

/**
 * Injects function to set up table axis and start alignment.
 * @param {Object} ids - IDs of table axis related elements
 */
function apply(wpd, wpdDocument, dataEntryVM, ids) {
	const calibration = {
		area: [],
		rotation: 0,
		schema: "",
		rows: {
			num: 1,
			dividers: [],
			headers: [],
			headerless: false,
		},
		cols: {
			num: 1,
			dividers: [],
			headers: [],
			headerless: false,
		},
	};

	let existingArea = [];

	/**
	 * Resets calibration object.
	 */
	const resetCalibration = () => {
		calibration.area.length = 0;
		calibration.rows.num = 1;
		calibration.rows.dividers.length = 0;
		calibration.rows.headers.length = 0;
		calibration.rows.headerless = false;
		calibration.cols.num = 1;
		calibration.cols.dividers.length = 0;
		calibration.cols.headers.length = 0;
		calibration.cols.headerless = false;
		calibration.rotation = 0;
		calibration.schema = "";
		calibration.calibrationComplete = false;
	};

	/**
	 * Table axis alignment entry point from table plot info dialog.
	 */
	wpd.alignAxes.pickArea = (back) => {
		// store existing area
		existingArea = [...calibration.area];

		if (!back) {
			// close axis calibration info popup
			wpd.popup.close(ids.infoPopup);

			// clear local calibration
			resetCalibration();
		}

		// set the rotation for this calibration
		calibration.rotation = wpd.graphicsWidget.getRotation();

		// trigger table axis area alignment
		wpd.graphicsWidget.setTool(new wpd.AlignmentAreaTool(calibration));

		// display table axis area sidebar
		wpd.sidebar.show(ids.areaSidebar);
	};

	/**
	 * On change handler for table axis numbers of rows and columns inputs.
	 */
	wpd.alignAxes.updateRowsAndColumns = (el) => {
		const id = el.getAttribute("id");
		const value = parseInt(el.value, 10);

		// set desired number of rows or columns
		if (id === ids.rowInput) {
			calibration.rows.num = value;
		} else if (id === ids.colInput) {
			calibration.cols.num = value;
		}

		// update dividers in the alignment rows and columns tool
		wpd.events.dispatch("wpd.tableAxis.updateDividers");

		// force redraw
		wpd.graphicsWidget.forceHandlerRepaint();
	};

	/**
	 * Triggers table axis recalibration from custom tweak calibration button.
	 */
	wpd.alignAxes.tweakTableCalibration = () => {
		// clear local calibration
		resetCalibration();

		// get calibration data from existing table axis
		const axis = wpd.tree.getActiveAxes();
		const metadata = axis.getMetadata();

		// set local calibration to existing table axis data
		calibration.area = metadata.calibration.area;
		calibration.rows = metadata.calibration.rows;
		calibration.cols = metadata.calibration.cols;
		calibration.rotation = metadata.calibration.rotation;

		// store existing area
		existingArea = [...calibration.area];

		// trigger table axis area alignment
		wpd.graphicsWidget.setTool(new wpd.AlignmentAreaTool(calibration));

		// display table axis area sidebar
		wpd.sidebar.show(ids.areaSidebar);
	};

	/**
	 * Triggers table axis rows and columns calibration after table area definition.
	 */
	wpd.alignAxes.defineRowsAndColumns = () => {
		if (
			_.isEqual(existingArea, calibration.area) &&
			(calibration.rows.num > 1 || calibration.cols.num > 1)
		) {
			// load existing rows and columns if either exists and area has not changed
			wpdDocument.getElementById(ids.rowInput).value =
				calibration.rows.num;
			wpdDocument.getElementById(ids.colInput).value =
				calibration.cols.num;
		} else {
			// otherwise revert to default
			wpdDocument.getElementById(ids.rowInput).value = 1;
			calibration.rows.num = 1;
			calibration.rows.dividers.length = 0;

			wpdDocument.getElementById(ids.colInput).value = 1;
			calibration.cols.num = 1;
			calibration.cols.dividers.length = 0;
		}

		// trigger table axis rows and column alignment tool
		wpd.graphicsWidget.setTool(
			new wpd.AlignmentRowsAndColumnsTool(calibration)
		);

		// display table axis rows and columns sidebar
		wpd.sidebar.show(ids.rcSidebar);
	};

	/**
	 * Sets the types of headers present.
	 */
	wpd.alignAxes.updateHeaderTypes = (headerlessInput) => {
		// update the local calibration data
		switch (headerlessInput.value) {
			case "both":
				calibration.rows.headerless = false;
				calibration.cols.headerless = false;
				break;
			case "rows":
				calibration.rows.headerless = false;
				calibration.cols.headerless = true;
				break;
			case "cols":
				calibration.rows.headerless = true;
				calibration.cols.headerless = false;
				break;
		}

		// get existing axis metadata
		const tableAxis = wpd.tree.getActiveAxes();
		const metadata = tableAxis.getMetadata();

		// update axis metadata calibration with local calibration
		metadata.calibration.rows.headerless = calibration.rows.headerless;
		metadata.calibration.cols.headerless = calibration.cols.headerless;

		// store data in the axis metadata field
		tableAxis.setMetadata(metadata);

		// force redraw
		wpd.graphicsWidget.forceHandlerRepaint();
	};

	/**
	 * Sets the table schema for pre-filling cells with used tags.
	 */
	wpd.alignAxes.updateTableSchema = (schemaInput) => {
		// update the local calibration data
		calibration.schema = schemaInput.value;

		// get existing axis metadata
		const tableAxis = wpd.tree.getActiveAxes();
		const metadata = tableAxis.getMetadata();

		// update axis metadata calibration with local calibration
		metadata.calibration.schema = calibration.schema;

		// store data in the axis metadata field
		tableAxis.setMetadata(metadata);
	};

	wpd.alignAxes.updateTableTagGroup = (tagGroupInput) => {
		const existingAxis = wpd.tree.getActiveAxes();

		// create an image axis to store table data under the hood
		const tableAxis = existingAxis ? existingAxis : new wpd.ImageAxes();

		// update the local calibration data
		calibration.cellTagGroup = {
			name: tagGroupInput.options[tagGroupInput.selectedIndex].text,
			uuid: tagGroupInput.options[tagGroupInput.selectedIndex].value,
		};

		// get existing axis metadata
		const metadata = tableAxis.getMetadata();

		// always set table to true and set calibration data
		metadata.table = true;
		metadata.calibration = calibration;

		// store data in the axis metadata field
		tableAxis.setMetadata(metadata);
	};

	/**
	 * Displays the details calibration sidebar.
	 */
	wpd.alignAxes.displayDetailsSidebar = (metadata) => {
		// load axis calibration into local calibration
		_.assign(calibration, metadata.calibration);

		if (!dataEntryVM.tableEditEnabled) {
			// set table header tool
			wpd.graphicsWidget.setTool(new wpd.TableHeaderTool(calibration));

			// set the headerless input default value
			// display values are the opposite of the internal values
			let headerlessValue;
			switch (true) {
				case calibration.rows.headerless:
					headerlessValue = "cols";
					break;
				case calibration.cols.headerless:
					headerlessValue = "rows";
					break;
				default:
					headerlessValue = "both";
			}
			wpdDocument.getElementById(ids.headerlessInput).value =
				headerlessValue;

			let schemaValue;
			switch (calibration.schema) {
				case "cols":
					schemaValue = "cols";
					break;
				case "rows":
					schemaValue = "rows";
					break;
				default:
					schemaValue = "both";
			}
			wpdDocument.getElementById(ids.schemaInput).value = schemaValue;
		} else {
			wpd.graphicsWidget.setTool(new wpd.TableHeaderTool(calibration));

			let schemaValue;
			switch (calibration.schema) {
				case "cols":
					schemaValue = "cols";
					break;
				case "rows":
					schemaValue = "rows";
					break;
				default:
					schemaValue = "both";
			}
			wpdDocument.getElementById(ids.editSchemaInput).value = schemaValue;

			// TODO: If we include the header and tag group dropdowns in the edit menu, we'd need these
			// 	let headerlessValue;
			// 	switch (true) {
			// 		case calibration.rows.headerless:
			// 			headerlessValue = "cols";
			// 			break;
			// 		case calibration.cols.headerless:
			// 			headerlessValue = "rows";
			// 			break;
			// 		default:
			// 			headerlessValue = "both";
			// 	}
			// 	wpdDocument.getElementById(ids.headerlessInput).value =
			// 		headerlessValue;
			// 	const tagGroupValue = calibration.cellTagGroup?.uuid;
			// 	wpdDocument.getElementById(ids.editTagGroupInput).value =
			// 		tagGroupValue;
			// }
		}

		// display table axis header sidebar
		const emptyHeaders = wpd.tableAxis.areTableHeadersEmpty();
		const validHeaders = wpd.tableAxis.validateTableHeaders();
		if (
			dataEntryVM.tableEditEnabled &&
			(calibration.calibrationComplete || (!emptyHeaders && validHeaders))
		) {
			wpd.sidebar.show(ids.tableEditSidebar);
		} else {
			wpd.sidebar.show(ids.detailsSidebar);
		}
	};

	wpd.alignAxes.addDataset = (colIndex, tableAxis) => {
		const plotData = wpd.appData.getPlotData();
		const fileManager = wpd.appData.getFileManager();
		const pageManager = wpd.appData.getPageManager();

		// make a new uuid
		const uuid = uuidv4();

		// get column header value for use as dataset name
		const name = "Column";

		// create a new dataset
		const dataset = new wpd.Dataset();

		// set dataset name to column and skip over existing column names
		dataset.name = `${name}_${uuid}`;

		// add dataset metadata
		dataset.setMetadata({
			table: true,
			colIndex: colIndex,
			name: name,
			uuid: uuid,
		});

		// add dataset to plot data, current file, and current page
		plotData.addDataset(dataset);
		fileManager.addDatasetsToCurrentFile([dataset]);

		if (wpd.appData.isMultipage()) {
			pageManager.addDatasetsToCurrentPage([dataset]);
		}

		// associate newly created dataset with table axis
		plotData.setAxesForDataset(dataset, tableAxis);

		// dispatch dataset add event
		wpd.events.dispatch("wpd.dataset.add", {
			dataset: dataset,
		});

		return dataset;
	};

	/**
	 * Creates or updates table axis after calibration.
	 */
	wpd.alignAxes.completeTableAxisCalibration = () => {
		const existingAxis = wpd.tree.getActiveAxes();

		const edit = existingAxis ? true : false;

		// create an image axis to store table data under the hood
		const tableAxis = edit ? existingAxis : new wpd.ImageAxes();

		// set the axis name to table
		if (!edit) {
			tableAxis.name = wpd.custom.makeAxisName("Table");
		}

		// set active axis
		wpd.tree.setActiveAxis(tableAxis);

		// get existing axis metadata
		const metadata = tableAxis.getMetadata();

		// sync column and row headers data
		syncHeaders(metadata.calibration, calibration);

		const tagGroupInput = wpdDocument.getElementById(ids.tagGroupInput);

		// update the local calibration data
		calibration.cellTagGroup = {
			name: tagGroupInput.options[tagGroupInput.selectedIndex].text,
			uuid: tagGroupInput.options[tagGroupInput.selectedIndex].value,
		};

		// always set table to true and set calibration data
		metadata.table = true;
		metadata.calibration = calibration;

		// store data in the axis metadata field
		tableAxis.setMetadata(metadata);

		const plotData = wpd.appData.getPlotData();
		const fileManager = wpd.appData.getFileManager();
		const pageManager = wpd.appData.getPageManager();

		// add the new axis to plot data
		if (!edit) {
			plotData.addAxes(tableAxis, wpd.appData.isMultipage());

			// dispatch axis add event
			wpd.events.dispatch("wpd.axes.add", {
				axes: tableAxis,
			});

			// add axis to current file
			fileManager.addAxesToCurrentFile([tableAxis]);

			// add axis to current page if necessary, and filter axes and datasets to the current page
			if (wpd.appData.isMultipage()) {
				pageManager.addAxesToCurrentPage([tableAxis]);
			}

			// create a dataset for each column
			for (let i = 0; i < calibration.cols.num; i++) {
				wpd.alignAxes.addDataset(i, tableAxis);
			}

			// update the left sidebar
			wpd.tree.refreshPreservingSelection();

			// select the newly created axis
			wpd.tree.editAxis(tableAxis.name);

			const cancelButton = wpdDocument.getElementsByClassName(
				"table-calibration-sidebar-cancel-button"
			)[0];
			cancelButton.classList.remove("hidden");

			// reset add new table button
			wpdDocument
				.getElementById("table-add-button")
				.classList.remove("pressed-button");
		} else {
			// get datasets within current file
			let datasets = fileManager.filterToCurrentFileDatasets(
				plotData.getDatasets()
			);

			if (wpd.appData.isMultipage()) {
				// and within current page if multipage
				datasets = pageManager.filterToCurrentPageDatasets(datasets);
			}

			// filter down to table datasets in the table axis
			datasets = datasets.filter((dataset) => {
				return (
					dataset.getMetadata().table &&
					tableAxis === plotData.getAxesForDataset(dataset)
				);
			});

			// add or remove datasets based on new column number
			if (datasets.length !== calibration.cols.num) {
				if (datasets.length > calibration.cols.num) {
					// remove extra columns from the end
					const deletes = datasets.splice(calibration.cols.num);
					deletes.forEach((dataset) =>
						plotData.deleteDataset(dataset)
					);
				} else if (datasets.length < calibration.cols.num) {
					// add extra columns at the end
					for (
						let i = datasets.length;
						i < calibration.cols.num;
						i++
					) {
						datasets.push(wpd.alignAxes.addDataset(i, tableAxis));
					}
				}
			}

			// re-index the columns
			datasets.forEach((dataset, i) => {
				dataset.setMetadata({
					table: true,
					colIndex: i,
				});
			});

			// update the left sidebar
			wpd.tree.refreshPreservingSelection();
		}
	};

	wpd.alignAxes.resizeTable = () => {
		wpd.sidebar.show("table-axis-resize-sidebar");
		const axis = wpd.tree.getActiveAxes();
		wpd.graphicsWidget.setTool(new wpd.EditAlignmentAreaTool(calibration));
	};

	wpd.alignAxes.completeResizeTable = () => {
		wpd.sidebar.clear();
		wpd.graphicsWidget.removeTool();

		const axis = wpd.tree.getActiveAxes();
		const metadata = axis.getMetadata();
		metadata.calibration = calibration;
		axis.setMetadata(metadata);

		wpd.alignAxes.displayDetailsSidebar(axis.getMetadata());
	};

	wpd.alignAxes.adjustRowsAndColumns = () => {
		wpd.sidebar.show("table-axis-adjust-rows-and-columns");

		wpd.graphicsWidget.setTool(
			new wpd.AlignmentRowsAndColumnsTool(calibration)
		);
	};

	wpd.alignAxes.addRow = () => {
		wpd.graphicsWidget.setTool(
			new wpd.AddRowOrColumnDividerTool(calibration, true)
		);
	};

	wpd.alignAxes.addColumn = () => {
		wpd.graphicsWidget.setTool(
			new wpd.AddRowOrColumnDividerTool(calibration, false)
		);
	};

	wpd.alignAxes.removeRow = () => {
		wpd.graphicsWidget.setTool(
			new wpd.RemoveRowOrColumnTool(calibration, true)
		);
	};

	wpd.alignAxes.removeColumn = () => {
		wpd.graphicsWidget.setTool(
			new wpd.RemoveRowOrColumnTool(calibration, false)
		);
	};

	wpd.alignAxes.completeAdjustRowsAndColumns = () => {
		wpd.sidebar.clear();
		wpd.graphicsWidget.removeTool();

		const axis = wpd.tree.getActiveAxes();
		const metadata = axis.getMetadata();
		metadata.calibration = calibration;
		axis.setMetadata(metadata);

		wpd.alignAxes.displayDetailsSidebar(axis.getMetadata());
	};

	wpd.alignAxes.closeTableEdit = () => {
		const isEmpty = wpd.tableAxis.areTableHeadersEmpty();
		const isValid = wpd.tableAxis.validateTableHeaders();
		const hasEmptyHeaders = wpd.tableAxis.hasEmptyTableHeaders();

		if (isEmpty) {
			wpd.tableAxis.showEmptyTableHeadersDialog();
		} else if (!isValid) {
			wpd.tableAxis.showInvalidTableHeadersDialog();
		} else if (hasEmptyHeaders) {
			wpd.tableAxis.showHasEmptyTableHeadersDialog();
		} else {
			wpd.graphicsWidget.removeTool();
			wpd.sidebar.clear();
			wpd.tree.refreshPreservingSelection();
		}
	};
}

export { apply };
