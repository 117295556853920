import { createApp } from "vue";
import { LDPlugin } from "launchdarkly-vue-client-sdk";
import * as Sentry from "@sentry/vue";
import axios from "axios";
import axiosRetry from "axios-retry";
import auth from "./auth";
import { validate } from "uuid";

import vuetify from "./plugins/vuetify";
import "vuetify/styles";

import router from "./router";
import store from "./store";

// main component to render
import App from "./App.vue";

const app = createApp(App);

// launchdarkly
app.use(LDPlugin, {
	clientSideID: process.env.VUE_APP_LD_CLIENT_SIDE_ID,
});

// set baseURL
const protocol = process.env.VUE_APP_ENV === "development" ? "http" : "https";
const apiHost = process.env.VUE_APP_API_HOST;
const apiPort = process.env.VUE_APP_API_PORT
	? `:${process.env.VUE_APP_API_PORT}`
	: "";
const apiUrl = `${protocol}://${apiHost}${apiPort}`;
const requestTimeout = 10000;
axios.defaults.baseURL = apiUrl;
axios.defaults.withCredentials = true;

function isValidDataEntryUrl(url) {
	if (!url) return false;

	// Check if the URL starts with /data-entry/ and has a valid UUID
	const parts = url.split("/");
	return parts.length > 2 && parts[1] === "data-entry" && validate(parts[2]);
}

axios.interceptors.request.use((config) => {
	if (
		config.url &&
		(isValidDataEntryUrl(config.url) || config.url === "/ping")
	) {
		config.timeout = requestTimeout;
	}
	return config;
});

axiosRetry(axios, {
	retries: 3,
	retryCondition: (error) => {
		return (
			axiosRetry.isNetworkOrIdempotentRequestError(error) ||
			error.code === "ECONNABORTED"
		);
	},
	retryDelay: (retryCount) => {
		return retryCount * 1000; // Exponential backoff: 1s, 2s, 3s
	},
	shouldResetTimeout: true,
	onRetry: (retryCount, error, requestConfig) => {
		console.error(
			`Request timed out after ${requestTimeout}ms; retry attempt ${retryCount} for URL: ${requestConfig.url}`
		);
	},
});

// make axios available everywhere
app.config.globalProperties.$http = axios;

// set up route guards to check authentication
router.beforeEach(async (to, from, next) => {
	const isAuthenticated = auth.isAuthenticated();
	store.commit("setAuthenticated", isAuthenticated);

	// unauthenticated
	if (!isAuthenticated) {
		// authentication required
		if (to.matched.some((record) => record.meta.requireAuth)) {
			// sent to login
			next({
				path: "/login",
				params: { nextUrl: to.fullPath },
			});
		}
		// authentication not required
		else if (to.matched.some((record) => record.meta.guest)) {
			next();
		}
	}
	// authenticated
	else {
		// authentication required
		if (to.matched.some((record) => record.meta.requireAuth)) {
			// look for permissions guard
			const record = to.matched.find((record) => record.meta.permissions);

			if (record) {
				// check for user permissions
				const user = store.getters.user;
				await axios
					.get(`/users/${user.uuid}/can-all`, {
						params: {
							permissions: record.meta.permissions,
						},
					})
					.then(() => {
						next();
					})
					.catch(() => {
						// expect 403s here; but if it fails navigation should
						// be aborted regardless of status code
						next(false);
					});
			} else {
				next();
			}
		}
		// authentication not required
		else if (to.matched.some((record) => record.meta.guest)) {
			// send to dashboard instead of guest page
			// NOTE may want to revisit this to allow authenticated users to access guest content
			next("/home/dashboard");
		}
	}
});

// only enabling sentry for production for now
if (process.env.VUE_APP_ENV === "production") {
	Sentry.init({
		app,
		release: process.env.VUE_APP_VERSION,
		dsn: "https://5e5e8d34c700b4f18b79cc51f7cbf03d@o4508049027891200.ingest.us.sentry.io/4508049048993792",
		integrations: [
			Sentry.browserTracingIntegration({ router }),
			Sentry.replayIntegration({
				blockAllMedia: false,
				maskAllInputs: false,
				maskAllText: false,
				networkDetailAllowUrls: [
					`${apiUrl}/data-entry/`,
					`${apiUrl}/ping`,
				],
			}),
			Sentry.replayCanvasIntegration(),
			Sentry.captureConsoleIntegration({
				levels: ["error"], // Capture only console.error
			}),
		],
		// Tracing
		tracesSampleRate: 1.0, //  Capture 100% of the transactions
		// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
		tracePropagationTargets: ["localhost", apiUrl],
		// Session Replay
		replaysSessionSampleRate: 1.0, // This sets the sample rate at 100%. You may want to change it to 10% while in production.
		replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
		// Reduce span sizes
		beforeSendSpan: (span) => {
			if (
				span.op === "ui.long-animation-frame" &&
				span.data["browser.script.invoker_type"] === "event-listener" &&
				span.data["code.filepath"] ===
					`${protocol}://${process.env.VUE_APP_WEB_HOST}/modules/wpd/app/wpd.min.js`
			) {
				if (span.data["browser.script.invoker"].length > 100) {
					span.data["browser.script.invoker"] = "ImageOnLoad";
				}
			}
			return span;
		},
	});
}

// include dactyl version
app.config.globalProperties.$version = process.env.VUE_APP_VERSION;

// initialize vue instance and render main component
app.use(router);
app.use(store);
// app.use(Vuelidate);
app.use(vuetify);
app.mount("#app");
