import { createRouter, createWebHistory } from "vue-router";

// pages
import BaseTagManager from "@/pages/BaseTagManager";
import DactylDashboard from "@/pages/DactylDashboard";
import DataEntry from "@/pages/DataEntry";
import DataViewer from "@/pages/DataViewer";
import CompletedExtractions from "@/pages/CompletedExtractions";
import EmptyRouter from "@/pages/EmptyRouter";
import FileManager from "@/pages/FileManager";
import DactylHome from "@/pages/DactylHome";
import PaperManager from "@/pages/PaperManager";
import PasswordReset from "@/pages/PasswordReset";
import ProjectManager from "@/pages/ProjectManager";
import QualityControl from "@/pages/QualityControl";
import QCDashboard from "@/pages/QCDashboard";
import UserSettings from "@/pages/UserSettings";
import Organizations from "@/pages/Organizations";
import TagGroupManager from "@/pages/TagGroupManager";
import TrainingDashboard from "@/pages/TrainingDashboard";
import UserManager from "@/pages/UserManager";
import UserManual from "@/pages/UserManual";
import DactylWelcome from "@/pages/DactylWelcome";

const routes = [
	{
		path: "/",
		component: DactylWelcome,
		meta: {
			guest: true,
		},
		props(route) {
			const fromPath = route.redirectedFrom?.path;
			if (fromPath) {
				if (fromPath.startsWith("/login")) {
					if (route.query.token) {
						return { token: route.query.token };
					} else {
						return { login: true };
					}
				} else if (fromPath.startsWith("/sso/failure")) {
					return { ssoFailure: true };
				}
			}

			return {};
		},
	},
	{
		path: "/login",
		redirect: "/",
	},
	{
		path: "/sso/failure",
		redirect: "/",
	},
	{
		path: "/password-reset/:token",
		component: PasswordReset,
		meta: {
			guest: true,
		},
	},
	{
		path: "/home",
		component: DactylHome,
		meta: {
			requireAuth: true,
		},
		children: [
			{
				path: "user-manager",
				component: UserManager,
				meta: {
					requireAuth: true,
					permissions: ["create_user", "edit_user", "delete_user"],
				},
			},
			{
				path: "dashboard",
				component: DactylDashboard,
				meta: {
					requireAuth: true,
				},
			},
			{
				path: "training-dashboard",
				component: TrainingDashboard,
				meta: {
					requireAuth: true,
				},
			},
			{
				path: "qc-dashboard",
				component: QCDashboard,
				meta: {
					requireAuth: true,
				},
			},
			{
				path: "completed-extractions",
				component: CompletedExtractions,
				meta: {
					requireAuth: true,
				},
			},
			{
				path: "file-manager",
				component: FileManager,
				meta: {
					requireAuth: true,
					permissions: ["delete_file", "upload_file"],
				},
			},
			{
				path: "paper-manager",
				component: PaperManager,
				meta: {
					requireAuth: true,
					permissions: ["delete_file", "upload_file"],
				},
			},
			{
				path: "project-manager",
				component: ProjectManager,
				meta: {
					requireAuth: true,
					permissions: ["delete_file", "upload_file"],
				},
			},
			{
				path: "organizations",
				component: Organizations,
				meta: {
					requireAuth: true,
				},
			},
			{
				path: "settings",
				component: UserSettings,
				meta: {
					requireAuth: true,
				},
			},
			{
				path: "tag",
				component: EmptyRouter, // this is needed for vue-router v3
				children: [
					{
						path: "base-tag-manager",
						component: BaseTagManager,
						meta: {
							requireAuth: true,
							permissions: ["manage_tags"],
						},
					},
					{
						path: "tag-group-manager",
						component: TagGroupManager,
						meta: {
							requireAuth: true,
							permissions: ["manage_tags"],
						},
					},
				],
			},
		],
	},
	{
		path: "/data-entry/:paperUuid/:sdeUuid",
		component: DataEntry,
		meta: {
			requireAuth: true,
		},
		props: true,
	},
	{
		path: "/data-entry-training/:paperUuid/:sdeUuid",
		component: DataEntry,
		meta: {
			requireAuth: true,
		},
		props: (route) => ({
			paperUuid: route.params.paperUuid,
			sdeUuid: route.params.sdeUuid,
			training: true,
		}),
	},
	{
		path: "/data-viewer",
		component: DataViewer,
		meta: { requireAuth: true },
		props: (route) => ({
			paperUuid: route.query.paperUuid || null,
			sdeUuid: route.query.sdeUuid || null,
			dataPointUuid: route.query.dataPointUuid || null,
		}),
	},
	{
		path: "/quality-control",
		component: QualityControl,
		meta: {
			requireAuth: true,
		},
	},
	{
		path: "/user-manual",
		component: UserManual,
		meta: {
			requireAuth: true,
		},
	},
	// TODO make a cheeky 404 page
	// {
	// 	path: "/:pathMatch(.*)*",
	// 	redirect: "/",
	// },
];

export default createRouter({
	history: createWebHistory(),
	routes,
});
