function apply(wpd, wpdDocument, dataEntryVM) {
	const hitTolerance = 8;
	wpd.alignAxes.rowHitTest = function (
		y,
		rowY,
		rowHeight,
		rotation,
		tolerance = hitTolerance
	) {
		const { row: flipRow } = this.getAxisFlip(rotation);
		const min = flipRow ? rowY - rowHeight - tolerance : rowY - tolerance;
		const max = flipRow ? rowY + tolerance : rowY + tolerance + rowHeight;
		return y >= min && y <= max;
	};

	wpd.alignAxes.colHitTest = function (
		x,
		colX,
		colHeight,
		rotation,
		tolerance = hitTolerance
	) {
		const { col: flipCol } = this.getAxisFlip(rotation);
		const min = flipCol ? colX - colHeight - tolerance : colX - tolerance;
		const max = flipCol ? colX + tolerance : colX + tolerance + colHeight;
		return x >= min && x <= max;
	};

	wpd.alignAxes.dividerHitTest = function (
		pos,
		dividerPos,
		tolerance = hitTolerance
	) {
		return pos >= dividerPos - tolerance && pos <= dividerPos + tolerance;
	};

	wpd.alignAxes.cornerHitTest = function (y, x, cornerY, cornerX, rotation) {
		return (
			this.rowHitTest(y, cornerY, 0, rotation, 24) &&
			this.colHitTest(x, cornerX, 0, rotation, 24)
		);
	};

	wpd.alignAxes.borderHitTest = function (pos, area, rotation) {
		const areaMin = this.getAreaMin(area);
		const areaMax = this.getAreaMax(area);
		const minX = areaMin.x;
		const maxX = areaMax.x;
		const minY = areaMin.y;
		const maxY = areaMax.y;

		const x = pos.x;
		const y = pos.y;

		let selected = {
			corner: null,
			edge: null,
		};

		if (wpd.alignAxes.cornerHitTest(y, x, minY, minX, rotation)) {
			selected.corner = "top-left";
			selected.xIndex = area[0].x === minX ? 0 : 1;
			selected.yIndex = area[0].y === minY ? 0 : 1;
		} else if (wpd.alignAxes.cornerHitTest(y, x, minY, maxX, rotation)) {
			selected.corner = "top-right";
			selected.xIndex = area[0].x === maxX ? 0 : 1;
			selected.yIndex = area[0].y === minY ? 0 : 1;
		} else if (wpd.alignAxes.cornerHitTest(y, x, maxY, minX, rotation)) {
			selected.corner = "bottom-left";
			selected.xIndex = area[0].x === minX ? 0 : 1;
			selected.yIndex = area[0].y === maxY ? 0 : 1;
		} else if (wpd.alignAxes.cornerHitTest(y, x, maxY, maxX, rotation)) {
			selected.corner = "bottom-right";
			selected.xIndex = area[0].x === maxX ? 0 : 1;
			selected.yIndex = area[0].y === maxY ? 0 : 1;
		} else if (wpd.alignAxes.rowHitTest(y, minY, 0, rotation, 24)) {
			selected.edge = "top";
			selected.yIndex = area[0].y === minY ? 0 : 1;
		} else if (wpd.alignAxes.rowHitTest(y, maxY, 0, rotation, 24)) {
			selected.edge = "bottom";
			selected.yIndex = area[0].y === maxY ? 0 : 1;
		} else if (wpd.alignAxes.colHitTest(x, minX, 0, rotation, 24)) {
			selected.edge = "left";
			selected.xIndex = area[0].x === minX ? 0 : 1;
		} else if (wpd.alignAxes.colHitTest(x, maxX, 0, rotation, 24)) {
			selected.edge = "right";
			selected.xIndex = area[0].x === maxX ? 0 : 1;
		}

		return selected;
	};

	wpd.alignAxes.rowHeaderTest = function (area, rows, imagePos, rotation) {
		const edges = this.getEdges(area, rotation);
		const [min, max] = edges.rows;
		const width = (max - min) / rows.num;

		const areaMin = this.getAreaMin(area);
		const areaMax = this.getAreaMax(area);
		const tests = {
			0:
				imagePos.x > areaMin.x - width * 3 &&
				imagePos.x < areaMin.x &&
				imagePos.y > areaMin.y &&
				imagePos.y < areaMax.y,
			90:
				imagePos.y < areaMax.y + width * 3 &&
				imagePos.y > areaMax.y &&
				imagePos.x > areaMin.x &&
				imagePos.x < areaMax.x,
			180:
				imagePos.x < areaMax.x - width * 3 &&
				imagePos.x > areaMax.x &&
				imagePos.y > areaMin.y &&
				imagePos.y < areaMax.y,
			270:
				imagePos.y > areaMin.y + width * 3 &&
				imagePos.y < areaMin.y &&
				imagePos.x > areaMin.x &&
				imagePos.x < areaMax.x,
		};

		return tests[rotation];
	};

	wpd.alignAxes.colHeaderTest = function (area, cols, imagePos, rotation) {
		const edges = this.getEdges(area, rotation);
		const [min, max] = edges.cols;
		const height = (max - min) / cols.num;

		const areaMin = this.getAreaMin(area);
		const areaMax = this.getAreaMax(area);
		const tests = {
			0:
				imagePos.x > areaMin.x &&
				imagePos.x < areaMax.x &&
				imagePos.y > areaMin.y - height * 3 &&
				imagePos.y < areaMin.y,
			90:
				imagePos.y > areaMin.y &&
				imagePos.y < areaMax.y &&
				imagePos.x > areaMin.x + height * 3 &&
				imagePos.x < areaMin.x,
			180:
				imagePos.x > areaMin.x &&
				imagePos.x < areaMax.x &&
				imagePos.y < areaMax.y - height * 3 &&
				imagePos.y > areaMax.y,
			270:
				imagePos.y > areaMin.y &&
				imagePos.y < areaMax.y &&
				imagePos.x < areaMax.x + height * 3 &&
				imagePos.x > areaMax.x,
		};

		return tests[rotation];
	};

	wpd.alignAxes.rowHeaderDialogPosition = function (
		rowPos,
		height,
		area,
		rotation
	) {
		const areaMin = this.getAreaMin(area);
		const areaMax = this.getAreaMax(area);
		const positions = {
			0: {
				x: areaMin.x,
				y: rowPos + height / 2,
			},
			90: {
				x: rowPos + height / 2,
				y: areaMax.y,
			},
			180: {
				x: areaMax.x,
				y: rowPos - height / 2,
			},
			270: {
				x: rowPos - height / 2,
				y: areaMin.y,
			},
		};

		return positions[rotation];
	};

	wpd.alignAxes.colHeaderDialogPosition = function (
		colPos,
		width,
		area,
		rotation
	) {
		const areaMin = this.getAreaMin(area);
		const areaMax = this.getAreaMax(area);
		const positions = {
			0: {
				x: colPos + width / 2,
				y: areaMin.y,
			},
			90: {
				x: areaMin.x,
				y: colPos - width / 2,
			},
			180: {
				x: colPos - width / 2,
				y: areaMax.y,
			},
			270: {
				x: areaMax.x,
				y: colPos + width / 2,
			},
		};

		return positions[rotation];
	};

	wpd.alignAxes.borderMovementRules = function (pos, area, threshold) {
		const areaMin = this.getAreaMin(area);
		const areaMax = this.getAreaMax(area);
		return {
			"top-left": {
				x: pos.x < areaMax.x - threshold,
				y: pos.y < areaMax.y - threshold,
			},
			"top-right": {
				x: pos.x > areaMin.x + threshold,
				y: pos.y < areaMax.y - threshold,
			},
			"bottom-right": {
				x: pos.x > areaMin.x + threshold,
				y: pos.y > areaMin.y + threshold,
			},
			"bottom-left": {
				x: pos.x < areaMax.x - threshold,
				y: pos.y > areaMin.y + threshold,
			},
			"top": { y: pos.y < areaMax.y - threshold },
			"right": { x: pos.x > areaMin.x + threshold },
			"bottom": { y: pos.y > areaMin.y + threshold },
			"left": { x: pos.x < areaMax.x - threshold },
		};
	};

	//TODO: instead of resetting to even-length, scale proportionally to what's already there
	wpd.alignAxes.resetDividers = function (area, rows, cols, rotation) {
		const edges = this.getEdges(area, rotation);

		// rows
		const [minRowBorder, maxRowBorder] = edges.rows;
		const totalRowHeight = maxRowBorder - minRowBorder;
		const newRowHeight = totalRowHeight / rows.num;
		const newRowDividers = [];
		for (let i = 0; i < rows.num - 1; i++) {
			newRowDividers.push(minRowBorder + newRowHeight * (i + 1));
		}

		rows.dividers = newRowDividers;

		// cols
		const [minColBorder, maxColBorder] = edges.cols;
		const totalColHeight = maxColBorder - minColBorder;
		const newColHeight = totalColHeight / cols.num;
		const newColDividers = [];
		for (let i = 0; i < cols.num - 1; i++) {
			newColDividers.push(minColBorder + newColHeight * (i + 1));
		}

		cols.dividers = newColDividers;
	};

	// index - current divider index
	// dividers - all dividers (including table borders)
	// pos - mouse position
	// rotation - table rotation
	wpd.alignAxes.getRow = function (index, dividers, area, pos, rotation) {
		const { row: flipRow, col: flipCol } = this.getAxisFlip(rotation);

		const nextIndex = Math.min(index + 1, dividers.length - 1);
		const previousIndex = Math.max(index - 1, 0);
		const rowHeight = Math.abs(dividers[index] - dividers[nextIndex]);

		let previewIndex;
		let isHovered = false;
		let previewHeight;
		let isPreviewHovered = false;
		if (pos) {
			const rowPos = this.getRowPos(pos, rotation);
			const shouldIncrement = flipRow
				? rowPos < dividers[index]
				: rowPos > dividers[index];
			previewIndex = shouldIncrement ? nextIndex : previousIndex;
			isHovered = this.rowHitTest(
				rowPos,
				dividers[index],
				rowHeight,
				rotation
			);

			previewHeight =
				Math.abs(dividers[index] - dividers[previewIndex]) / 2;
			isPreviewHovered =
				rowPos >= dividers[index] - previewHeight &&
				rowPos <= dividers[index] + previewHeight;
		}

		const headerDialogPosition = this.rowHeaderDialogPosition(
			dividers[index],
			rowHeight,
			area,
			rotation
		);

		return {
			startPos: dividers[index],
			endPos: dividers[nextIndex],
			startDividerIndex: index,
			endDividerIndex: nextIndex,
			rowIndex: index,
			previewIndex,
			height: rowHeight,
			isHovered,
			isPreviewHovered,
			headerDialogPosition,
		};
	};

	// index - current divider index
	// dividers - all dividers (including table borders)
	// pos - mouse position
	// rotation - table rotation
	wpd.alignAxes.getCol = function (index, dividers, area, pos, rotation) {
		const { row: flipRow, col: flipCol } = this.getAxisFlip(rotation);

		const nextIndex = Math.min(index + 1, dividers.length - 1);
		const previousIndex = Math.max(index - 1, 0);
		const colHeight = Math.abs(dividers[index] - dividers[nextIndex]);

		let previewIndex;
		let isHovered = false;
		let previewHeight;
		let isPreviewHovered = false;
		if (pos) {
			const colPos = this.getColPos(pos, rotation);
			const shouldIncrement = flipCol
				? colPos < dividers[index]
				: colPos > dividers[index];
			previewIndex = shouldIncrement ? nextIndex : previousIndex;
			isHovered = this.colHitTest(
				colPos,
				dividers[index],
				colHeight,
				rotation
			);

			previewHeight =
				Math.abs(dividers[index] - dividers[previewIndex]) / 2;
			isPreviewHovered =
				colPos >= dividers[index] - previewHeight &&
				colPos <= dividers[index] + previewHeight;
		}

		const headerDialogPosition = this.colHeaderDialogPosition(
			dividers[index],
			colHeight,
			area,
			rotation
		);

		return {
			startPos: dividers[index],
			endPos: dividers[nextIndex],
			startDividerIndex: index,
			endDividerIndex: nextIndex,
			colIndex: index,
			previewIndex,
			height: colHeight,
			isHovered,
			isPreviewHovered,
			headerDialogPosition,
		};
	};

	wpd.alignAxes.getCell = function (
		rowIndex,
		colIndex,
		rowDividers,
		colDividers,
		area,
		rotation
	) {
		const row = this.getRow(rowIndex, rowDividers, area, null, rotation);
		const col = this.getCol(colIndex, colDividers, area, null, rotation);

		const rowCenter = (row.startPos + row.endPos) / 2;
		const colCenter = (col.startPos + col.endPos) / 2;

		const x = this.swapXY(rotation) ? rowCenter : colCenter;
		const y = this.swapXY(rotation) ? colCenter : rowCenter;
		const rotatedCoords = this.getRotatedScreenCoordinates({
			x,
			y,
		});

		const swap = this.swapXY(rotation);

		const start = this.getRotatedScreenCoordinates({
			x: col.startPos,
			y: row.startPos,
		});

		const end = this.getRotatedScreenCoordinates({
			x: col.endPos,
			y: row.endPos,
		});

		const xWidth = swap ? end.y - start.y : end.x - start.x;
		const yHeight = swap ? end.x - start.x : end.y - start.y;

		const dialogOffset = Math.abs(xWidth) / 2;

		return {
			rowCenter,
			colCenter,
			x,
			y,
			rotatedCoords,
			dialogOffset,
			rowHeight: row.height,
			colHeight: col.height,
			screenWidth: Math.abs(xWidth),
			screenHeight: Math.abs(yHeight),
			rowStart: row.startPos,
			rowEnd: row.endPos,
			colStart: col.startPos,
			colEnd: col.endPos,
		};
	};

	// Determines which row (if any) is hovered, regardless of position on the col axis
	// dividers - all row dividers, including table borders
	wpd.alignAxes.getHoveredRow = function (
		area,
		dividers,
		imagePos,
		rotation
	) {
		for (let i = 0; i < dividers.length - 1; i++) {
			const row = this.getRow(i, dividers, area, imagePos, rotation);
			if (row.isHovered) {
				return row;
			}
		}

		return null;
	};

	// Determines which col (if any) is hovered, regardless of position on the row axis
	// dividers - all col dividers, including table borders
	wpd.alignAxes.getHoveredCol = function (
		area,
		dividers,
		imagePos,
		rotation
	) {
		for (let i = 0; i < dividers.length - 1; i++) {
			const col = this.getCol(i, dividers, area, imagePos, rotation);
			if (col.isHovered) {
				return col;
			}
		}

		return null;
	};

	wpd.alignAxes.getRowPos = function (pos, rotation) {
		return this.swapXY(rotation) ? pos.x : pos.y;
	};

	wpd.alignAxes.getColPos = function (pos, rotation) {
		return this.swapXY(rotation) ? pos.y : pos.x;
	};

	// getMinMax
	wpd.alignAxes.getAreaMin = function (points) {
		return {
			x: Math.min(points[0].x, points[1].x),
			y: Math.min(points[0].y, points[1].y),
		};
	};

	wpd.alignAxes.getAreaMax = function (points) {
		return {
			x: Math.max(points[0].x, points[1].x),
			y: Math.max(points[0].y, points[1].y),
		};
	};

	wpd.alignAxes.getEdges = function (area, rotation) {
		const areaMin = this.getAreaMin(area);
		const areaMax = this.getAreaMax(area);
		const { row: flipRow, col: flipCol } = this.getAxisFlip(rotation);

		const rowMin = flipRow ? areaMax.y : areaMin.y;
		const rowMax = flipRow ? areaMin.y : areaMax.y;
		const colMin = flipCol ? areaMax.x : areaMin.x;
		const colMax = flipCol ? areaMin.x : areaMax.x;

		const swapXY = this.swapXY(rotation);
		const rows = swapXY ? [colMax, colMin] : [rowMin, rowMax];
		const cols = swapXY ? [rowMax, rowMin] : [colMin, colMax];

		return {
			rows,
			cols,
		};
	};

	wpd.alignAxes.isInTableArea = function (imagePos, area, tolerance = 0) {
		const areaMin = this.getAreaMin(area);
		const areaMax = this.getAreaMax(area);
		return (
			imagePos.y > areaMin.y - tolerance &&
			imagePos.y < areaMax.y + tolerance &&
			imagePos.x > areaMin.x - tolerance &&
			imagePos.x < areaMax.x + tolerance
		);
	};

	wpd.alignAxes.getFullDividers = function (area, rotation, rows, cols) {
		const edges = this.getEdges(area, rotation);
		const [minRowBorder, maxRowBorder] = edges.rows;
		const [minColBorder, maxColBorder] = edges.cols;

		return {
			rows: [minRowBorder, ...rows, maxRowBorder],
			cols: [minColBorder, ...cols, maxColBorder],
		};
	};

	wpd.alignAxes.getAxisFlip = (rotation) => {
		return {
			row: rotation === 180 || rotation === 270,
			col: rotation === 90 || rotation === 180,
		};
	};

	wpd.alignAxes.swapXY = (rotation) => {
		return rotation % 180 === 90;
	};

	wpd.alignAxes.getRotatedScreenCoordinates = (coords) => {
		const { x, y } = wpd.graphicsWidget.getRotatedCoordinates(
			0,
			wpd.graphicsWidget.getRotation(),
			coords.x,
			coords.y
		);

		return wpd.graphicsWidget.screenPx(x, y);
	};
}

export { apply };
