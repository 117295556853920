function init(wpd, wpdDocument, wpdWindow, dataEntryVM) {
	wpd.barChartUtils = {};

	const mouseOverHitThreshold = 8;
	const headerLineMargin = 10;
	const headerBarLength = 200;
	const headerBarMargin = 1;

	const BarDirection = {
		UP: "UP",
		DOWN: "DOWN",
		LEFT: "LEFT",
		RIGHT: "RIGHT",
	};
	const LineOrientation = {
		HORIZONTAL: "HORIZONTAL",
		VERTICAL: "VERTICAL",
	};

	wpd.barChartUtils.drawHeaderDividers = function (ctx, dividers) {
		if (!dividers) return;

		for (let divider of dividers) {
			const headerDividerStart = [divider.startX, divider.startY].map(
				(pixel) => wpd.graphicsWidget.toScreenPixel(pixel)
			);
			const headerDividerEnd = [divider.endX, divider.endY].map((pixel) =>
				wpd.graphicsWidget.toScreenPixel(pixel)
			);
			const color = divider.hover
				? wpd.Colors.activeDark
				: wpd.Colors.headerActiveDarkA;
			wpd.barChartUtils.drawLine(
				ctx,
				headerDividerStart,
				headerDividerEnd,
				color
			);
		}
	};

	wpd.barChartUtils.drawLine = function (ctx, start, end, color) {
		ctx.beginPath();
		ctx.strokeStyle = color;
		ctx.lineWidth = 4;
		ctx.moveTo(...start);
		ctx.lineTo(...end);
		ctx.stroke();
	};

	wpd.barChartUtils.drawAxisLine = function (ctx, axes, metadata) {
		if (!metadata.barHeaders) return;

		const { p1, p2 } = metadata.barHeaders;
		const barP1 = axes.calibration.getPoint(0);

		if (!p1 || !p2 || !barP1) return;

		const headerOrientation = metadata.barHeaders.headerOrientation;
		const p1Dist = wpd.barChartUtils.calculateDistance(barP1, p1);
		const p2Dist = wpd.barChartUtils.calculateDistance(barP1, p2);

		const headerAxisStartPoint = p1Dist > p2Dist ? p2 : p1;
		const otherPoint = p1Dist > p2Dist ? p1 : p2;

		const headerAxisStart = [
			headerAxisStartPoint.px,
			headerAxisStartPoint.py,
		];

		const headerAxisEnd = [
			headerOrientation === LineOrientation.HORIZONTAL
				? otherPoint.px
				: headerAxisStartPoint.px,
			headerOrientation === LineOrientation.VERTICAL
				? otherPoint.py
				: headerAxisStartPoint.py,
		];

		metadata.barHeaders.headerAxisLineStart = headerAxisStart;
		metadata.barHeaders.headerAxisLineEnd = headerAxisEnd;

		axes.setMetadata(metadata);

		const color = wpd.Colors.headerActiveDarkA;

		wpd.barChartUtils.drawLine(
			ctx,
			headerAxisStart.map((val) => wpd.graphicsWidget.toScreenPixel(val)),
			headerAxisEnd.map((val) => wpd.graphicsWidget.toScreenPixel(val)),
			color
		);
	};

	wpd.barChartUtils.calculateDistance = function (p1, p2) {
		return Math.sqrt((p2.px - p1.px) ** 2 + (p2.py - p1.py) ** 2);
	};

	wpd.barChartUtils.getLineOrientation = function (p1, p2) {
		return Math.abs(p1.px - p2.px) > Math.abs(p1.py - p2.py)
			? LineOrientation.HORIZONTAL
			: LineOrientation.VERTICAL;
	};

	wpd.barChartUtils.getBarDirection = function (metadata, barP1, barP2, p) {
		const barOrientation = metadata.barHeaders.barOrientation;
		const axis =
			barOrientation === LineOrientation.HORIZONTAL ? "px" : "py";

		// if horizontal, bp1 is the right point and bp2 is the left
		// if vertical, bp1 is the bottom point and bp2 is the top
		const bp1 = barP1[axis] > barP2[axis] ? barP1 : barP2;
		const bp2 = barP1[axis] > barP2[axis] ? barP2 : barP1;

		// get the distances from the provided header point to each of the bar points
		const d1 = wpd.barChartUtils.calculateDistance(p, bp1);
		const d2 = wpd.barChartUtils.calculateDistance(p, bp2);

		if (barOrientation === LineOrientation.HORIZONTAL) {
			// if the left point is closer to the headers, bars are going right
			return d1 > d2 ? BarDirection.RIGHT : BarDirection.LEFT;
		} else {
			// if the top point is closer to the headers, bars are going down
			return d1 > d2 ? BarDirection.DOWN : BarDirection.UP;
		}
	};

	wpd.barChartUtils.drawPoints = function (axes) {
		const metadata = axes.getMetadata();
		const drawPoint = (point) => {
			if (point) {
				wpd.graphicsHelper.drawPoint(
					{ x: point.px, y: point.py },
					wpd.Colors.good,
					""
				);
			}
		};

		const p1 = axes.calibration.getPoint(0);
		drawPoint(p1);
		const p2 = axes.calibration.getPoint(1);
		drawPoint(p2);

		if (metadata.barHeaders) {
			drawPoint(metadata.barHeaders.p1);
			drawPoint(metadata.barHeaders.p2);
		}
	};

	wpd.barChartUtils.getGraphBoundaries = function (axes) {
		const metadata = axes.getMetadata();
		if (!metadata.barHeaders) return;

		const firstDivider = metadata.barHeaders.headerDividers[0];
		const lastDivider =
			metadata.barHeaders.headerDividers[
				metadata.barHeaders.headerDividers.length - 1
			];

		const barOrientation = metadata.barHeaders.barOrientation;
		const isHorizontal = barOrientation === LineOrientation.HORIZONTAL;

		const minX = isHorizontal
			? Math.min(firstDivider.startX, firstDivider.endX)
			: Math.min(firstDivider.startX, lastDivider.startX);
		const maxX = isHorizontal
			? Math.max(firstDivider.startX, firstDivider.endX)
			: Math.max(firstDivider.startX, lastDivider.startX);

		const minY = isHorizontal
			? Math.min(firstDivider.startY, lastDivider.startY)
			: Math.min(firstDivider.startY, firstDivider.endY);
		const maxY = isHorizontal
			? Math.max(firstDivider.startY, lastDivider.startY)
			: Math.max(firstDivider.startY, firstDivider.endY);

		return { minX, maxX, minY, maxY };
	};

	wpd.barChartUtils.isInGraphArea = function (axis, imagePos) {
		const boundaries = wpd.barChartUtils.getGraphBoundaries(axis);
		return (
			imagePos.y > boundaries.minY &&
			imagePos.y < boundaries.maxY &&
			imagePos.x > boundaries.minX &&
			imagePos.x < boundaries.maxX
		);
	};

	wpd.barChartUtils.toScreenPixels = function (pixels) {
		return pixels.map((pixel) => wpd.graphicsWidget.toScreenPixel(pixel));
	};

	wpd.barChartUtils.buildHeaderAreas = function (axis, metadata, isEdit) {
		const existingHeaders = metadata.barHeaders.headers;
		if (!isEdit && existingHeaders && existingHeaders.length > 0) return;

		const dividers = metadata.barHeaders.headerDividers;
		const headerOrientation = metadata.barHeaders.headerOrientation;
		const barDirection = metadata.barHeaders.barDirection;

		const isHorizontal = headerOrientation === LineOrientation.HORIZONTAL;
		const headers = isEdit ? existingHeaders : [];
		for (let i = 1; i < dividers.length; i++) {
			const divider = dividers[i];
			const prev = dividers[i - 1];
			const width = isHorizontal
				? divider.startX - prev.startX
				: headerBarLength;
			const height = !isHorizontal
				? divider.startY - prev.startY
				: headerBarLength;

			let gradientArgs, rectArgs, startX, startY, endX, endY;
			switch (barDirection) {
				case BarDirection.DOWN:
					gradientArgs = [0, prev.startY, 0, prev.startY - height];
					startX = prev.startX + headerBarMargin;
					startY = prev.startY - height;
					endX = startX + (width - headerBarMargin * 2);
					endY = startY + height;
					rectArgs = [
						prev.startX + headerBarMargin,
						prev.startY - height,
						width - headerBarMargin * 2,
						height,
					];
					break;
				case BarDirection.UP:
					gradientArgs = [0, prev.startY, 0, prev.startY + height];
					startX = prev.startX + headerBarMargin;
					startY = prev.startY;
					endX = startX + (width - headerBarMargin * 2);
					endY = startY + height;
					rectArgs = [
						prev.startX + headerBarMargin,
						prev.startY,
						width - headerBarMargin * 2,
						height,
					];
					break;
				case BarDirection.LEFT:
					gradientArgs = [prev.startX, 0, prev.startX + width, 0];
					startX = prev.startX;
					startY = prev.startY + headerBarMargin;
					endX = startX + width;
					endY = startY + (height - headerBarMargin * 2);
					rectArgs = [
						prev.startX,
						prev.startY + headerBarMargin,
						width,
						height - headerBarMargin * 2,
					];
					break;
				case BarDirection.RIGHT:
					gradientArgs = [prev.startX, 0, prev.startX - width, 0];
					startX = prev.startX - width;
					startY = prev.startY + headerBarMargin;
					endX = startX + width;
					endY = startY + (height - headerBarMargin * 2);
					rectArgs = [
						prev.startX - width,
						prev.startY + headerBarMargin,
						width,
						height - headerBarMargin * 2,
					];
					break;
			}

			if (isEdit) {
				const existingHeader = headers[i - 1];
				const updatedHeader = {
					...existingHeader,
					rectArgs,
					gradientArgs,
					startX,
					startY,
					endX,
					endY,
				};
				headers[i - 1] = updatedHeader;
			} else {
				headers.push({
					rectArgs,
					gradientArgs,
					startX,
					startY,
					endX,
					endY,
				});
			}
		}

		metadata.barHeaders.headers = headers;
		axis.setMetadata(metadata);
	};

	wpd.barChartUtils.drawHeaderGradients = function (metadata, ctx) {
		const headers = metadata.barHeaders.headers;
		for (let i = 0; i < headers.length; i++) {
			const header = headers[i];
			const gradientGood = ctx.createLinearGradient(
				...wpd.barChartUtils.toScreenPixels(header.gradientArgs)
			);
			const gradientBad = ctx.createLinearGradient(
				...wpd.barChartUtils.toScreenPixels(header.gradientArgs)
			);
			const gradientGoodHover = ctx.createLinearGradient(
				...wpd.barChartUtils.toScreenPixels(header.gradientArgs)
			);
			const gradientBadHover = ctx.createLinearGradient(
				...wpd.barChartUtils.toScreenPixels(header.gradientArgs)
			);

			wpd.Colors.addColorStop("good", gradientGood);
			wpd.Colors.addColorStop("bad", gradientBad);
			wpd.Colors.addColorStop("goodHover", gradientGoodHover);
			wpd.Colors.addColorStop("badHover", gradientBadHover);

			ctx.fillStyle = header.metadata
				? header.hover
					? gradientGoodHover
					: gradientGood
				: header.hover
				? gradientBadHover
				: gradientBad;
			ctx.fillRect(...wpd.barChartUtils.toScreenPixels(header.rectArgs));
		}
	};

	wpd.barChartUtils.buildHeaderDividers = function (axis, metadata, isEdit) {
		const count = metadata.barHeaders?.count || 0;
		if (count < 1) return;

		const { p1, p2 } = metadata.barHeaders;
		const barP1 = axis.calibration.getPoint(0);
		const barP2 = axis.calibration.getPoint(1);
		const barOrientation = metadata.barHeaders.barOrientation;
		const isHorizontal = barOrientation === LineOrientation.HORIZONTAL;
		const intervalSize = isHorizontal
			? Math.abs(p1.py - p2.py) / count
			: Math.abs(p1.px - p2.px) / count;

		const dividerLineLength = isHorizontal
			? Math.max(Math.abs(barP1.px - p1.px), Math.abs(barP2.px - p2.px))
			: Math.max(Math.abs(barP1.py - p1.py), Math.abs(barP2.py - p2.py));

		const barDirection = metadata.barHeaders.barDirection;
		const axisStart = metadata.barHeaders.headerAxisLineStart;
		const axisEnd = metadata.barHeaders.headerAxisLineEnd;

		let startPoint;
		switch (barDirection) {
			case BarDirection.DOWN:
				startPoint = {
					px: Math.min(axisStart[0], axisEnd[0]),
					py: axisStart[1],
				};
				break;
			case BarDirection.UP:
				startPoint = {
					px: Math.min(axisStart[0], axisEnd[0]),
					py: axisStart[1],
				};
				break;

			case BarDirection.LEFT:
				startPoint = {
					px: axisStart[0],
					py: Math.min(axisStart[1], axisEnd[1]),
				};
				break;

			case BarDirection.RIGHT:
				startPoint = {
					px: axisStart[0],
					py: Math.min(axisStart[1], axisEnd[1]),
				};
				break;
		}

		if (!isEdit) {
			metadata.barHeaders.headerDividers = [];
		}
		for (let i = 0; i < count + 1; i++) {
			const mult = i;
			const startX = isHorizontal
				? startPoint.px
				: startPoint.px + mult * intervalSize;
			const startY = isHorizontal
				? startPoint.py + mult * intervalSize
				: startPoint.py;

			const endX = isHorizontal
				? barDirection === BarDirection.RIGHT
					? startPoint.px + dividerLineLength
					: startPoint.px - dividerLineLength
				: startPoint.px + mult * intervalSize;
			const endY = isHorizontal
				? startPoint.py + mult * intervalSize
				: barDirection === BarDirection.DOWN
				? startPoint.py + dividerLineLength
				: startPoint.py - dividerLineLength;

			if (isEdit) {
				metadata.barHeaders.headerDividers[i] = {
					...metadata.barHeaders.headerDividers[i],
					startX: startX,
					startY: startY,
					endX: endX,
					endY: endY,
					hover: false,
					selected: false,
					disabled: i === 0 || i === count,
				};
			} else {
				metadata.barHeaders.headerDividers.push({
					startX: startX,
					startY: startY,
					endX: endX,
					endY: endY,
					hover: false,
					selected: false,
					disabled: i === 0 || i === count,
				});
			}
		}

		axis.setMetadata(metadata);
	};

	wpd.AddBarHeaderTool = class {
		constructor(axes, dataEntryVM) {
			this.axes = axes;
			this.dataEntryVM = dataEntryVM;
			this.metadata = axes.getMetadata();
			this.ctx = wpd.graphicsWidget.getAllContexts().dataCtx;

			wpd.barChartUtils.drawPoints(this.axes);
			wpd.barChartUtils.drawAxisLine(this.ctx, this.axes, this.metadata);
			wpd.barChartUtils.drawHeaderDividers(
				this.ctx,
				this.metadata.barHeaders.headerDividers
			);
		}

		onRedraw() {
			this.metadata = this.axes.getMetadata();

			wpd.barChartUtils.drawPoints(this.axes);
			wpd.barChartUtils.drawAxisLine(this.ctx, this.axes, this.metadata);
			wpd.barChartUtils.drawHeaderDividers(
				this.ctx,
				this.metadata.barHeaders?.headerDividers
			);
		}

		onMouseMove(e, pos, imagePos) {
			wpd.graphicsWidget.resetData();
			wpd.graphicsWidget.forceHandlerRepaint();

			if (!wpd.barChartUtils.isInGraphArea(this.axes, imagePos)) return;

			const metadata = this.axes.getMetadata();
			const direction = metadata.barHeaders.barDirection;
			const dividers = metadata.barHeaders.headerDividers;

			const isHorizontal =
				direction === BarDirection.RIGHT ||
				direction === BarDirection.LEFT;
			const posCoord = isHorizontal ? "y" : "x";
			const startCoord = isHorizontal ? "startY" : "startX";

			let closestDivider = null;
			let minDist = Infinity;
			for (const divider of dividers) {
				const dist = Math.abs(imagePos[posCoord] - divider[startCoord]);
				if (dist < minDist) {
					minDist = dist;
					closestDivider = divider;
				}
			}
			if (!closestDivider) return;

			const toScreen = wpd.barChartUtils.toScreenPixels;
			if (isHorizontal) {
				// Draw guide line (horizontal)
				wpd.barChartUtils.drawLine(
					this.ctx,
					toScreen([closestDivider.startX, imagePos.y]),
					toScreen([closestDivider.endX, imagePos.y]),
					wpd.Colors.activeDark
				);

				// Draw filled rectangle
				const minX = Math.min(
					closestDivider.startX,
					closestDivider.endX
				);
				const minY = Math.min(imagePos.y, closestDivider.startY);
				const height = Math.abs(imagePos.y - closestDivider.startY);
				const width = Math.abs(
					closestDivider.endX - closestDivider.startX
				);

				this.ctx.fillStyle = wpd.Colors.activeA;
				this.ctx.fillRect(...toScreen([minX, minY, width, height]));
			} else {
				// Draw guide line (vertical)
				wpd.barChartUtils.drawLine(
					this.ctx,
					toScreen([imagePos.x, closestDivider.startY]),
					toScreen([imagePos.x, closestDivider.endY]),
					wpd.Colors.activeDark
				);

				// Draw filled rectangle
				const minX = Math.min(imagePos.x, closestDivider.startX);
				const minY = Math.min(
					closestDivider.startY,
					closestDivider.endY
				);
				const width = Math.abs(imagePos.x - closestDivider.startX);
				const height = Math.abs(
					closestDivider.startY - closestDivider.endY
				);

				this.ctx.fillStyle = wpd.Colors.activeA;
				this.ctx.fillRect(...toScreen([minX, minY, width, height]));
			}
		}

		onMouseDown(e, pos, imagePos) {
			wpd.graphicsWidget.forceHandlerRepaint();

			if (!wpd.barChartUtils.isInGraphArea(this.axes, imagePos)) return;

			const metadata = this.axes.getMetadata();
			const direction = metadata.barHeaders.barDirection;
			const dividers = metadata.barHeaders.headerDividers;
			const isHorizontal =
				direction === BarDirection.RIGHT ||
				direction === BarDirection.LEFT;
			const posCoord = isHorizontal ? "y" : "x";
			const startCoord = isHorizontal ? "startY" : "startX";

			let closestDivider = null;
			let closestIndex = null;
			let minDist = Infinity;
			for (let i = 0; i < dividers.length; i++) {
				const divider = dividers[i];
				const dist = Math.abs(imagePos[posCoord] - divider[startCoord]);
				if (dist < minDist) {
					minDist = dist;
					closestDivider = divider;
					closestIndex = i;
				}
			}

			if (!closestDivider) return;

			const insertBefore =
				imagePos[posCoord] < closestDivider[startCoord];
			const index = insertBefore ? closestIndex : closestIndex + 1;

			const newDivider = {
				startX: isHorizontal ? closestDivider.startX : imagePos.x,
				endX: isHorizontal ? closestDivider.endX : imagePos.x,
				startY: isHorizontal ? imagePos.y : closestDivider.startY,
				endY: isHorizontal ? imagePos.y : closestDivider.endY,
				hover: false,
				selected: false,
				disabled: false,
			};

			this.metadata.barHeaders.headerDividers.splice(
				index,
				0,
				newDivider
			);
			this.metadata.barHeaders.headers.splice(closestIndex, 0, {});
			this.metadata.barHeaders.count += 1;
			this.axes.setMetadata(this.metadata);

			wpd.barChartUtils.buildHeaderAreas(this.axes, this.metadata, true);
			wpd.barChartUtils.drawHeaderGradients(this.metadata, this.ctx);

			wpd.graphicsWidget.removeTool();
			wpd.graphicsWidget.resetData();
			wpd.graphicsWidget.setTool(
				new wpd.BarHeadersTool(this.axes, this.dataEntryVM, true)
			);
			wpd.sidebar.show("edit-bar-graph-sidebar");
			wpd.graphicsWidget.forceHandlerRepaint();
		}
	};

	wpd.RemoveBarHeaderTool = class {
		constructor(axes, dataEntryVM) {
			this.axes = axes;
			this.metadata = axes.getMetadata();
			this.ctx = wpd.graphicsWidget.getAllContexts().dataCtx;

			wpd.barChartUtils.drawPoints(this.axes);
			wpd.barChartUtils.drawAxisLine(this.ctx, this.axes, this.metadata);
			wpd.barChartUtils.drawHeaderDividers(
				this.ctx,
				this.metadata.barHeaders.headerDividers
			);
		}

		onRedraw() {
			this.metadata = this.axes.getMetadata();

			wpd.barChartUtils.drawPoints(this.axes);
			wpd.barChartUtils.drawAxisLine(this.ctx, this.axes, this.metadata);
			wpd.barChartUtils.drawHeaderDividers(
				this.ctx,
				this.metadata.barHeaders?.headerDividers
			);
		}

		onMouseMove(e, pos, imagePos) {
			wpd.graphicsWidget.resetData();
			wpd.graphicsWidget.forceHandlerRepaint();

			if (!wpd.barChartUtils.isInGraphArea(this.axes, imagePos)) return;

			const metadata = this.axes.getMetadata();
			const direction = metadata.barHeaders.barDirection;
			const dividers = metadata.barHeaders.headerDividers;
			const isHorizontal =
				direction === BarDirection.RIGHT ||
				direction === BarDirection.LEFT;

			const posCoord = isHorizontal ? "y" : "x";
			const startCoord = isHorizontal ? "startY" : "startX";
			for (let i = 0; i < dividers.length - 1; i++) {
				const divider = dividers[i];
				const nextDivider = dividers[i + 1];

				const posValue = imagePos[posCoord];
				const start = divider[startCoord];
				const end = nextDivider[startCoord];

				const isBetween = start < posValue && posValue < end;

				if (isBetween) {
					const ctx = this.ctx;
					const toScreen = wpd.barChartUtils.toScreenPixels;
					const drawLine = wpd.barChartUtils.drawLine;

					// Draw lines at both dividers
					drawLine(
						ctx,
						toScreen([divider.startX, divider.startY]),
						toScreen([divider.endX, divider.endY]),
						wpd.Colors.activeDark
					);
					drawLine(
						ctx,
						toScreen([nextDivider.startX, nextDivider.startY]),
						toScreen([nextDivider.endX, nextDivider.endY]),
						wpd.Colors.activeDark
					);

					// Draw fill between the two
					ctx.fillStyle = wpd.Colors.activeA;

					if (isHorizontal) {
						const x = divider.startX;
						const y = divider.startY;
						const width = divider.endX - divider.startX;
						const height = nextDivider.startY - divider.startY;
						ctx.fillRect(...toScreen([x, y, width, height]));
					} else {
						const x = divider.startX;
						const y = divider.startY;
						const width = nextDivider.startX - divider.startX;
						const height = divider.endY - divider.startY;
						ctx.fillRect(...toScreen([x, y, width, height]));
					}

					return;
				}
			}
		}

		onMouseDown(e, pos, imagePos) {
			wpd.graphicsWidget.forceHandlerRepaint();

			if (!wpd.barChartUtils.isInGraphArea(this.axes, imagePos)) return;

			const metadata = this.axes.getMetadata();
			const direction = metadata.barHeaders.barDirection;
			const dividers = metadata.barHeaders.headerDividers;

			if (dividers.length <= 2) return;
			const isHorizontal =
				direction === BarDirection.RIGHT ||
				direction === BarDirection.LEFT;

			const posCoord = isHorizontal ? "y" : "x";
			const startCoord = isHorizontal ? "startY" : "startX";

			for (let i = 0; i < dividers.length - 1; i++) {
				const divider = dividers[i];
				const nextDivider = dividers[i + 1];

				const posValue = imagePos[posCoord];
				const start = divider[startCoord];
				const end = nextDivider[startCoord];

				const isBetween = start < posValue && posValue < end;

				if (isBetween) {
					const index = i === dividers.length - 2 ? i : i + 1;
					this.metadata.barHeaders.headerDividers.splice(index, 1);
					this.metadata.barHeaders.headers.splice(i, 1);
					this.metadata.barHeaders.count -= 1;
					this.axes.setMetadata(this.metadata);
					break;
				}
			}

			wpd.graphicsWidget.removeTool();
			wpd.graphicsWidget.resetData();
			wpd.graphicsWidget.setTool(
				new wpd.BarHeadersTool(this.axes, this.dataEntryVM, true)
			);
			wpd.sidebar.show("edit-bar-graph-sidebar");
			wpd.graphicsWidget.forceHandlerRepaint();
		}
	};

	wpd.BarHeadersTool = class {
		constructor(axes, dataEntryVM, isEdit) {
			this.axes = axes;
			this.isEdit = isEdit;
			this.metadata = axes.getMetadata();
			this.ctx = wpd.graphicsWidget.getAllContexts().dataCtx;
			this.vm = dataEntryVM;
			this.lock = false;

			if (!this.isEdit) {
				const barP1 = this.axes.calibration.getPoint(0);
				const barP2 = this.axes.calibration.getPoint(1);
				const barAxisOrientation = wpd.barChartUtils.getLineOrientation(
					barP1,
					barP2
				);
				this.metadata.barHeaders.barOrientation = barAxisOrientation;
				const { p1: headerP1, p2: headerP2 } = this.metadata.barHeaders;
				const headerAxisOrientation =
					wpd.barChartUtils.getLineOrientation(headerP1, headerP2);
				this.metadata.barHeaders.headerOrientation =
					headerAxisOrientation;
				const barDirection = wpd.barChartUtils.getBarDirection(
					this.metadata,
					barP1,
					barP2,
					headerP1
				);
				this.metadata.barHeaders.barDirection = barDirection;
				this.axes.setMetadata(this.metadata);
			}

			wpd.barChartUtils.drawPoints(this.axes);
			wpd.barChartUtils.drawAxisLine(this.ctx, this.axes, this.metadata);
			if (!isEdit) {
				wpd.barChartUtils.buildHeaderDividers(this.axes, this.metadata);
			}
			wpd.barChartUtils.drawHeaderDividers(
				this.ctx,
				this.metadata.barHeaders.headerDividers
			);

			if (isEdit) {
				wpd.barChartUtils.buildHeaderAreas(
					this.axes,
					this.metadata,
					this.isEdit
				);
				wpd.barChartUtils.drawHeaderGradients(this.metadata, this.ctx);
			}
		}

		onRedraw() {
			this.metadata = this.axes.getMetadata();

			wpd.barChartUtils.drawPoints(this.axes);
			wpd.barChartUtils.drawAxisLine(this.ctx, this.axes, this.metadata);
			wpd.barChartUtils.drawHeaderDividers(
				this.ctx,
				this.metadata.barHeaders?.headerDividers
			);

			if (this.isEdit || this.linesLocked()) {
				wpd.barChartUtils.buildHeaderAreas(
					this.axes,
					this.metadata,
					this.isEdit
				);
				wpd.barChartUtils.drawHeaderGradients(this.metadata, this.ctx);
			}
		}

		onMouseMove(e, pos, imagePos) {
			if (this.lock) return;

			const isInArea = wpd.barChartUtils.isInGraphArea(
				this.axes,
				imagePos
			);

			if (this.isEdit) {
				if (isInArea) {
					this.onMouseMoveAxisLines(e, pos, imagePos);
				}
				this.onMouseMoveHeaders(e, pos, imagePos);
			} else {
				if (this.linesLocked()) {
					this.onMouseMoveHeaders(e, pos, imagePos);
				} else if (isInArea) {
					this.onMouseMoveAxisLines(e, pos, imagePos);
				}
			}
		}

		onMouseMoveHeaders(e, pos, imagePos) {
			const headers = this.metadata.barHeaders.headers;
			for (let header of headers) {
				const overlaps = this.rectOverlapTest(header, imagePos);
				if (overlaps) {
					header.hover = true;
				} else {
					header.hover = false;
				}
			}

			this.axes.setMetadata(this.metadata);
			wpd.graphicsWidget.forceHandlerRepaint();
		}

		onMouseMoveAxisLines(e, pos, imagePos) {
			const barLineOrientation = this.metadata.barHeaders.barOrientation;
			const dividers = this.metadata.barHeaders.headerDividers;
			const selectedDividerIndex = dividers.findIndex(
				(divider) => divider.selected
			);

			if (selectedDividerIndex !== -1) {
				e.target.style.cursor = "grabbing";
				this.moveLine(selectedDividerIndex, imagePos);
				return;
			} else {
				let isHovered = false;

				for (let i = 0; i < dividers.length; i++) {
					const divider = dividers[i];
					if (divider.disabled) continue;

					if (
						barLineOrientation === LineOrientation.HORIZONTAL &&
						this.horizontalLineOverlapTest(
							imagePos.y,
							divider.startY
						)
					) {
						divider.hover = true;
						isHovered = true;
						e.target.style.cursor = "grab";
					} else if (
						barLineOrientation === LineOrientation.VERTICAL &&
						this.verticalLineOverlapTest(imagePos.x, divider.startX)
					) {
						divider.hover = true;
						isHovered = true;
						e.target.style.cursor = "grab";
					} else {
						divider.hover = false;
					}
				}

				if (!isHovered) {
					e.target.style.cursor = "crosshair";
				}
			}
			this.axes.setMetadata(this.metadata);
			wpd.graphicsWidget.forceHandlerRepaint();
		}

		onMouseDown(e, pos, imagePos) {
			if (this.lock) return;

			const isInArea = wpd.barChartUtils.isInGraphArea(
				this.axes,
				imagePos
			);

			if (this.isEdit) {
				this.onMouseDownAxisLines(e, pos, imagePos);
				this.onMouseDownHeaders(e, pos, imagePos);
			} else {
				if (this.linesLocked()) {
					this.onMouseDownHeaders(e, pos, imagePos);
				} else if (isInArea) {
					this.onMouseDownAxisLines(e, pos, imagePos);
				}
			}
		}

		onMouseDownAxisLines(e, pos, imagePos) {
			const barLineOrientation = this.metadata.barHeaders.barOrientation;
			const dividers = this.metadata.barHeaders.headerDividers;
			for (let i = 0; i < dividers.length; i++) {
				const divider = dividers[i];
				if (divider.disabled) continue;

				if (
					barLineOrientation === LineOrientation.HORIZONTAL &&
					this.horizontalLineOverlapTest(imagePos.y, divider.startY)
				) {
					divider.selected = true;
					e.target.style.cursor = "grabbing";
					break;
				}
				if (
					barLineOrientation === LineOrientation.VERTICAL &&
					this.verticalLineOverlapTest(imagePos.x, divider.startX)
				) {
					divider.selected = true;
					e.target.style.cursor = "grabbing";
					break;
				}
			}

			this.axes.setMetadata(this.metadata);
			wpd.graphicsWidget.forceHandlerRepaint();
		}

		async onMouseDownHeaders(e, pos, imagePos) {
			const headers = this.metadata.barHeaders.headers;

			for (let i = 0; i < headers.length; i++) {
				const header = headers[i];
				const overlaps = this.rectOverlapTest(header, imagePos);
				if (overlaps) {
					await this.collectHeaderData(i);
					break;
				}
			}
		}

		onMouseUp(e, pos, imagePos) {
			if (this.lock || (!this.isEdit && this.linesLocked())) return;

			if (e.target.style.cursor === "grabbing") {
				e.target.style.cursor = "grab";
			} else {
				e.target.style.cursor = "crosshair";
			}

			this.metadata.barHeaders.headerDividers.map((divider) => {
				divider.selected = false;
			});

			this.axes.setMetadata(this.metadata);
		}

		async collectHeaderData(index) {
			const header = this.metadata.barHeaders.headers[index];
			const headerPosition = this.getHeaderDialogPosition(header);
			const barDirection = this.getRotatedBarDirection();

			const editing = !!header.metadata;

			// include offset and scroll positions from wpd graphics container
			const graphicsContainer =
				wpdDocument.getElementById("graphicsContainer");
			const canvasDiv = wpdDocument.getElementById("canvasDiv");
			const marginLeftText =
				wpdWindow.getComputedStyle(canvasDiv).marginLeft;
			const marginLeft = parseInt(marginLeftText.replace("px", ""), 10);
			const finalPosition = {
				x: headerPosition.x - graphicsContainer.scrollLeft + marginLeft,
				y: headerPosition.y - graphicsContainer.scrollTop,
			};

			let direction;
			if (barDirection === BarDirection.DOWN) {
				direction = "north";
			} else if (barDirection === BarDirection.UP) {
				direction = "south";
			} else if (barDirection === BarDirection.LEFT) {
				direction = "east";
			} else {
				direction = "west";
			}
			const allTagGroups = wpd.tagGroups.getAllTagGroups();
			const filteredTagGroupUuids = allTagGroups
				.filter((group) =>
					["Outcomes", "Baseline/Demographics"].includes(group.name)
				)
				.map((group) => group.uuid);

			const defaultTags = this.getDefaultHeaderTags(
				this.metadata.barHeaders.headers
			);

			const defaultData = _.isEmpty(header.metadata)
				? defaultTags
				: header.metadata;

			this.lock = true;
			const headerMetadata = await this.vm
				.captureMetadata({
					axisType: "barGraphHeader",
					compositeKeys: wpd.tagGroups.compositeKeys,
					data: defaultData,
					forceDirection: direction,
					limitTagGroups: filteredTagGroupUuids,
					position: finalPosition,
					studyArmAbbreviations: wpd.tagGroups.getAllStudyArmKeys(),
					selectTextFunction: wpd.acquireData.selectText,
				})
				.then(async (metadata) => {
					this.lock = false;
					return metadata;
				});

			// filter incomplete tags (missing either the tag or the value) from the set
			const filteredMetadata =
				wpd.custom.filterCapturedMetadata(headerMetadata);

			const studyKeyTag = _.find(wpd.tagGroups.tags, {
				name: wpd.CompositeKeyUtils.studyTag,
			});
			const partKeyTag = _.find(wpd.tagGroups.tags, {
				name: wpd.CompositeKeyUtils.studyPartTag,
			});
			const armKeyTag = _.find(wpd.tagGroups.tags, {
				name: wpd.CompositeKeyUtils.studyArmTag,
			});
			const populationKeyTag = _.find(wpd.tagGroups.tags, {
				name: wpd.CompositeKeyUtils.populationTag,
			});

			let maxIndex = Math.max(
				...Object.keys(filteredMetadata)
					.filter((key) => !isNaN(key))
					.map((key) => parseInt(key, 10))
			);

			const extraData = [];
			Object.entries(filteredMetadata).forEach(([key, data]) => {
				if (wpd.utils.isWord(data, key)) {
					return;
				}
				const isKey = wpd.tagGroups.isKeyTag(data.tag.name);
				if (isKey) {
					if (!filteredMetadata.compositeKey) {
						filteredMetadata.compositeKey = data.value;
					}
					const parsedKey = wpd.CompositeKeyUtils.parseCompositeKey(
						data.value
					);
					data.isCompositeKey = true;
					switch (data.tag.name) {
						case wpd.CompositeKeyUtils.subgroupTag:
							data.value = parsedKey.subgroup;
							if (parsedKey.population) {
								extraData.push(
									_buildHiddenMetadataPoint(
										populationKeyTag,
										parsedKey.population
									)
								);
							}
							extraData.push(
								_buildHiddenMetadataPoint(
									armKeyTag,
									parsedKey.studyArm
								)
							);
							if (parsedKey.studyPart) {
								extraData.push(
									_buildHiddenMetadataPoint(
										partKeyTag,
										parsedKey.studyPart
									)
								);
							}
							extraData.push(
								_buildHiddenMetadataPoint(
									studyKeyTag,
									parsedKey.study
								)
							);
							break;
						case wpd.CompositeKeyUtils.populationTag:
							data.value = parsedKey.population;
							extraData.push(
								_buildHiddenMetadataPoint(
									armKeyTag,
									parsedKey.studyArm
								)
							);
							if (parsedKey.studyPart) {
								extraData.push(
									_buildHiddenMetadataPoint(
										partKeyTag,
										parsedKey.studyPart
									)
								);
							}
							extraData.push(
								_buildHiddenMetadataPoint(
									studyKeyTag,
									parsedKey.study
								)
							);
							break;
						case wpd.CompositeKeyUtils.studyArmTag:
							data.value = parsedKey.studyArm;
							if (parsedKey.studyPart) {
								extraData.push(
									_buildHiddenMetadataPoint(
										partKeyTag,
										parsedKey.studyPart
									)
								);
							}
							extraData.push(
								_buildHiddenMetadataPoint(
									studyKeyTag,
									parsedKey.study
								)
							);
							break;
						case wpd.CompositeKeyUtils.studyPartTag:
							data.value = parsedKey.studyPart;
							extraData.push(
								_buildHiddenMetadataPoint(
									studyKeyTag,
									parsedKey.study
								)
							);
							break;
						case wpd.CompositeKeyUtils.studyTag:
							data.value = parsedKey.study;
							break;
					}
				}
			});

			extraData.forEach((data) => {
				filteredMetadata[++maxIndex] = data;
			});

			// check if there is any remaining valid metadata
			const hasValidMetadata = Object.keys(filteredMetadata).some(
				(key) => !wpd.utils.isWord(filteredMetadata[key], key)
			);

			// set both metadata and local calibration to enable repainting
			if (!hasValidMetadata) {
				header.metadata = null;
			} else {
				header.metadata = filteredMetadata;
			}

			this.axes.setMetadata(this.metadata);
			wpd.graphicsWidget.forceHandlerRepaint();

			await dataEntryVM.autoSaveMetadata();

			// trigger the metadata dialog for the next empty header
			if (!editing && hasValidMetadata) {
				let nextIndex = _.findIndex(
					this.metadata.barHeaders.headers,
					(header) => !header.metadata,
					index
				);

				// loop and start over from 0 if not found
				if (nextIndex < 0) {
					nextIndex = _.findIndex(
						this.metadata.barHeaders.headers,
						(header) => !header.metadata
					);
				}

				// next empty index found
				if (nextIndex > -1) {
					await this.collectHeaderData(nextIndex);
				}
			}
		}

		_buildHiddenMetadataPoint(tag, value) {
			return {
				tag: {
					name: tag.name,
					uuid: tag.uuid,
				},
				value: value,
				hidden: true,
			};
		}

		getDefaultHeaderTags(headers) {
			// get the set of default tags for new headers
			const existingTagUuids = Array.from(
				headers.reduce((acc, header) => {
					Object.values(
						_.omitBy(header.metadata, wpd.utils.isWord)
					).forEach((data) => {
						if (data.tag?.uuid && !data.hidden) {
							acc.add(data.tag.uuid);
						}
					});
					return acc;
				}, new Set())
			);

			return existingTagUuids.reduce((acc, tagUUID, tagIndex) => {
				acc[tagIndex] = { value: null, tagUUID };
				return acc;
			}, {});
		}

		moveLine(selectedDividerIndex, cursorPos) {
			const dividers = this.metadata.barHeaders.headerDividers;
			const selectedLine = dividers[selectedDividerIndex];
			const headerOrientation =
				this.metadata.barHeaders.headerOrientation;

			if (headerOrientation === LineOrientation.HORIZONTAL) {
				const minX =
					dividers[selectedDividerIndex - 1].startX +
					headerLineMargin;
				const maxX =
					dividers[selectedDividerIndex + 1].startX -
					headerLineMargin;

				if (cursorPos.x > minX && cursorPos.x < maxX) {
					selectedLine.startX = cursorPos.x;
					selectedLine.endX = cursorPos.x;
				}
			} else {
				const minY =
					dividers[selectedDividerIndex - 1].startY +
					headerLineMargin;
				const maxY =
					dividers[selectedDividerIndex + 1].startY -
					headerLineMargin;
				if (cursorPos.y > minY && cursorPos.y < maxY) {
					selectedLine.startY = cursorPos.y;
					selectedLine.endY = cursorPos.y;
				}
			}

			this.axes.setMetadata(this.metadata);
			wpd.graphicsWidget.forceHandlerRepaint();
		}

		horizontalLineOverlapTest(cursorY, lineY) {
			return (
				cursorY >= lineY - mouseOverHitThreshold &&
				cursorY <= lineY + mouseOverHitThreshold
			);
		}

		verticalLineOverlapTest(cursorX, lineX) {
			return (
				cursorX >= lineX - mouseOverHitThreshold &&
				cursorX <= lineX + mouseOverHitThreshold
			);
		}

		rectOverlapTest(header, imagePos) {
			return (
				imagePos.x > header.startX &&
				imagePos.x < header.endX &&
				imagePos.y > header.startY &&
				imagePos.y < header.endY
			);
		}

		linesLocked() {
			return this.metadata.barHeaders.linesLocked;
		}

		getRotatedBarDirection() {
			const direction = this.metadata.barHeaders.barDirection;
			const directions = [
				BarDirection.UP,
				BarDirection.RIGHT,
				BarDirection.DOWN,
				BarDirection.LEFT,
			];

			const rotation = wpd.graphicsWidget.getRotation();
			const dirIndex = directions.indexOf(direction);
			const steps = (rotation / 90) % 4;
			return directions[(dirIndex + steps) % 4];
		}

		getAxisFlip(rotation) {
			return {
				x: rotation === 180 || rotation === 270,
				y: rotation === 90 || rotation === 180,
			};
		}

		getHeaderDialogPosition(header) {
			const barDirection = this.getRotatedBarDirection();
			const rotation = wpd.graphicsWidget.getRotation();
			const swapXY = wpd.alignAxes.swapXY(rotation);
			const axisFlip = this.getAxisFlip(rotation);

			// Compute base coordinates based on direction
			let baseX, baseY;

			if (
				barDirection === BarDirection.UP ||
				barDirection === BarDirection.DOWN
			) {
				baseX = swapXY
					? axisFlip.x
						? barDirection === BarDirection.UP
							? header.startX
							: header.endX
						: barDirection === BarDirection.UP
						? header.endX
						: header.startX
					: (header.startX + header.endX) / 2;

				baseY = swapXY
					? (header.startY + header.endY) / 2
					: axisFlip.y
					? barDirection === BarDirection.UP
						? header.startY
						: header.endY
					: barDirection === BarDirection.UP
					? header.endY
					: header.startY;
			} else if (
				barDirection === BarDirection.LEFT ||
				barDirection === BarDirection.RIGHT
			) {
				baseX = swapXY
					? (header.startX + header.endX) / 2
					: axisFlip.x
					? barDirection === BarDirection.LEFT
						? header.startX
						: header.endX
					: barDirection === BarDirection.LEFT
					? header.endX
					: header.startX;

				baseY = swapXY
					? axisFlip.y
						? barDirection === BarDirection.LEFT
							? header.startY
							: header.endY
						: barDirection === BarDirection.LEFT
						? header.endY
						: header.startY
					: (header.startY + header.endY) / 2;
			}

			// Rotate and convert to screen coordinates
			const rotated = wpd.graphicsWidget.getRotatedCoordinates(
				0,
				rotation,
				baseX,
				baseY
			);

			return {
				x: wpd.graphicsWidget.toScreenPixel(rotated.x),
				y: wpd.graphicsWidget.toScreenPixel(rotated.y),
			};
		}
	};
}

export { init };
