/**
 * Adds repainters for table axis.
 */
function apply(wpd, dataEntryVM) {
	/**
	 * Repainter class responsible for drawing the rectangle. All pixel values and rotations here are bound to the image,
	 * unless otherwise noted.
	 */
	wpd.TableRepainter = class {
		constructor(table) {
			this.ctx = wpd.graphicsWidget.getAllContexts().dataCtx;

			this.area = table.area;
			this.rows = table.rows;
			this.cols = table.cols;

			// calculate area min/max to always get the same corners
			this.areaMin = 0;
			this.areaMax = 0;
			this.calculateAreaMinMax();

			// rotation related variables
			this.rotation = table.rotation ?? 0;
			this.swap = wpd.TableRepainter.swapTest(this.rotation);
			this.topLeft = ((rotation) => {
				const topLeftDirection = {
					0: { x: "-", y: "-" },
					90: { x: "-", y: "+" },
					180: { x: "+", y: "+" },
					270: { x: "+", y: "-" },
				};
				return topLeftDirection[rotation];
			})(this.rotation);

			this.drawHeaders = table.drawHeaders ?? true;
			this.hover = table.hover;
			this.selectedDividers = table.selectedDividers;
			this.selectedBorder = table.selectedBorder;
			this.activeCell = table.activeCell;
			this.colData = table.colData;
			this.addPreview = table.addPreview;
			this.removePreview = table.removePreview;
			this.editing = table.editing;
			this.isRow = table.isRow;

			this.painterName = "TableRepainter";
		}

		// factor to multiply cell width or height by for headers
		static rowHeaderGradientFactor = 3;
		static colHeaderGradientFactor = 3;

		// position of row and column headers for all rotations
		static headerLocation = {
			rows: {
				0: "left",
				90: "bottom",
				180: "right",
				270: "top",
			},
			cols: {
				0: "top",
				90: "left",
				180: "bottom",
				270: "right",
			},
		};

		static addLocation = {
			rows: {
				0: "right",
				90: "top",
				180: "left",
				270: "bottom",
			},
			cols: {
				0: "bottom",
				90: "right",
				180: "top",
				270: "left",
			},
		};

		static swapTest(rotation) {
			// true if rotation is 90 or 270 degrees
			return rotation % 180 === 90;
		}

		static getTopLeftDirection(rotation) {
			const directionMap = {
				0: { x: "-", y: "-" },
				90: { x: "-", y: "+" },
				180: { x: "+", y: "+" },
				270: { x: "+", y: "-" },
			};

			return directionMap[rotation];
		}

		calculateAreaMinMax() {
			if (this.area.length > 1) {
				this.areaMin = {
					x:
						this.area[0].x > this.area[1].x
							? this.area[1].x
							: this.area[0].x,
					y:
						this.area[0].y > this.area[1].y
							? this.area[1].y
							: this.area[0].y,
				};
				this.areaMax = {
					x:
						this.area[0].x > this.area[1].x
							? this.area[0].x
							: this.area[1].x,
					y:
						this.area[0].y > this.area[1].y
							? this.area[0].y
							: this.area[1].y,
				};
			}
		}

		toScreenPixels(imagePixels) {
			const pageManager = wpd.appData.getPageManager();
			let pdfScaleFactor = 1;
			if (pageManager instanceof wpd.PDFManager) {
				pdfScaleFactor = pageManager.currentScaleFactor;
			}

			const zoomRatio =
				wpd.graphicsWidget.getZoomRatio() * pdfScaleFactor;

			return imagePixels.map((pixel) => pixel * zoomRatio);
		}

		getAreaWidth() {
			return this.areaMax.x - this.areaMin.x;
		}

		getAreaHeight() {
			return this.areaMax.y - this.areaMin.y;
		}

		getRowCount() {
			return this.rows.dividers.length + 1;
		}

		getColumnCount() {
			return this.cols.dividers.length + 1;
		}

		getAverageCellWidth() {
			return (
				this.getAreaWidth() /
				(this.swap ? this.getRowCount() : this.getColumnCount())
			);
		}

		getAverageCellHeight() {
			return (
				this.getAreaHeight() /
				(this.swap ? this.getColumnCount() : this.getRowCount())
			);
		}

		getCellWidth(colIndex) {
			const dividers = this.swap
				? this.rows.dividers
				: this.cols.dividers;
			const ends =
				this.topLeft.x === "-"
					? [this.areaMin.x, this.areaMax.x]
					: [this.areaMax.x, this.areaMin.x];

			const x0 = colIndex === 0 ? ends[0] : dividers[colIndex - 1];
			const x1 =
				colIndex === dividers.length ? ends[1] : dividers[colIndex];

			return x1 - x0;
		}

		getCellHeight(rowIndex) {
			const dividers = this.swap
				? this.cols.dividers
				: this.rows.dividers;
			const ends =
				this.topLeft.y === "-"
					? [this.areaMin.y, this.areaMax.y]
					: [this.areaMax.y, this.areaMin.y];

			const y0 = rowIndex === 0 ? ends[0] : dividers[rowIndex - 1];
			const y1 =
				rowIndex === dividers.length ? ends[1] : dividers[rowIndex];

			return y1 - y0;
		}

		getColStart(colIndex) {
			const axis = this.swap ? "y" : "x";

			const index = colIndex - 1;
			const areaStart =
				this.topLeft[axis] === "+"
					? this.areaMax[axis]
					: this.areaMin[axis];

			// if the active column is the first or last, use the x value of the area
			return colIndex < 1 ? areaStart : this.cols.dividers[index];
		}

		getRowStart(rowIndex) {
			const axis = this.swap ? "x" : "y";

			const index = rowIndex - 1;
			const areaStart =
				this.topLeft[axis] === "+"
					? this.areaMax[axis]
					: this.areaMin[axis];

			// if the active column is the first or last, use the x value of the area
			return rowIndex < 1 ? areaStart : this.rows.dividers[index];
		}

		drawTableArea(ctx) {
			// draw a black rectangle
			if (this.area.length > 1) {
				// set active color when defining area
				if (!this.rows?.dividers && !this.cols?.dividers) {
					ctx.strokeStyle = wpd.Colors.active;
				} else {
					ctx.strokeStyle = wpd.Colors.headerActiveDarkA;
				}

				ctx.lineWidth = 6;

				// recalculate area min/max to get the top-left and bottom-right corners, related to image
				this.calculateAreaMinMax();

				ctx.strokeRect(
					...this.toScreenPixels([
						this.areaMin.x,
						this.areaMin.y,
						this.getAreaWidth(),
						this.getAreaHeight(),
					])
				);
			}
		}

		drawTableResizeHighlight(ctx) {
			if (this.selectedBorder?.corner || this.selectedBorder?.edge) {
				const minX = this.areaMin.x;
				const minY = this.areaMin.y;
				const maxX = this.areaMax.x;
				const maxY = this.areaMax.y;

				const corner = this.selectedBorder.corner;
				const edge = this.selectedBorder.edge;
				const buffer = 4;
				const cornerLength = 35;

				ctx.beginPath();
				ctx.lineWidth = 8;
				ctx.strokeStyle = wpd.Colors.active;

				if (corner === "top-left") {
					const moveToArgs = [minX - buffer, minY];
					const moveToArgs2 = [minX, minY - buffer];
					const lineToArgs = [minX + cornerLength, minY];
					const lineToArgs2 = [minX, minY + cornerLength];
					ctx.moveTo(...this.toScreenPixels(moveToArgs));
					ctx.lineTo(...this.toScreenPixels(lineToArgs));
					ctx.moveTo(...this.toScreenPixels(moveToArgs2));
					ctx.lineTo(...this.toScreenPixels(lineToArgs2));
					ctx.stroke();
				} else if (corner === "top-right") {
					const moveToArgs = [maxX + buffer, minY];
					const moveToArgs2 = [maxX, minY - buffer];
					const lineToArgs = [maxX - cornerLength, minY];
					const lineToArgs2 = [maxX, minY + cornerLength];
					ctx.moveTo(...this.toScreenPixels(moveToArgs));
					ctx.lineTo(...this.toScreenPixels(lineToArgs));
					ctx.moveTo(...this.toScreenPixels(moveToArgs2));
					ctx.lineTo(...this.toScreenPixels(lineToArgs2));
					ctx.stroke();
				} else if (corner === "bottom-right") {
					const moveToArgs = [maxX + buffer, maxY];
					const moveToArgs2 = [maxX, maxY + buffer];
					const lineToArgs = [maxX - cornerLength, maxY];
					const lineToArgs2 = [maxX, maxY - cornerLength];
					ctx.moveTo(...this.toScreenPixels(moveToArgs));
					ctx.lineTo(...this.toScreenPixels(lineToArgs));
					ctx.moveTo(...this.toScreenPixels(moveToArgs2));
					ctx.lineTo(...this.toScreenPixels(lineToArgs2));
					ctx.stroke();
				} else if (corner === "bottom-left") {
					const moveToArgs = [minX - buffer, maxY];
					const moveToArgs2 = [minX, maxY + buffer];
					const lineToArgs = [minX + cornerLength, maxY];
					const lineToArgs2 = [minX, maxY - cornerLength];
					ctx.moveTo(...this.toScreenPixels(moveToArgs));
					ctx.lineTo(...this.toScreenPixels(lineToArgs));
					ctx.moveTo(...this.toScreenPixels(moveToArgs2));
					ctx.lineTo(...this.toScreenPixels(lineToArgs2));
					ctx.stroke();
				} else if (edge === "top") {
					const moveToArgs = [minX - buffer, minY];
					const lineToArgs = [maxX + buffer, minY];
					ctx.moveTo(...this.toScreenPixels(moveToArgs));
					ctx.lineTo(...this.toScreenPixels(lineToArgs));
					ctx.stroke();
				} else if (edge === "right") {
					const moveToArgs = [maxX, minY - buffer];
					const lineToArgs = [maxX, maxY + buffer];
					ctx.moveTo(...this.toScreenPixels(moveToArgs));
					ctx.lineTo(...this.toScreenPixels(lineToArgs));
					ctx.stroke();
				} else if (edge === "bottom") {
					const moveToArgs = [minX - buffer, maxY];
					const lineToArgs = [maxX + buffer, maxY];
					ctx.moveTo(...this.toScreenPixels(moveToArgs));
					ctx.lineTo(...this.toScreenPixels(lineToArgs));
					ctx.stroke();
				} else if (edge === "left") {
					const moveToArgs = [minX, minY - buffer];
					const lineToArgs = [minX, maxY + buffer];
					ctx.moveTo(...this.toScreenPixels(moveToArgs));
					ctx.lineTo(...this.toScreenPixels(lineToArgs));
					ctx.stroke();
				}
			}
		}

		drawRowDivision(ctx, pos, selected) {
			const buffer = ctx.lineWidth / 2;

			ctx.beginPath();
			ctx.lineWidth = 6;
			ctx.strokeStyle = selected
				? wpd.Colors.active
				: this.editing && !this.isRow
				? wpd.Colors.headerActiveA
				: wpd.Colors.headerActiveDarkA;

			// const i = this.selectedDividers.row;
			const moveToArgs = this.swap
				? [pos, this.areaMin.y + buffer]
				: [this.areaMin.x + buffer, pos];

			const lineToArgs = this.swap
				? [pos, this.areaMax.y - buffer]
				: [this.areaMax.x - buffer, pos];

			ctx.moveTo(...this.toScreenPixels(moveToArgs));
			ctx.lineTo(...this.toScreenPixels(lineToArgs));
			ctx.stroke();
		}

		drawColDivision(ctx, pos, selected) {
			const buffer = ctx.lineWidth / 2;

			ctx.beginPath();
			ctx.lineWidth = 6;
			ctx.strokeStyle = selected
				? wpd.Colors.active
				: this.editing && this.isRow
				? wpd.Colors.headerActiveA
				: wpd.Colors.headerActiveDarkA;

			const moveToArgs = this.swap
				? [this.areaMin.x + buffer, pos]
				: [pos, this.areaMin.y + buffer];

			const lineToArgs = this.swap
				? [this.areaMax.x - buffer, pos]
				: [pos, this.areaMax.y - buffer];

			ctx.moveTo(...this.toScreenPixels(moveToArgs));
			ctx.lineTo(...this.toScreenPixels(lineToArgs));
			ctx.stroke();
		}

		drawTableDivisions(ctx) {
			const buffer = ctx.lineWidth / 2;

			if (this.rows?.dividers.length > 0) {
				// draw unselected rows
				for (let i = 0; i < this.rows.dividers.length; i++) {
					if (i !== this.selectedDividers?.row) {
						this.drawRowDivision(ctx, this.rows.dividers[i], false);
					}
				}

				// show "add row" preview
				if (this.addPreview && !_.isNil(this.selectedDividers?.row)) {
					const ind = this.selectedDividers.row;
					const selectedY = this.rows.dividers[ind];
					const previewY = this.selectedDividers?.previewRow;
					this.drawRowDivision(ctx, previewY, true);

					const rowRectangleArgs = this.getRowRectangleArgs(
						this.swap
							? this.areaMax.y - this.areaMin.y
							: this.areaMax.x - this.areaMin.x,
						Math.abs(previewY - selectedY)
					);

					ctx.fillStyle = wpd.Colors.activeA;
					ctx.fillRect(...this.toScreenPixels(rowRectangleArgs));
				}

				// show "remove row" preview
				if (
					this.removePreview &&
					!_.isNil(this.selectedDividers?.row)
				) {
					const ind = this.selectedDividers?.row;
					const nextInd = this.selectedDividers?.nextRow;
					const selectedY = this.rows.dividers[ind];
					const nextY = this.rows.dividers[nextInd];

					this.drawRowDivision(ctx, nextY, true);

					const rowRectangleArgs = this.getRowRectangleArgs(
						this.swap
							? this.areaMax.y - this.areaMin.y
							: this.areaMax.x - this.areaMin.x,
						Math.abs(nextY - selectedY),
						true
					);

					ctx.beginPath();
					ctx.fillStyle = wpd.Colors.activeA;
					ctx.fillRect(...this.toScreenPixels(rowRectangleArgs));
				}
			}

			if (this.cols?.dividers.length > 0) {
				// draw unselected cols
				for (let i = 0; i < this.cols.dividers.length; i++) {
					if (i !== this.selectedDividers?.col) {
						this.drawColDivision(ctx, this.cols.dividers[i], false);
					}
				}

				// show "add column" preview
				if (this.addPreview && !_.isNil(this.selectedDividers?.col)) {
					const ind = this.selectedDividers?.col;
					const selectedX = this.cols.dividers[ind];
					const previewX = this.selectedDividers?.previewCol;

					this.drawColDivision(ctx, previewX, true);

					ctx.fillStyle = wpd.Colors.activeA;
					const colRectangleArgs = this.getColRectangleArgs(
						this.swap
							? this.areaMax.x - this.areaMin.x
							: this.areaMax.y - this.areaMin.y,
						Math.abs(previewX - selectedX)
					);

					ctx.fillRect(...this.toScreenPixels(colRectangleArgs));
				}

				// show "remove col" preview
				if (
					this.removePreview &&
					!_.isNil(this.selectedDividers?.col)
				) {
					const ind = this.selectedDividers?.col;
					const nextInd = this.selectedDividers?.nextCol;
					const selectedX = this.cols.dividers[ind];
					const nextX = this.cols.dividers[nextInd];

					this.drawColDivision(ctx, nextX, true);

					const colRectangleArgs = this.getColRectangleArgs(
						this.swap
							? this.areaMax.x - this.areaMin.x
							: this.areaMax.y - this.areaMin.y,
						Math.abs(nextX - selectedX),
						true
					);

					ctx.beginPath();
					ctx.fillStyle = wpd.Colors.activeA;
					ctx.fillRect(...this.toScreenPixels(colRectangleArgs));
				}
			}

			// draw selected row and column last so they are at the front
			if (!_.isNil(this.selectedDividers?.row)) {
				this.drawRowDivision(
					ctx,
					this.rows.dividers[this.selectedDividers.row],
					true
				);
			}
			if (!_.isNil(this.selectedDividers?.col)) {
				this.drawColDivision(
					ctx,
					this.cols.dividers[this.selectedDividers.col],
					true
				);
			}
		}

		drawActiveCellOutline(ctx) {
			if (
				this.activeCell?.col != null &&
				this.activeCell?.col > -1 &&
				this.activeCell?.row != null &&
				this.activeCell?.row > -1
			) {
				const args = this.swap
					? [
							this.getRowStart(this.activeCell.row),
							this.getColStart(this.activeCell.col),
							this.getCellWidth(this.activeCell.row),
							this.getCellHeight(this.activeCell.col),
					  ]
					: [
							this.getColStart(this.activeCell.col),
							this.getRowStart(this.activeCell.row),
							this.getCellWidth(this.activeCell.col),
							this.getCellHeight(this.activeCell.row),
					  ];

				// translate to screen pixels
				const screenArgs = this.toScreenPixels(args);

				// draw the rectangle
				ctx.lineWidth = 6;
				ctx.strokeStyle = wpd.Colors.active;
				ctx.strokeRect(...screenArgs);
			}
		}

		getHeaderGradientArgs(type, headerWidth, headerHeight) {
			// define gradient line with 2 points:
			//  [x0, y0, x1, y1]
			const args = {
				top: [0, this.areaMin.y, 0, this.areaMin.y - headerHeight],
				right: [this.areaMax.x, 0, this.areaMax.x + headerWidth, 0],
				bottom: [0, this.areaMax.y, 0, this.areaMax.y + headerHeight],
				left: [this.areaMin.x, 0, this.areaMin.x - headerWidth, 0],
			};

			return args[wpd.TableRepainter.headerLocation[type][this.rotation]];
		}

		getRowDividerArgs(isRemove) {
			const ind = this.selectedDividers?.nextRow;
			const buffer = this.ctx.lineWidth / 2;
			const previewRow = isRemove
				? this.rows.dividers[ind]
				: this.selectedDividers?.previewRow;
			const args = {
				top: {
					start: [previewRow, this.areaMin.y + buffer],
					end: [previewRow, this.areaMax.y - buffer],
				},
				right: {
					start: [this.areaMin.x + buffer, previewRow],
					end: [this.areaMax.x - buffer, previewRow],
				},
				bottom: {
					start: [previewRow, this.areaMin.y + buffer],
					end: [previewRow, this.areaMax.y - buffer],
				},
				left: {
					start: [this.areaMin.x + buffer, previewRow],
					end: [this.areaMax.x - buffer, previewRow],
				},
			};

			return args[wpd.TableRepainter.addLocation["rows"][this.rotation]];
		}

		getColDividerArgs(isRemove) {
			const ind = this.selectedDividers?.nextCol;
			const buffer = this.ctx.lineWidth / 2;
			const previewCol = isRemove
				? this.cols.dividers[ind]
				: this.selectedDividers?.previewCol;
			const args = {
				top: {
					start: [previewCol, this.areaMin.y + buffer],
					end: [previewCol, this.areaMax.y - buffer],
				},
				right: {
					start: [this.areaMin.x + buffer, previewCol],
					end: [this.areaMax.x - buffer, previewCol],
				},
				bottom: {
					start: [previewCol, this.areaMin.y + buffer],
					end: [previewCol, this.areaMax.y - buffer],
				},
				left: {
					start: [this.areaMin.x + buffer, previewCol],
					end: [this.areaMax.x - buffer, previewCol],
				},
			};

			return args[wpd.TableRepainter.addLocation["cols"][this.rotation]];
		}

		getRowRectangleArgs(rowWidth, rowHeight, isRemove) {
			const ind = this.selectedDividers?.row;
			const nextInd = this.selectedDividers?.nextRow;
			const previewRow = isRemove
				? this.rows.dividers[nextInd]
				: this.selectedDividers?.previewRow;
			const args = {
				top: [
					Math.min(previewRow, this.rows.dividers[ind]),
					this.areaMax.y,
					rowHeight,
					-rowWidth,
				],
				right: [
					this.areaMin.x,
					Math.min(previewRow, this.rows.dividers[ind]),
					rowWidth,
					rowHeight,
				],
				bottom: [
					Math.max(previewRow, this.rows.dividers[ind]),
					this.areaMin.y,
					-rowHeight,
					rowWidth,
				],
				left: [
					this.areaMax.x,
					Math.max(previewRow, this.rows.dividers[ind]),
					-rowWidth,
					-rowHeight,
				],
			};

			return args[wpd.TableRepainter.addLocation["rows"][this.rotation]];
		}

		getColRectangleArgs(colWidth, colHeight, isRemove) {
			const ind = this.selectedDividers?.col;
			const nextInd = this.selectedDividers?.nextCol;
			const previewCol = isRemove
				? this.cols.dividers[nextInd]
				: this.selectedDividers?.previewCol;
			const args = {
				top: [
					Math.max(previewCol, this.cols.dividers[ind]),
					this.areaMin.y,
					-colHeight,
					colWidth,
				],
				right: [
					this.areaMax.x,
					Math.max(previewCol, this.cols.dividers[ind]),
					-colWidth,
					-colHeight,
				],
				bottom: [
					Math.min(previewCol, this.cols.dividers[ind]),
					this.areaMax.y,
					colHeight,
					-colWidth,
				],
				left: [
					this.areaMin.x,
					Math.min(previewCol, this.cols.dividers[ind]),
					colWidth,
					colHeight,
				],
			};

			return args[wpd.TableRepainter.addLocation["cols"][this.rotation]];
		}

		getRowHeaderRectangleArgs(index, headerWidth, headerHeight) {
			// define rectangle with a point, width, and height
			//  [x, y, width, height]
			const args = {
				top: [
					index === 0
						? this.areaMax.x
						: this.rows.dividers[index - 1],
					this.areaMin.y - headerHeight,
					this.getCellWidth(index) + 1,
					headerHeight,
				],
				right: [
					this.areaMax.x + headerWidth,
					index === 0
						? this.areaMax.y
						: this.rows.dividers[index - 1],
					-headerWidth,
					this.getCellHeight(index) + 1,
				],
				bottom: [
					index === 0
						? this.areaMin.x
						: this.rows.dividers[index - 1],
					this.areaMax.y + headerHeight,
					this.getCellWidth(index) - 1,
					-headerHeight,
				],
				left: [
					this.areaMin.x - headerWidth,
					index === 0
						? this.areaMin.y
						: this.rows.dividers[index - 1],
					headerWidth,
					this.getCellHeight(index) - 1,
				],
			};

			return args[
				wpd.TableRepainter.headerLocation["rows"][this.rotation]
			];
		}

		getColHeaderRectangleArgs(index, headerWidth, headerHeight) {
			// define rectangle with a point, width, and height
			//  [x, y, width, height]
			const args = {
				top: [
					index === 0
						? this.areaMin.x
						: this.cols.dividers[index - 1],
					this.areaMin.y - headerHeight,
					this.getCellWidth(index) - 1,
					headerHeight,
				],
				right: [
					this.areaMax.x + headerWidth,
					index === 0
						? this.areaMin.y
						: this.cols.dividers[index - 1],
					-headerWidth,
					this.getCellHeight(index) - 1,
				],
				bottom: [
					index === 0
						? this.areaMax.x
						: this.cols.dividers[index - 1],
					this.areaMax.y + headerHeight,
					this.getCellWidth(index) + 1,
					-headerHeight,
				],
				left: [
					this.areaMin.x - headerWidth,
					index === 0
						? this.areaMax.y
						: this.cols.dividers[index - 1],
					headerWidth,
					this.getCellHeight(index) + 1,
				],
			};

			return args[
				wpd.TableRepainter.headerLocation["cols"][this.rotation]
			];
		}

		drawHeaderGradients(ctx) {
			// width/height of gradients are calculated based on width/height of cells multipled by a factor
			const headerWidth =
				this.getAverageCellWidth() *
				wpd.TableRepainter.rowHeaderGradientFactor;
			const headerHeight =
				this.getAverageCellHeight() *
				wpd.TableRepainter.colHeaderGradientFactor;

			if (!this.rows?.headerless && this.rows?.headers.length) {
				// draw header gradients on calibrated rotation top-left
				const gradientArgs = this.getHeaderGradientArgs(
					"rows",
					headerWidth,
					headerHeight
				);

				// define gradient start and end points
				const screenGradientArgs = this.toScreenPixels(gradientArgs);

				// create gradient
				const gradientGood = ctx.createLinearGradient(
					...screenGradientArgs
				);
				const gradientBad = ctx.createLinearGradient(
					...screenGradientArgs
				);
				const gradientGoodHover = ctx.createLinearGradient(
					...screenGradientArgs
				);
				const gradientBadHover = ctx.createLinearGradient(
					...screenGradientArgs
				);

				// define color stops
				wpd.Colors.addColorStop("good", gradientGood);
				wpd.Colors.addColorStop("bad", gradientBad);
				wpd.Colors.addColorStop("goodHover", gradientGoodHover);
				wpd.Colors.addColorStop("badHover", gradientBadHover);

				// draw headers
				for (let i = 0; i < this.rows.headers.length; i++) {
					if (this.rows.headers[i]) {
						// header defined, good
						if (this.hover?.header?.rows === i) {
							ctx.fillStyle = gradientGoodHover;
						} else {
							ctx.fillStyle = gradientGood;
						}
					} else {
						// header not defined, bad
						if (this.hover?.header?.rows === i) {
							ctx.fillStyle = gradientBadHover;
						} else {
							ctx.fillStyle = gradientBad;
						}
					}

					const rowRectangleArgs = this.getRowHeaderRectangleArgs(
						i,
						headerWidth,
						headerHeight
					);

					ctx.fillRect(...this.toScreenPixels(rowRectangleArgs));
				}
			}

			if (!this.cols?.headerless && this.cols?.headers.length) {
				const gradientArgs = this.getHeaderGradientArgs(
					"cols",
					headerWidth,
					headerHeight
				);

				// define gradient start and end points
				const screenGradientArgs = this.toScreenPixels(gradientArgs);

				// create gradient
				const gradientGood = ctx.createLinearGradient(
					...screenGradientArgs
				);
				const gradientBad = ctx.createLinearGradient(
					...screenGradientArgs
				);
				const gradientGoodHover = ctx.createLinearGradient(
					...screenGradientArgs
				);
				const gradientBadHover = ctx.createLinearGradient(
					...screenGradientArgs
				);

				// define color stops
				wpd.Colors.addColorStop("good", gradientGood);
				wpd.Colors.addColorStop("bad", gradientBad);
				wpd.Colors.addColorStop("goodHover", gradientGoodHover);
				wpd.Colors.addColorStop("badHover", gradientBadHover);

				// draw headers
				for (let i = 0; i < this.cols.headers.length; i++) {
					if (this.cols.headers[i]) {
						// header defined, good
						if (this.hover?.header?.cols === i) {
							ctx.fillStyle = gradientGoodHover;
						} else {
							ctx.fillStyle = gradientGood;
						}
					} else {
						// header not defined, bad
						if (this.hover?.header?.cols === i) {
							ctx.fillStyle = gradientBadHover;
						} else {
							ctx.fillStyle = gradientBad;
						}
					}

					const colRectangleArgs = this.getColHeaderRectangleArgs(
						i,
						headerWidth,
						headerHeight
					);

					ctx.fillRect(...this.toScreenPixels(colRectangleArgs));
				}
			}
		}

		fillCells(ctx) {
			if (this.activeCell?.col != null && this.colData) {
				// width/height of gradients are calculated based on width/height of cells multipled by a factor
				const headerWidth =
					this.getAverageCellWidth() *
					wpd.TableRepainter.rowHeaderGradientFactor;
				const headerHeight =
					this.getAverageCellHeight() *
					wpd.TableRepainter.colHeaderGradientFactor;

				// create gradients for active row/col headers
				const rowGradientArgs = this.getHeaderGradientArgs(
					"rows",
					headerWidth,
					headerHeight
				);
				const colGradientArgs = this.getHeaderGradientArgs(
					"cols",
					headerWidth,
					headerHeight
				);

				// create gradient
				const rowGradient = ctx.createLinearGradient(
					...this.toScreenPixels(rowGradientArgs)
				);
				const colGradient = ctx.createLinearGradient(
					...this.toScreenPixels(colGradientArgs)
				);

				// define gradient color stops
				wpd.Colors.addColorStop("active", rowGradient);
				wpd.Colors.addColorStop("active", colGradient);

				// create draw functions
				const drawRowGradient = (index) => {
					const headerArgs = this.getRowHeaderRectangleArgs(
						index,
						headerWidth,
						headerHeight
					);
					ctx.fillStyle = rowGradient;
					ctx.fillRect(...this.toScreenPixels(headerArgs));
				};
				const drawColGradient = (index) => {
					const headerArgs = this.getColHeaderRectangleArgs(
						index,
						headerWidth,
						headerHeight
					);
					ctx.fillStyle = colGradient;
					ctx.fillRect(...this.toScreenPixels(headerArgs));
				};

				const yAxis = this.swap ? "x" : "y";

				// process all columns
				for (let i = 0; i < this.colData.length; i++) {
					const x = this.getColStart(i);

					// fill in each cell in the column
					for (let j = 0; j < this.colData[i].length; j++) {
						// define the cell rectangle
						const y =
							j === 0
								? this.topLeft[yAxis] === "-"
									? this.areaMin[yAxis]
									: this.areaMax[yAxis]
								: this.rows.dividers[j - 1];

						// cell has defined headers, assume data should be collected
						if (
							(this.cols.headers[i] && this.rows.headers[j]) ||
							(this.rows.headerless && this.cols.headers[i]) ||
							(this.cols.headerless && this.rows.headers[j])
						) {
							// cell has data and data has values other than uuid and rowIndex
							if (
								this.colData[i][j] &&
								Object.keys(this.colData[i][j].metadata)
									.length > 2
							) {
								if (
									this.hover?.cell.cols === i &&
									this.hover?.cell.rows === j
								) {
									// fill header gradients
									if (!this.rows.headerless) {
										drawRowGradient(j);
									}
									if (!this.cols.headerless) {
										drawColGradient(i);
									}

									ctx.fillStyle = wpd.Colors.goodHoverA;
								} else {
									ctx.fillStyle = wpd.Colors.goodA;
								}
							} else {
								// no data
								if (
									this.hover?.cell.cols === i &&
									this.hover?.cell.rows === j
								) {
									// fill header gradients
									if (!this.rows.headerless) {
										drawRowGradient(j);
									}
									if (!this.cols.headerless) {
										drawColGradient(i);
									}

									// fill hover colors
									if (this.colData[i][j] === undefined) {
										ctx.fillStyle = wpd.Colors.nullHoverA;
									} else {
										ctx.fillStyle = wpd.Colors.badHoverA;
									}
								} else {
									if (this.colData[i][j] === undefined) {
										ctx.fillStyle = wpd.Colors.nullA;
									} else {
										ctx.fillStyle = wpd.Colors.badA;
									}
								}
							}
						} else {
							// otherwise, data not required
							ctx.fillStyle = wpd.Colors.nullA;
						}

						let cellTopLeft = [x, y];
						let rowIndex = j;
						let colIndex = i;
						if (this.swap) {
							cellTopLeft = [y, x];
							rowIndex = i;
							colIndex = j;
						}

						const args = [
							...cellTopLeft,
							this.getCellWidth(colIndex),
							this.getCellHeight(rowIndex),
						];

						ctx.fillRect(...this.toScreenPixels(args));
					}
				}
			}
		}

		onAttach() {
			wpd.graphicsWidget.resetData();
		}

		onRedraw() {
			this.drawTableArea(this.ctx);
			this.drawTableDivisions(this.ctx);
			this.drawTableResizeHighlight(this.ctx);
			if (this.drawHeaders) {
				this.drawHeaderGradients(this.ctx);
			}
			this.drawActiveCellOutline(this.ctx);
			this.fillCells(this.ctx);
		}

		onForcedRedraw() {
			wpd.graphicsWidget.resetData();
		}

		drawPoints() {
			this.onRedraw();
		}
	};
}

export { apply };
