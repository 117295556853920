/**
 * Initializes WPD Image Manager.
 */
function init(wpd, wpdWindow, wpdDocument, dataEntryVM) {
	if (dataEntryVM.fitButtonsUpdateFlag) {
		class ImageManager {
			#firstLoad = true;
			#newLoad = false;
			#imageInfo = {
				width: 0,
				height: 0,
			};

			saveImage() {
				wpd.graphicsWidget.saveImage();
			}

			load() {
				let $input = wpdDocument.getElementById("fileLoadBox");
				if ($input.files.length > 0) {
					this.#newLoad = true;
					this.initializeFileManager($input.files);
					wpd.appData.setPageManager(null);
					this.loadFromFile($input.files[0]);
				}
				wpd.popup.close("loadNewImage");
			}

			#fileToDataUrl(file) {
				return new Promise((resolve, reject) => {
					const reader = new FileReader();
					reader.onload = () => {
						resolve(reader.result);
					};
					reader.onerror = reject;
					reader.readAsDataURL(file);
				});
			}

			async #loadImageFile(file, resumedProject) {
				const url = await this.#fileToDataUrl(file);

				await this.loadFromURL(url, resumedProject);
			}

			async #loadPdfFile(file) {
				const url = await this.#fileToDataUrl(file);

				const pdf = await wpdWindow.pdfjsLib.getDocument(url).promise;

				const existingPageManager = wpd.appData.getPageManager();
				if (existingPageManager) {
					await existingPageManager.renderPage(
						existingPageManager.currentPage()
					);
				} else {
					const pdfManager = this.initializePDFManager(pdf);
					await pdfManager.renderPage(1);
					wpd.appData.setPageManager(pdfManager);
				}
			}

			loadFromFile(file, resumedProject) {
				return new Promise(async (resolve) => {
					wpd.busyNote.show();

					const zoom100Button = wpdDocument.querySelector(
						"button[title='View actual size']"
					);

					if (file.type.match("image.*")) {
						await this.#loadImageFile(file, resumedProject);
						resolve();
					} else if (file.type == "application/pdf") {
						await this.#loadPdfFile(file);
						resolve();
					} else {
						// do not reject the promise to avoid preventing the
						// next file to load
						console.error(`Invalid file type: ${file.type}`);
						wpd.busyNote.close();
						wpd.messagePopup.show(
							wpd.gettext("invalid-file"),
							wpd.gettext("invalid-file-text")
						);
					}
				});
			}

			loadFromURL(url, resumedProject) {
				return new Promise((resolve) => {
					let image = new Image();
					image.onload = () => {
						this.#setImage(image, resumedProject);
						resolve();
					};
					image.src = url;
				});
			}

			#setImage(image, resumedProject) {
				if (this.#newLoad) {
					wpd.appData.reset();
					wpd.sidebar.clear();
				}
				const pageManager = wpd.appData.getPageManager();
				if (pageManager) {
					wpd.graphicsWidget.setRotation(pageManager.getRotation());
				}
				let imageData = wpd.graphicsWidget.loadImage(image);
				wpd.appData.plotLoaded(imageData);
				wpd.busyNote.close();
				if (this.#newLoad) {
					wpd.tree.refresh();
				} else {
					wpd.tree.refreshPreservingSelection();
				}
				if (this.#firstLoad) {
					wpd.sidebar.show("start-sidebar");
				} else if (!resumedProject) {
					wpd.popup.show("axesList");
				}
				this.#firstLoad = false;
				this.#newLoad = false;
				this.#imageInfo = {
					width: imageData.width,
					height: imageData.height,
				};
			}

			getImageInfo() {
				return this.#imageInfo;
			}

			initializeFileManager(files, startIndex) {
				const fileManager = wpd.appData.getFileManager();
				fileManager.reset();
				// prevent fileManager from loading unsupported file
				fileManager.currentIndex = startIndex;
				fileManager.set(files);
			}

			initializePDFManager(pdf, skipInputRefresh) {
				const pdfManager = new wpd.PDFManager();
				pdfManager.init(pdf, skipInputRefresh);
				return pdfManager;
			}
		}

		wpd.imageManager = new ImageManager();
	} else {
		// prevent fileManager from loading unsupported file
		wpd.imageManager.initializeFileManager = function (files, startIndex) {
			const fileManager = wpd.appData.getFileManager();
			fileManager.reset();
			fileManager.currentIndex = startIndex;
			fileManager.set(files);
		};
	}
}

export { init };
