import colors from "vuetify/util/colors";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import "vuetify/styles";

// Supports weights 300-700
import "@fontsource-variable/fira-code";

const blue = "#347b99";
const orange = "#f09d37";
const darkOrange = "#f58634";
const turquoise = "#39c9bb";
const white = "#FAFAFA";
const trueWhite = "#ffffff";
const grey = "#f5f5f5";
export default createVuetify({
	components,
	defaults: {
		VTextField: {
			variant: "underlined",
			color: "secondary",
		},
		VCombobox: {
			bgColor: trueWhite,
			color: "secondary",
		},
		VAutocomplete: {
			bgColor: trueWhite,
			color: "secondary",
		},
		VSelect: {
			bgColor: trueWhite,
			color: "secondary",
		},
	},
	directives,
	theme: {
		defaultTheme: "light",
		themes: {
			light: {
				colors: {
					primary: blue,
					secondary: orange,
					secondaryDark: darkOrange,
					accent: turquoise,
					white: white,
					grey: grey,
					info: colors.amber.darken2,
				},
			},
		},
	},
});
