import * as ManualSelectionTool from "./tools/manual-selection.js";
import * as AdjustDataPointTool from "./tools/adjust-data-point.js";
import * as DeleteDataPointTool from "./tools/delete-data-point.js";
import * as BarCornersTool from "./tools/bar-corners.js";
import * as BarHeadersTool from "./tools/bar-headers.js";
import * as SelectTextTool from "./tools/select-text.js";
import * as XYGraphPointTool from "./tools/xy-graph-points.js";

/**
 * Initializes existing tools in WPD for superloading/overriding.
 */
function init(wpd, wpdDocument, wpdWindow, dataEntryVM) {
	ManualSelectionTool.init(wpd, wpdDocument, dataEntryVM);
	AdjustDataPointTool.init(wpd, wpdDocument);
	DeleteDataPointTool.init(wpd, wpdDocument, dataEntryVM);
	BarCornersTool.init(wpd);
	BarHeadersTool.init(wpd, wpdDocument, wpdWindow, dataEntryVM);
	SelectTextTool.init(wpd, wpdDocument);

	if (dataEntryVM.xyGraphEditEnabled) {
		XYGraphPointTool.init(wpd);
	}
}

export { init };
