function init(wpd) {
	wpd.XYGraphPointTool = class {
		constructor(axes) {
			this.axes = axes;

			this.drawPoints();
		}

		onRedraw() {
			this.drawPoints();
		}

		drawPoints() {
			const drawPoint = (point, label = "") => {
				if (point) {
					const color = point.isSelected
						? wpd.Colors.active
						: wpd.Colors.good;
					wpd.graphicsHelper.drawPoint(
						{ x: point.px, y: point.py },
						color,
						label
					);
				}
			};

			drawPoint(this.axes.calibration.getPoint(0));
			drawPoint(this.axes.calibration.getPoint(1));
			drawPoint(this.axes.calibration.getPoint(2));
			drawPoint(this.axes.calibration.getPoint(3));
		}
	};
}

export { init };
