import * as Elements from "./elements.js";

function init(wpd, wpdDocument, dataEntryVM) {
	wpd.xyGraphEdit = {};

	let calibrator = null;

	const elementIds = {
		editXYGraphSidebar: "edit-xy-graph-sidebar",
		editXYGraphAdjustPointsSidebar: "edit-xy-graph-adjust-points-sidebar",
	};

	Elements.apply(wpd, wpdDocument, elementIds);

	const showAxisPoints = function () {
		const axis = wpd.tree.getActiveAxes();
		const calibration = axis.calibration;
		calibration.unselectAll();
		calibrator = new wpd.XYAxesCalibrator(calibration, true);
		calibrator.reload();

		wpd.graphicsWidget.setRepainter(
			new wpd.AlignmentCornersRepainter(calibration)
		);

		wpd.graphicsWidget.forceHandlerRepaint();
	};

	const hideAxisPoints = function () {
		const axis = wpd.tree.getActiveAxes();
		const calibration = axis.calibration;
		calibration.unselectAll();

		wpd.graphicsWidget.removeTool();
		wpd.graphicsWidget.removeRepainter();
		wpd.graphicsWidget.resetData();
		wpd.graphicsWidget.forceHandlerRepaint();
	};

	// Populate the values in the axis point values popup
	const setAxisPointFieldValues = function () {
		let axis = wpd.tree.getActiveAxes();
		let calibration = axis.calibration;
		if (calibration.getCount() == 4) {
			wpdDocument.getElementById("xy-edit-xmin").value =
				calibration.getPoint(0).dx;
			wpdDocument.getElementById("xy-edit-xmax").value =
				calibration.getPoint(1).dx;
			wpdDocument.getElementById("xy-edit-ymin").value =
				calibration.getPoint(2).dy;
			wpdDocument.getElementById("xy-edit-ymax").value =
				calibration.getPoint(3).dy;
			wpdDocument.getElementById("xy-edit-xlog").checked = axis.isLogX();
			wpdDocument.getElementById("xy-edit-ylog").checked = axis.isLogY();
			wpdDocument.getElementById("xy-edit-axes-no-rotation").checked =
				axis.noRotation();
		}
	};

	const showError = function (errorClass) {
		wpdDocument
			.getElementById("xy-graph-set-values-dialog")
			.querySelector(`.${errorClass}`)
			.removeAttribute("hidden");
	};

	const hideError = function (errorClass) {
		wpdDocument
			.getElementById("xy-graph-set-values-dialog")
			.querySelector(`.${errorClass}`)
			.setAttribute("hidden", "true");
	};

	// Save updates to axis point values
	const saveAxisPointValues = function () {
		hideError("xy-edit-log-validation-message");
		hideError("xy-edit-validation-message");

		const values = {
			xmin: wpdDocument.getElementById("xy-edit-xmin").value,
			xmax: wpdDocument.getElementById("xy-edit-xmax").value,
			ymin: wpdDocument.getElementById("xy-edit-ymin").value,
			ymax: wpdDocument.getElementById("xy-edit-ymax").value,
			xlog: wpdDocument.getElementById("xy-edit-xlog").checked,
			ylog: wpdDocument.getElementById("xy-edit-ylog").checked,
			noRotation: wpdDocument.getElementById("xy-edit-axes-no-rotation")
				.checked,
		};

		let axis = wpd.tree.getActiveAxes();
		let calibration = axis.calibration;

		if (
			(values.xlog &&
				(parseFloat(values.xmin) == 0 ||
					parseFloat(values.xmax) == 0)) ||
			(values.ylog &&
				(parseFloat(values.ymin) == 0 || parseFloat(values.ymax) == 0))
		) {
			showError("xy-edit-log-validation-message");
			return false;
		}

		calibration.setDataAt(0, values.xmin, values.ymin);
		calibration.setDataAt(1, values.xmax, values.ymin);
		calibration.setDataAt(2, values.xmin, values.ymin);
		calibration.setDataAt(3, values.xmax, values.ymax);
		const calibrateResult = axis.calibrate(
			calibration,
			values.xlog,
			values.ylog,
			values.noRotation
		);

		if (!calibrateResult) {
			showError("xy-edit-validation-message");
			return;
		}

		return values;
	};

	const updateGraphPointsWithAxisPointValues = function () {
		let axis = wpd.tree.getActiveAxes();
		const metadata = axis.getMetadata();
		const datasets = wpd.appData.getPlotData().getDatasetsForAxis(axis);

		datasets.forEach((ds) => {
			const pixels = ds.getAllPixels();
			pixels.forEach((px) => {
				const data = axis.pixelToData(px.x, px.y);
				Object.keys(px.metadata)
					.filter(
						(key) =>
							!isNaN(key) && !_.isNil(px.metadata[key].axisType)
					)
					.forEach((key) => {
						const dp = px.metadata[key];
						if (dp.axis === "x" && dp.axisType === "value") {
							dp.value = wpd.utils.toDecimalPlaces(
								data[0],
								metadata?.axesLabelData?.x?.decimals ?? 2
							);
						} else if (dp.axis === "y" && dp.axisType === "value") {
							dp.value = wpd.utils.toDecimalPlaces(
								data[1],
								metadata?.axesLabelData?.y?.decimals ?? 2
							);
						}
					});
			});
		});
	};

	const setAxisDataTypeFieldValues = function () {
		let axis = wpd.tree.getActiveAxes();
		const metadata = axis.getMetadata();
		const xData = metadata?.axesLabelData?.x;
		const yData = metadata?.axesLabelData?.y;
		if (!xData || !yData) {
			console.error("XY graph missing axis data type metadata");
			return;
		}

		wpdDocument.getElementById("x-axis-data-type").value = xData.dataType;
		wpdDocument.getElementById("y-axis-data-type").value = yData.dataType;
		wpdDocument.getElementById("x-axis-unit").value = xData.unit;
		wpdDocument.getElementById("y-axis-unit").value = yData.unit;
		wpdDocument.getElementById("x-axis-change-from-baseline").checked =
			xData.changeFromBaseline;
		wpdDocument.getElementById("y-axis-change-from-baseline").checked =
			yData.changeFromBaseline;
		wpdDocument.getElementById("x-axis-decimals").value = xData.decimals;
		wpdDocument.getElementById("y-axis-decimals").value = yData.decimals;

		wpd.alignAxes.initializeXyGraphFields();
	};

	const updateGraphPointsWithAxisDataTypes = function (dataTypes) {
		let axis = wpd.tree.getActiveAxes();
		const datasets = wpd.appData.getPlotData().getDatasetsForAxis(axis);
		const changeFromBaselineTag = _.find(wpd.tagGroups.tags, {
			name: "Change from baseline",
		});
		const valueUnitTag = wpd.tagGroups.findTag({
			name: "Unit",
		});
		const timeUnitTag = wpd.tagGroups.findTag({
			name: "Time (unit)",
		});

		datasets.forEach((ds) => {
			const pixels = ds.getAllPixels();
			pixels.forEach((px) => {
				let maxIndex = 0;

				let hasXChangeFromBaseline = false;
				let hasYChangeFromBaseline = false;
				Object.keys(px.metadata)
					.filter(
						(key) =>
							!isNaN(key) && !_.isNil(px.metadata[key].axisType)
					)
					.forEach((key) => {
						const dp = px.metadata[key];
						maxIndex = Math.max(maxIndex, parseInt(key, 10));

						if (dp.axis === "x" && dp.axisType === "unit") {
							dp.tag =
								dataTypes.xAxisDataType === "Time (number)"
									? timeUnitTag
									: valueUnitTag;

							dp.value = dataTypes.xAxisUnit;
						} else if (dp.axis === "x" && dp.axisType === "value") {
							dp.tag = _.pick(
								_.find(wpd.tagGroups.tags, {
									name: dataTypes.xAxisDataType,
								}),
								["name", "uuid"]
							);

							dp.value = wpd.utils.toDecimalPlaces(
								dp.value,
								dataTypes.xAxisDecimals
							);
						} else if (
							dp.axis === "x" &&
							dp.axisType === "changeFromBaseline"
						) {
							hasXChangeFromBaseline = true;
							dp.value = dataTypes.xAxisChangeFromBaseline
								? "Yes"
								: "No";
						} else if (dp.axis === "y" && dp.axisType === "unit") {
							dp.tag =
								dataTypes.yAxisDataType === "Time (number)"
									? timeUnitTag
									: valueUnitTag;
							dp.value = dataTypes.yAxisUnit;
						} else if (dp.axis === "y" && dp.axisType === "value") {
							dp.tag = _.pick(
								_.find(wpd.tagGroups.tags, {
									name: dataTypes.yAxisDataType,
								}),
								["name", "uuid"]
							);

							dp.value = wpd.utils.toDecimalPlaces(
								dp.value,
								dataTypes.yAxisDecimals
							);
						} else if (
							dp.axis === "y" &&
							dp.axisType === "changeFromBaseline"
						) {
							hasYChangeFromBaseline = true;
							dp.value = dataTypes.yAxisChangeFromBaseline
								? "Yes"
								: "No";
						}

						px.metadata[key] = dp;
					});

				if (
					dataTypes.xAxisChangeFromBaseline &&
					!hasXChangeFromBaseline
				) {
					px.metadata[++maxIndex] = {
						tag: changeFromBaselineTag,
						value: "Yes",
						axis: "x",
						axisType: "changeFromBaseline",
					};
				}

				if (
					dataTypes.yAxisChangeFromBaseline &&
					!hasYChangeFromBaseline
				) {
					px.metadata[++maxIndex] = {
						tag: changeFromBaselineTag,
						value: "Yes",
						axis: "y",
						axisType: "changeFromBaseline",
					};
				}
			});
		});
	};

	const setStudyBranchFieldValues = function () {
		let axis = wpd.tree.getActiveAxes();
		const datasets = wpd.appData.getPlotData().getDatasetsForAxis(axis);
		if (_.isEmpty(datasets)) {
			return;
		}

		for (let i = 0; i < datasets.length - 1; i++) {
			wpd.dataSeriesManagement.addRow();
		}
		const rowContainer = wpdDocument.getElementById(
			"add-study-branch-inputs"
		);

		const rows = Array.from(rowContainer.children);
		for (let i = 0; i < rows.length; i++) {
			const row = rows[i];
			const ds = datasets[i];
			const md = ds.getMetadata();
			const dsInput = row.querySelector(".add-dataset-study-arm-input");
			const tgInput = row.querySelector(".add-dataset-tag-group-input");
			dsInput.setAttribute(
				"data-composite-key",
				md.studyData.compositeKey
			);
			dsInput.value = wpd.CompositeKeyUtils.getCompositeKeyValue(
				md.studyData.compositeKey
			);
			tgInput.value = md.studyData.tagGroup.name;
		}
	};

	wpd.xyGraphEdit.zoomCalibrationPoint = function (index) {
		const axis = wpd.tree.getActiveAxes();
		const calibration = axis.calibration;
		var point = calibration.getPoint(index);
		wpd.graphicsWidget.updateZoomToImagePosn(point.px, point.py);
	};

	// Adjusting axis point positions
	wpd.xyGraphEdit.adjustAxisPoints = function () {
		showAxisPoints();
		wpd.sidebar.show("edit-xy-graph-adjust-points-sidebar");
	};

	wpd.xyGraphEdit.completeAdjustAxisPoints = function () {
		const axis = wpd.tree.getActiveAxes();
		axis.calibrate(
			axis.calibration,
			axis.isLogX(),
			axis.isLogY(),
			axis.noRotation()
		);
		updateGraphPointsWithAxisPointValues();
		hideAxisPoints();
		wpd.graphicsWidget.setTool(
			new wpd.XYGraphPointTool(wpd.tree.getActiveAxes())
		);
		wpd.sidebar.clear();
		wpd.sidebar.show("edit-xy-graph-sidebar");
	};

	// Setting axis point values
	wpd.xyGraphEdit.openSetAxisPointValuesDialog = function () {
		setAxisPointFieldValues();
		wpd.popup.show("xy-graph-set-values-dialog");
	};

	wpd.xyGraphEdit.closeSetAxisPointValuesDialog = function () {
		wpd.popup.close("xy-graph-set-values-dialog");
	};

	wpd.xyGraphEdit.confirmSetAxisPointValues = function () {
		const saveResult = saveAxisPointValues();

		if (!saveResult) return;

		updateGraphPointsWithAxisPointValues();
		wpd.popup.close("xy-graph-set-values-dialog");
	};

	// Setting axis data types
	wpd.xyGraphEdit.openSetDataTypesDialog = function () {
		setAxisDataTypeFieldValues();

		wpdDocument
			.getElementById("xy-edit-graph-axis-buttons")
			.removeAttribute("hidden");
		wpdDocument
			.getElementById("xy-graph-axis-buttons")
			.setAttribute("hidden", "true");

		wpd.popup.show("graph-axes-label-popup");
	};

	wpd.xyGraphEdit.closeSetDataTypesDialog = function () {
		wpd.popup.close("graph-axes-label-popup");
		wpd.alignAxes.clearAxesLabelValues();
		wpd.alignAxes.destroyAwesompletes();
	};

	wpd.xyGraphEdit.confirmSetDataTypes = function () {
		const saveResult = wpd.alignAxes.saveAxesData(false);
		if (!saveResult) return;

		updateGraphPointsWithAxisDataTypes(saveResult);
		updateGraphPointsWithAxisPointValues();

		wpd.popup.close("graph-axes-label-popup");
	};

	// Setting study branches
	wpd.xyGraphEdit.openSetStudyBranchesDialog = function () {
		wpd.dataSeriesManagement.showAddDataset(true);
		setStudyBranchFieldValues();
	};

	wpd.xyGraphEdit.closeSetStudyBranchesDialog = function () {
		wpd.dataSeriesManagement.resetDialog();
		wpd.popup.close("add-dataset-popup");
	};

	wpd.xyGraphEdit.confirmSetStudyBranches = function () {
		const rowContainer = wpdDocument.getElementById(
			"add-study-branch-inputs"
		);

		const rows = Array.from(rowContainer.children);
		const studyBranchKeys = rows
			.map((row) => row.querySelector(".add-dataset-study-arm-input"))
			.filter((input) => input.value !== "")
			.map((input) => input.getAttribute("data-composite-key"));
		const tagGroupNames = rows
			.map((row) => row.querySelector(".add-dataset-tag-group-input"))
			.filter((input) => input.value !== "")
			.map((input) => input.value);

		let axis = wpd.tree.getActiveAxes();
		const datasets = wpd.appData.getPlotData().getDatasetsForAxis(axis);
		let existingKeys = new Set();

		// if composite key is not in UI list, delete dataset
		// if composite key is in UI list, check tag group
		datasets.forEach((ds) => {
			const md = ds.getMetadata();
			existingKeys.add(md.studyData.compositeKey);
			if (!studyBranchKeys.includes(md.studyData.compositeKey)) {
				wpd.appData.getPlotData().deleteDataset(ds);
				wpd.tree.refreshPreservingSelection();
			} else {
				const index = studyBranchKeys.findIndex(
					(key) => key === md.studyData.compositeKey
				);
				md.studyData.tagGroup.name = tagGroupNames[index];
			}
		});

		for (let i = 0; i < studyBranchKeys.length; i++) {
			// if composite key is not in the MD list, add it
			if (!existingKeys.has(studyBranchKeys[i])) {
				wpd.dataSeriesManagement.saveDataset(false, rows[i]);
			}
		}

		wpd.tree.editAxis(axis.name);
		wpd.popup.close("add-dataset-popup");
		wpd.dataSeriesManagement.resetDialog(false);
	};

	wpd.xyGraphEdit.closeXYGraphEdit = function () {
		hideAxisPoints();
		wpd.sidebar.clear();
	};
}

export { init };
