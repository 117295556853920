import _ from "lodash";

import * as UserScript from "./user-script.js";

/**
 * Gets the pixel offsets for the WPD graphics container context, used to translate graphics container to main window
 * coordinates.
 * @return {Object} - Object containing top, right, bottom, left keys, each containing pixel offsets
 */
function getBounds(wpdDocument) {
	// graphics container pixel boundaries in the context of main viewport
	const dataEntryRect = document
		.getElementById("data-entry")
		.getBoundingClientRect();
	const imageRect = wpdDocument
		.getElementById("graphicsContainer")
		.getBoundingClientRect();

	return {
		top: dataEntryRect.top + imageRect.top,
		right: dataEntryRect.left + imageRect.right,
		bottom: dataEntryRect.top + imageRect.bottom,
		left: dataEntryRect.left + imageRect.left,
	};
}

/**
 * Injects a user script into WPD. Contains primary interfacing functions between WPD and Dactyl.
 * @param {VueComponent} dataEntryVM - DataEntry Vue instance
 */
function inject(wpd, wpdDocument, dataEntryVM) {
	const wpdscript = UserScript.generate(wpd, wpdDocument, dataEntryVM);

	window["wpdscript"] = wpdscript;

	wpdscript.run();
}

/**
 * Loads image files into WPD.
 * @param  {Array[Files]} files - Array of files to load into WPD
 * @return {Promise}            - Promise resolving to undefined
 */
async function loadFiles(wpd, files) {
	// load the first supported file
	const index = _getFirstFileIndex(wpd, files);

	wpd.imageManager.initializeFileManager(files, index);
	wpd.appData.setPageManager(null);

	return wpd.imageManager.loadFromFile(files[index]);
}

/**
 * Loads WPD JSON into WPD.
 * @param {String} json - JSON string of saved WPD data
 */
async function loadJSON(wpd, dataEntryVM, json) {
	const plotData = wpd.appData.getPlotData();
	const metadata = plotData.deserialize(json);

	let data = {};
	if (metadata && Object.keys(metadata).length !== 0) {
		data = metadata;
	}

	const fileManager = wpd.appData.getFileManager();
	await fileManager.loadMetadata(data).then((hasSavedPageAndFile) => {
		if (!hasSavedPageAndFile) {
			// switch to the first supported file if necessary
			const index = _getFirstFileIndex(wpd, fileManager.getFiles());
			if (index !== 0) {
				fileManager._savePageManager();
				fileManager._loadPageManager(index);
				fileManager._saveUndoManager();
				fileManager._loadUndoManager(index);
				wpd.tree.refresh();
			}
		}

		// clear the loading overlay
		dataEntryVM.loading = false;
	});

	// update default color on each loaded data set to include the alpha value
	_.forEach(plotData.getDatasets(), (dataset) => {
		dataset.colorRGB = new wpd.Color(
			...wpd.Colors.getValues(wpd.Colors.goodA)
		);
	});

	// render studies tree
	wpd.tagGroups.refreshStudies();
}

/**
 * Serializes current WPD data to a JSON string.
 * @return {String} - JSON string of WPD data
 */
function saveJSON(wpd) {
	const plotData = wpd.appData.getPlotData();
	const metadata = wpd.appData.getFileManager().getMetadata();
	return JSON.stringify(plotData.serialize(metadata));
}

/**
 * Sets metadata on a given dataset.
 * Used for storing cached metadata when the dialog is open while autosaving is triggered.
 * @param {Object} payload - Payload object containing the container, indexes, and metadata
 */
function setMetadata(wpd, payload) {
	if (payload.container && payload.indexes) {
		if (payload.indexes.length === 1) {
			// set rowIndex for table axis data points
			if (payload.rowIndex != null) {
				payload.metadata.rowIndex = payload.rowIndex;
			}

			// single point selected
			wpd.custom.saveDataPoint(
				payload.container,
				payload.indexes[0],
				payload.metadata
			);
		} else {
			// multiple points selected
			wpd.custom.saveDataPoints(
				payload.container,
				payload.indexes,
				payload.metadata
			);
		}

		// sync metadata keys on data set
		wpd.custom.syncMetadataKeys(payload.container);

		// repaint UI
		wpd.custom.repaintDataPoints(payload.container);
	}
}

/**
 * Sets options object values.
 * @param {Object} options    - Options object defined in interfaces.js
 * @param {Object} newOptions - New values to add to or override in options object
 */
function setOptions(options, newOptions) {
	_.assign(options, newOptions);
}

/**
 * Gets current location information: file and page indexes
 */
function getCurrentLocationInfo(wpd) {
	return {
		file: wpd.appData.getFileManager().currentFileIndex(),
		page: wpd.appData.getPageManager().currentPage(),
	};
}

/**
 * Returns index the of first file that is of a supported type, otherwise 0.
 * @param {Object} wpd   - WebPlotDigitizer instance
 * @param {Array}  files - Files loaded into WPD
 */
function _getFirstFileIndex(wpd, files) {
	const index = files.findIndex(wpd.utils.isSupportedFile);

	return index < 0 ? 0 : index;
}

export {
	getBounds,
	getCurrentLocationInfo,
	inject,
	loadFiles,
	loadJSON,
	saveJSON,
	setMetadata,
	setOptions,
};
