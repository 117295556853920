/**
 * Initializes acquireData object in WPD.
 */
function init(wpd, dataEntryVM) {
	wpd.acquireData = (function () {
		let dataset, axes;

		function load() {
			dataset = getActiveDataset();
			axes = getAxes();

			if (axes == null) {
				wpd.messagePopup.show(
					wpd.gettext("dataset-no-calibration"),
					wpd.gettext("calibrate-dataset")
				);
			} else {
				wpd.graphicsWidget.removeTool();
				wpd.graphicsWidget.resetData();
				showSidebar();
				wpd.dataPointCounter.setCount();
				wpd.graphicsWidget.removeTool();
				wpd.graphicsWidget.setRepainter(
					new wpd.DataPointsRepainter(axes, dataset)
				);

				// do not attach the manual selection "Add Point (A)" by default

				wpd.graphicsWidget.forceHandlerRepaint();
				wpd.dataPointCounter.setCount(dataset.getCount());
			}
		}

		function getActiveDataset() {
			return wpd.tree.getActiveDataset();
		}

		function getAxes() {
			return wpd.appData
				.getPlotData()
				.getAxesForDataset(getActiveDataset());
		}

		function selectText(resolve, keydownHandler) {
			const tool = new wpd.TextSelectionTool(resolve, keydownHandler);
			tool.attach();
			return tool;
		}

		function manualSelection() {
			const tool = new wpd.ManualSelectionTool(axes, dataset);
			wpd.graphicsWidget.setTool(tool);
		}

		function deletePoint() {
			const tool = new wpd.DeleteDataPointTool(axes, dataset);
			wpd.graphicsWidget.setTool(tool);
		}

		function confirmedClearAll() {
			dataset.clearAll();
			wpd.pointGroups.hideControls();
			wpd.graphicsWidget.removeTool();
			wpd.graphicsWidget.resetData();
			wpd.dataPointCounter.setCount(dataset.getCount());
			wpd.graphicsWidget.removeRepainter();
		}

		function clearAll() {
			if (dataset.getCount() <= 0 && !dataset.hasPointGroups()) {
				return;
			}
			wpd.okCancelPopup.show(
				wpd.gettext("clear-data-points"),
				wpd.gettext("clear-data-points-text"),
				confirmedClearAll,
				function () {}
			);
		}

		function undo() {
			dataset.removeLastPixel();
			wpd.graphicsWidget.resetData();
			wpd.graphicsWidget.forceHandlerRepaint();
			wpd.dataPointCounter.setCount(dataset.getCount());
		}

		function showSidebar() {
			wpd.sidebar.show("acquireDataSidebar");
			wpd.dataPointCounter.setCount(dataset.getCount());
		}

		function adjustPoints() {
			wpd.graphicsWidget.setTool(
				new wpd.AdjustDataPointTool(axes, dataset)
			);
		}

		function editLabels() {
			// this should only trigger the tool if the axes type is bar
			if (axes instanceof wpd.BarAxes) {
				wpd.graphicsWidget.setTool(
					new wpd.EditLabelsTool(axes, dataset)
				);
			}
		}

		function switchToolOnKeyPress(alphaKey) {
			switch (alphaKey) {
				case "d":
					deletePoint();
					break;
				case "a":
					const axisType = wpd.custom.identifyAxis(axes);
					if (
						["xy", "bar"].includes(axisType) ||
						!dataEntryVM.xyGraphEditEnabled
					) {
						manualSelection();
					}
					break;
				case "s":
					adjustPoints();
					break;
				case "e":
					editLabels();
					break;
				default:
					break;
			}
		}

		function isToolSwitchKey(keyCode) {
			if (
				wpd.keyCodes.isAlphabet(keyCode, "a") ||
				wpd.keyCodes.isAlphabet(keyCode, "s") ||
				wpd.keyCodes.isAlphabet(keyCode, "d") ||
				wpd.keyCodes.isAlphabet(keyCode, "e")
			) {
				return true;
			}
			return false;
		}

		return {
			load: load,
			manualSelection: manualSelection,
			adjustPoints: adjustPoints,
			deletePoint: deletePoint,
			selectText: selectText,
			clearAll: clearAll,
			undo: undo,
			showSidebar: showSidebar,
			switchToolOnKeyPress: switchToolOnKeyPress,
			isToolSwitchKey: isToolSwitchKey,
			editLabels: editLabels,
		};
	})();
}

export { init };
