/**
 * Initializes WPD Utils module.
 */
function init(wpd) {
	wpd.utils.createOptionsHTML = function (
		labels,
		values,
		selectedValue,
		disabledIndexes = []
	) {
		if (labels.length !== values.length) {
			console.error("labels and values length mismatch");
		}

		let optionsHTML = "";
		for (let i = 0; i < labels.length; i++) {
			optionsHTML += '<option value="' + values[i] + '"';
			if (values[i] === selectedValue) optionsHTML += " selected";
			if (disabledIndexes.includes(values[i])) optionsHTML += " disabled";
			optionsHTML += ">" + labels[i] + "</option>";
		}
		return optionsHTML;
	};

	/**
	 * Checks if a given file is a supported file type.
	 * @param {File} file - File to check
	 * @returns {Boolean} - Whether or not given file is supported
	 */
	wpd.utils.isSupportedFile = (file) => {
		return [
			"application/pdf",
			"image/bmp",
			"image/gif",
			"image/jpeg",
			"image/png",
		].includes(file.type);
	};

	wpd.utils.escapeSlashes = (string) =>
		string ? `${string}`.replaceAll("/", "\\") : "";

	wpd.utils.unescapeSlashes = (string) =>
		string ? `${string}`?.replaceAll("\\", "/") : "";

	wpd.utils.oneLine = (string) => {
		return _.chain(string).replace(/\s+/g, " ").trim().value();
	};

	/**
	 * Check if provided key is a word. For use with _.omitBy.
	 * @param  {Any}     value - Unused variable
	 * @param  {Any}     key   - Parameter to check if NaN
	 * @return {Boolean}       - Whether or not key is word
	 */
	wpd.utils.isWord = (value, key) => isNaN(key);

	/**
	 * Given a number, returns a new number with the specified number of decimal
	 * places.
	 * @param {Number} number - Number to modify
	 * @param {Number} decimalPlaces - Number of decimal places
	 * @returns {Number}
	 */
	wpd.utils.toDecimalPlaces = (number, decimalPlaces) => {
		if (isNaN(number) || typeof number !== "number") {
			return NaN;
		}

		return parseFloat(number.toFixed(decimalPlaces));
	};

	/**
	 * Check if a given metadata value is "empty"; this needs to return
	 * true for the value 0, but false for any form of empty value.
	 * @param {Any} value - Value to check
	 */
	wpd.utils.isEmptyValue = (value) => {
		return value === null || value === undefined || value === "";
	};

	wpd.utils.findDetailsByTagGroupInstanceUuid = (uuid) => {
		const plotData = wpd.appData.getPlotData();
		const documentMetadata = wpd.appData.getFileManager().getMetadata();
		const datasets = plotData.getDatasets();

		for (const dataset of datasets) {
			const dataPoints = dataset.getAllPixels();
			const instanceDataPoints = dataPoints.filter(
				(dataPoint) =>
					dataPoint?.metadata?.tagGroup?.instanceUuid === uuid
			);

			if (instanceDataPoints.length > 0) {
				const axis = plotData.getAxesForDataset(dataset);

				// assume the type is branch if no type is found
				const type =
					instanceDataPoints.find(
						(dataPoint) => dataPoint?.metadata?.type
					)?.metadata?.type ?? "branch";

				return {
					datasetName: dataset.name,
					axesName: axis.name,
					file: documentMetadata.file.datasets[dataset.name],
					page: documentMetadata.page.datasets[dataset.name],
					type,
				};
			}
		}

		return {};
	};
}

export { init };
