<template>
	<v-container
		id="data-entry"
		class="pa-0"
		height="100%"
		fluid
	>
		<v-overlay
			:model-value="loading"
			class="align-center justify-center"
		>
			<v-progress-circular
				color="primary"
				indeterminate
				size="64"
			/>
		</v-overlay>

		<iframe
			:id="module"
			ref="iframe"
			src="/modules/wpd/app/index.html"
		/>

		<v-metadata
			v-if="showMetadata"
			ref="metadata"
			:bounds="bounds"
			:tags="tags"
			:tag-groups="tagGroups"
		/>

		<v-hint
			ref="hint"
			:bounds="bounds"
		/>

		<data-entry-menu
			@open-file-in-new-tab="openFileInNewTab"
			@open-raw-data-viewer="openRawDataViewer"
		/>

		<data-entry-validation-dialog
			v-model="validationDialog"
			:validation-data="validationData"
			:module-window="moduleWindow"
		/>

		<data-entry-pre-rework-dialog v-model="preReworkDialog" />

		<v-raw-data-viewer
			v-if="rawJsonViewerEnabled && showRawDataViewer"
			v-model="showRawDataViewer"
			:raw-data="rawData"
			:file-names="fileNames"
			:module-window="moduleWindow"
			@update-json="updateJson"
		/>
	</v-container>
</template>

<script>
import _ from "lodash";
import { mapMutations } from "vuex";

import interfaces from "@/plugins/interfaces";
import utils from "@/utils";
import { useLDFlag } from "launchdarkly-vue-client-sdk";

import Timer from "@/assets/scripts/autosave-timer.worker.js";

import VHint from "@/components/VHint";
import VMetadata from "@/components/VMetadata";
import VRawDataViewer from "@/components/VRawDataViewer.vue";
import DataEntryMenu from "@/pages/DataEntry/DataEntryMenu";
import DataEntryPreReworkDialog from "@/pages/DataEntry/DataEntryPreReworkDialog";
import DataEntryValidationDialog from "@/pages/DataEntry/DataEntryValidationDialog";
import tableAxisInstruction from "@/assets/images/table_axis_instruction.gif";
import createCompositeKeyUtils from "@/compositeKeyUtils";
import { ref, onMounted } from "vue";
// import pako from "pako";
import { v4 as uuidv4 } from "uuid";

const autoSaveWait = 300000; // 5 mins

export default {
	name: "DataEntry",

	components: {
		DataEntryMenu,
		DataEntryPreReworkDialog,
		DataEntryValidationDialog,
		VHint,
		VMetadata,
		VRawDataViewer,
	},

	async beforeRouteLeave(to, from, next) {
		if (this.isMounted && !this.skipDirtyCheck && this.checkDirty()) {
			this.$emit("deactivate-activity-timer");

			// NOTE - The vue router beforeRouteLeave is only hit after the first attempt to
			// navigate away; a subsequent click will bypass this function.  To prevent data loss,
			// save after cancelling the first navigation.
			// NOTE - session timeouts can cause the code to reach here without
			// authentication
			if (this.isAuthenticated) {
				if (
					!confirm(
						"You have unsaved changes. Do you really want to leave?"
					)
				) {
					await this.timedAutoSave();
					this.$emit("activate-activity-timer");
					return next(false);
				}

				this.$emit("activate-activity-timer");
			}
		}

		document.removeEventListener("keydown", this.keyDownHandler);

		this.deactivateAutoSave();

		// clear dirty flag
		this.clearDirty();

		// destroy the autosave worker
		this.timer?.terminate();
		this.timer = null;

		// emit signal
		if (this.training) {
			this.$emit("leave-data-entry-training");
		} else {
			this.$emit("leave-data-entry");
		}

		this.isUnmounted = true;
		this.isMounted = false;
		this.preReworkDialog = false;
		this.validationDialog = false;
		this.loading = false;
		this.clearNotification();

		next();
	},

	props: {
		paperUuid: {
			type: String,
			required: true,
		},
		sdeUuid: {
			type: String,
			required: true,
		},
		training: Boolean,
		showBanner: {
			type: Boolean,
			default: false,
		},
	},

	emits: [
		"enter-data-entry-training",
		"enter-data-entry",
		"leave-data-entry-training",
		"leave-data-entry",
		"activate-activity-timer",
		"deactivate-activity-timer",
		"check-dirty",
		"handle-logout-save",
	],

	setup() {
		const showMetadata = ref(false);
		onMounted(() => {
			setTimeout(() => {
				showMetadata.value = true;
			}, 100);
		});

		const fitButtonsUpdateFlag = useLDFlag("fit-buttons-update");
		const rawJsonViewerEnabled = useLDFlag("raw-json-viewer");
		const reworkStudyTreeFlag = useLDFlag("rework-study-tree-interactions");
		const barGraphEditFlag = useLDFlag("bar-graph-editing");
		const xyGraphEditFlag = useLDFlag("xy-graph-editing");
		const tableEditFlag = useLDFlag("table-editing");
		const tableHeaderDataInCellDialogFlag = useLDFlag(
			"show-table-header-data-in-cell-dialog"
		);

		return {
			fitButtonsUpdateFlag,
			rawJsonViewerEnabled,
			reworkStudyTreeFlag,
			barGraphEditFlag,
			xyGraphEditFlag,
			tableEditFlag,
			tableHeaderDataInCellDialogFlag,
			showMetadata,
		};
	},

	data() {
		return {
			autoSaveComplete: true,
			bounds: null,
			compositeKeyUtils: null,
			existingData: null,
			fileMap: {},
			isMounted: false,
			isUnmounted: false,
			loading: true,
			paperData: null,
			menu: false,
			moduleWindow: null,
			preReworkDialog: false,
			rawData: {},
			showRawDataViewer: false,
			skipDirtyCheck: false,
			tags: [],
			tagGroups: [],
			timer: null,
			validationData: null,
			validationDialog: false,
		};
	},

	computed: {
		barGraphEditEnabled() {
			return this.barGraphEditFlag;
		},
		dirty() {
			return this.$store.getters.dirty;
		},
		discardDataEntry() {
			return this.$store.getters.discardDataEntry;
		},
		domain() {
			return process.env.VUE_APP_WEB_HOST;
		},
		done() {
			return this.$store.getters.done;
		},
		exit() {
			return this.$store.getters.exit;
		},
		fileNames() {
			return Object.values(this.fileMap).map((file) => file.name);
		},
		isAuthenticated() {
			return this.$store.getters.isAuthenticated;
		},
		metadataCache() {
			return this.$store.getters.metadataCache;
		},
		module() {
			return this.$store.getters.module;
		},
		paper() {
			return this.$store.getters.paper;
		},
		protocol() {
			return process.env.VUE_APP_ENV === "development" ? "http" : "https";
		},
		resetDataEntry() {
			return this.$store.getters.resetDataEntry;
		},
		resolve() {
			return this.$store.getters.resolve;
		},
		reworkStudyTree() {
			return this.reworkStudyTreeFlag;
		},
		save() {
			return this.$store.getters.save;
		},
		saveInProgress() {
			return this.$store.getters.saveInProgress;
		},
		saveUUID() {
			return this.$store.getters.saveUUID;
		},
		tableEditEnabled() {
			return this.tableEditFlag;
		},
		tableHeaderDataInCellDialogEnabled() {
			return this.tableHeaderDataInCellDialogFlag;
		},
		validate() {
			return this.$store.getters.validate;
		},
		xyGraphEditEnabled() {
			return this.xyGraphEditFlag;
		},
	},

	watch: {
		discardDataEntry(val) {
			if (val) {
				this.discardSDE();
			}
		},
		resetDataEntry(val) {
			if (val) {
				this.resetJSON();
			}
		},
		save(val) {
			if (val) {
				this.saveJSON(interfaces[this.module].saveJSON());
			}
		},
		validate(json) {
			if (json) {
				this.validateJSON(interfaces[this.module].saveJSON());
			}
		},
		showBanner() {
			this.$refs.iframe.contentWindow.postMessage(
				{ type: "resize" },
				"*"
			);
		},
	},

	async mounted() {
		// make sure tags and tag groups are resolved before initializing the
		// iframe
		await this.waitForIframeLoad();
		await this.getPaper();
		await Promise.all([this.fetchTags(), this.fetchTagGroups()]);

		this.compositeKeyUtils = createCompositeKeyUtils(this.tags);

		if (!this.isUnmounted) {
			await this.iframeInit();
			this.activateAutoSave();
		}

		this.isMounted = true;
	},

	beforeUnmount() {
		// destroy the autosave worker
		this.timer?.terminate();
		this.timer = null;
		this.isUnmounted = true;
	},
	created() {
		this.$emit("check-dirty", this.checkDirty);
		this.$emit("handle-logout-save", this.handleLogoutSave);
	},

	methods: {
		...mapMutations([
			"setHelp",
			"clearDirty",
			"clearNotification",
			"clearPaper",
			"clearSaveUUID",
			"receiveSave",
			"receiveValidate",
			"clearMetadataCache",
			"setDirty",
			"setPaper",
			"setSaveInProgress",
			"setSaveUUID",
			"showNotification",
		]),
		async iframeInit() {
			this.moduleWindow = window.frames[this.module].contentWindow;

			// forward keyboard events to the iframe
			document.removeEventListener("keydown", this.keyDownHandler);
			document.addEventListener("keydown", this.keyDownHandler);

			// forward custom events to the iframe
			document.removeEventListener("next-cell", this.nextCellHandler);
			document.addEventListener("next-cell", this.nextCellHandler);

			this.$refs.iframe.contentWindow.removeEventListener(
				"resize",
				this.resizeHandler
			);
			this.$refs.iframe.contentWindow.addEventListener(
				"resize",
				this.resizeHandler
			);

			// set images for wpd
			if (this.module === "wpd") {
				const domain =
					process.env.VUE_APP_ENV === "development"
						? `${this.domain}:8080`
						: this.domain;
				const url = `${this.protocol}://${domain}${tableAxisInstruction}`;

				interfaces[this.module].setOptions({
					tableAxisInstructionURL: url,
				});
			}

			interfaces[this.module].init(this.moduleWindow, this);

			await this.loadFiles();

			if (this.training) {
				this.$emit("enter-data-entry-training");
			} else {
				this.$emit("enter-data-entry");
			}
		},
		clear() {
			this.autoSaveComplete = true;
			this.bounds = null;
			this.existingData = null;
			this.fileMap = {};
			this.isMounted = false;
			this.isUnmounted = true;
			this.loading = true;
			this.paperData = null;
			this.moduleWindow = null;
			this.preReworkDialog = false;
			this.skipDirtyCheck = false;
			this.tags = [];
			this.tagGroups = [];
			this.timer = null;
			this.userManualActive = false;
			this.validationData = null;
			this.validationDialog = false;
		},
		nextCellHandler() {
			this.moduleWindow.document?.dispatchEvent(new Event("next-cell"));
		},
		keyDownHandler(event) {
			const newEvent = new KeyboardEvent("keydown", {
				code: event.code,
				key: event.key,
				keyCode: event.keyCode,
				ctrlKey: event.ctrlKey,
				metaKey: event.metaKey,
				shiftKey: event.shiftKey,
			});

			this.moduleWindow.document?.dispatchEvent(newEvent);
		},
		resizeHandler: _.debounce(
			function () {
				this.bounds = interfaces[this.module].getBounds();
			},
			300,
			{ trailing: true }
		),
		isPreRework(createdDate) {
			const cutoff = new Date("2024-10-12T00:00:00Z");
			return !createdDate || createdDate.getTime() < cutoff.getTime();
		},
		getPaper() {
			return this.$http
				.get(`/papers/${this.paperUuid}`, {
					params: {
						saveUUID: this.sdeUuid,
						...(this.training ? { isTraining: true } : {}),
					},
				})
				.then(async (response) => {
					this.paperData = response.data;
					if (response.data.saveData !== null) {
						const saveData = response.data.saveData;

						const saveJson = saveData.data;
						if (saveJson) {
							saveData.data = JSON.parse(
								await utils.decompressJson(saveJson)
							);
						}

						const isPreRework = this.isPreRework(
							new Date(saveData.created_at)
						);

						if (isPreRework) {
							this.preReworkDialog = true;
							this.setSaveUUID(uuidv4());
						} else {
							this.existingData = saveData;
						}

						if (saveJson && saveJson.tags) {
							this.tags = saveJson.tags;
						}

						if (saveJson && saveJson.tagGroups) {
							this.tagGroups = saveJson.tagGroups;
						}
					}
				})
				.catch((error) => {
					// display error
					if (error.response) {
						if (error.response.data.message) {
							this.showNotification(error.response.data);
						} else {
							this.showNotification(error.response.data.error);
						}
					}
				});
		},
		async loadFiles() {
			this.fileMap = {};

			// convert base64 data to File objects
			this.paperData.fileData.forEach((data) => {
				const file = new File(
					[utils.base64ToBlob(data.data, data.type)],
					data.name,
					{
						type: data.type,
					}
				);

				this.fileMap[data.uuid] = file;
			});

			// load files into module
			return interfaces[this.module]
				.loadFiles(Object.values(this.fileMap))
				.then(async () => {
					// get image bounds
					this.bounds = interfaces[this.module].getBounds();

					// script injection for additional UIs
					interfaces[this.module].inject(this);

					// load json file if it exists
					if (this.existingData) {
						await interfaces[this.module].loadJSON(
							this,
							this.existingData.data
						);
					} else {
						this.loading = false;
					}
				});
		},
		discardSDE() {
			this.$http
				.post(`/data-entry/${this.sdeUuid}/delete`)
				.then(() => {
					this.showNotification({
						message: "Data discarded successfully",
						status: "success",
					});

					this.existingData = {};
				})
				.catch((error) => {
					// display error
					if (error.response) {
						if (error.response.data.message) {
							this.showNotification(error.response.data);
						} else {
							this.showNotification(error.response.data.error);
						}
					}
				})
				.finally(() => {
					// consume save event
					this.receiveSave();

					// resolve if caller is a promise
					if (this.resolve) {
						this.resolve();
					}

					this.skipDirtyCheck = true;

					this.$router.push("/home/dashboard");
				});
		},

		resetJSON() {
			this.$http
				.post(`/data-entry/${this.sdeUuid}`, {
					data: null,
					done: this.done,
					paperUUID: this.paperUuid,
					dataFormat: "WPD",
					...(this.training ? { isTraining: true } : {}),
				})
				.then(() => {
					this.showNotification({
						message: "Data reset successfully",
						status: "success",
					});

					this.existingData = {};
				})
				.catch((error) => {
					// display error
					if (error.response) {
						if (error.response.data.message) {
							this.showNotification(error.response.data);
						} else {
							this.showNotification(error.response.data.error);
						}
					}
				})
				.finally(() => {
					// consume save event
					this.receiveSave();

					// resolve if caller is a promise
					if (this.resolve) {
						this.resolve();
					}

					window.location.reload();
				});
		},

		async saveJSON(json) {
			// abort if there is already a save in-progress
			if (this.saveInProgress || _.isEmpty(this.paper)) {
				return Promise.resolve();
			}

			this.setSaveInProgress(true);
			const versionedJSON = this.addDactylVersion(json);

			let finalJson;
			if (versionedJSON) {
				finalJson = await utils.compressJson(versionedJSON);
			} else {
				finalJson = versionedJSON;
			}

			// save json to server
			return this.$http
				.post(`/data-entry/${this.sdeUuid}`, {
					data: finalJson,
					done: this.done,
					paperUUID: this.paperUuid,
					dataFormat: "WPD",
					...(this.training ? { isTraining: true } : {}),
				})
				.then((response) => {
					if (this.done) {
						this.showNotification({
							message: "Paper saved and marked as done",
							status: "success",
						});

						// clear paper from store
						this.clearPaper();

						// clear save UUID
						this.clearSaveUUID();
					} else {
						if (this.existingData) {
							this.existingData.data = JSON.parse(versionedJSON);
						} else {
							this.existingData = {
								data: JSON.parse(versionedJSON),
								created_at: new Date().toLocaleString(),
							};
						}
						// display success
						this.showNotification({
							message: response.data.message,
							status: response.data.status,
							timeout: 1000,
						});
					}
				})
				.catch((error) => {
					// display error
					if (error.response) {
						if (error.response.data.message) {
							this.showNotification(error.response.data);
							if (error.response.data.code === "data_cleared") {
								throw new Error(error.response.data.message);
							}
						} else if (error.response.data.error) {
							this.showNotification(error.response.data.error);
						} else {
							this.showNotification("SDE save failed");
						}
					}
				})
				.finally(() => {
					this.setSaveInProgress(false);

					const navigate = this.done || this.exit;

					// consume save event
					this.receiveSave();

					// resolve if caller is a promise
					if (this.resolve) {
						this.resolve();
					}

					if (navigate) {
						// skip navigation confirmation
						this.skipDirtyCheck = true;

						// redirect to dashboard
						this.$router.push("/home/dashboard");
					}
				});
		},
		validateJSON(json) {
			this.$http
				.post(`/data-entry/${this.sdeUuid}/validate`, {
					data: json,
				})
				.then((response) => {
					this.validationData = response.data;
					this.validationDialog = true;
				})
				.catch((error) => {
					this.showNotification({
						message: `Error while attempting to validate: ${error.response?.data?.message}`,
						status: "error",
					});
				})
				.finally(() => {
					this.receiveValidate();
				});
		},
		addDactylVersion(json) {
			const object = JSON.parse(json);

			object.dactylVersion = this.$version;
			if (!object.tags) {
				object.tags = this.tags;
			}

			if (!object.tagGroups) {
				object.tagGroups = this.tagGroups;
			}

			return JSON.stringify(object);
		},
		fetchTags() {
			if (this.tags.length > 0) {
				return Promise.resolve();
			}

			return this.$http
				.get("/tags", {
					params: {
						flat: true,
						padded: true,
					},
				})
				.then((response) => {
					this.tags = [...response.data.tags];
				})
				.catch((error) => {
					// display error
					if (error.response) {
						if (error.response.data.message) {
							this.showNotification(error.response.data);
						} else {
							this.showNotification(error.response.data.error);
						}
					}
				});
		},
		getTags() {
			return this.tags;
		},
		fetchTagGroups() {
			if (this.tagGroups.length > 0) {
				return Promise.resolve();
			}

			return this.$http
				.get("/tag-groups", {
					params: {
						filteredByLevel: true,
						omitDeleted: true,
					},
				})
				.then((response) => {
					this.tagGroups = [...response.data.tagGroups];
				})
				.catch((error) => {
					// display error
					if (error.response) {
						if (error.response.data.message) {
							this.showNotification(error.response.data);
						} else {
							this.showNotification(error.response.data.error);
						}
					}
				});
		},
		getTagGroups() {
			return this.tagGroups;
		},
		captureMetadata(args) {
			return this.$refs.metadata.open(args);
		},
		showNoHeaderHint(args) {
			const article = {
				row: "row does",
				col: "column does",
				both: "row and column do",
			};

			const messages = [
				`This ${article[args.mode]} not have header information.`,
				"Add data to the headers by selecting the axis, and then you can add data here.",
			];

			this.$refs.hint.open({
				color: "error",
				messages: messages,
				position: args.position,
			});
		},
		hideNoHeaderHint() {
			this.$refs.hint.close();
		},
		checkDirty() {
			const currentJson = interfaces[this.module].saveJSON();
			const currentVersionedJson = this.addDactylVersion(currentJson);
			const currentVersionedData = JSON.parse(currentVersionedJson);

			// prevent setting dirty flag if axesColl and datasetColl is empty
			// the cause of this intermittent bug has not been identified
			const noData =
				currentVersionedData.axesColl.length === 0 &&
				currentVersionedData.datasetColl.length === 0;

			const existingData = this.existingData?.data;
			if (
				(noData && !existingData) ||
				_.isEqual(
					_.omit(currentVersionedData, "dactylVersion"),
					_.omit(existingData, "dactylVersion")
				)
			) {
				this.clearDirty();
				return false;
			}

			// the store dirty flag is for warning user that unsaved changes
			// will be lost
			this.setDirty();

			return true;
		},
		async timedAutoSave() {
			if (
				!this.$refs ||
				!this.$refs.metadata ||
				this.$refs.metadata?.dialog ||
				this.$refs.metadata?.selectTextCancellable
			)
				return;

			// update in-progress metadata
			this.$refs.metadata.updateMetadataCache();

			if (!_.isEmpty(this.metadataCache)) {
				// metadata populated, save to json
				interfaces[this.module].setMetadata(this.metadataCache);

				// clear dactyl store metadata
				this.clearMetadataCache();
			}

			if (this.checkDirty()) {
				// save json
				await this.saveJSON(interfaces[this.module].saveJSON());
				this.$refs.metadata.refreshMetadata();
			}
		},
		async autoSaveMetadata() {
			if (this.checkDirty()) {
				const saveData = interfaces[this.module].saveJSON(this);
				await this.saveJSON(saveData);
			}

			this.autoSaveComplete = true;
		},
		activateAutoSave() {
			// create the worker if necessary
			if (!this.timer) {
				this.timer = new Timer();
			}

			// listen for timer messages
			this.timer.onmessage = async ({ data }) => {
				// process interval ticks
				if (this.isAuthenticated && data === "interval_tick") {
					await this.timedAutoSave();
				}
			};

			// start the interval
			this.timer.postMessage({
				action: "start_interval",
				duration: autoSaveWait,
			});
		},
		deactivateAutoSave() {
			this.timer?.postMessage({ action: "stop_interval" });
		},
		hintKeydownHandler(event) {
			switch (event.key) {
				case "Escape":
					this.hideNoHeaderHint();
					break;
			}
		},
		waitForIframeLoad() {
			return new Promise((resolve) => {
				this.$refs.iframe.removeEventListener("load", resolve);
				this.$refs.iframe.addEventListener("load", resolve);
			});
		},
		openFileInNewTab() {
			// get current file index
			const { file: fileIndex, page: pageNumber } =
				interfaces[this.module].getCurrentLocationInfo();

			// get file at current file index
			const fileData = Object.values(this.fileMap)[fileIndex];

			// open file in new tab
			const objectUrl = URL.createObjectURL(fileData);
			window.open(`${objectUrl}#page=${pageNumber}`);
			URL.revokeObjectURL(objectUrl);
		},
		openRawDataViewer() {
			if (this.rawJsonViewerEnabled) {
				this.rawData = JSON.parse(interfaces[this.module].saveJSON());
				this.showRawDataViewer = true;
			}
		},
		async handleLogoutSave() {
			if (this.$refs.metadata?.dialog) {
				// Close the metadata dialog and export the existing metadata
				this.autoSaveComplete = false;

				this.$refs.metadata.resolve(
					this.$refs.metadata.exportMetadata()
				);

				// This is some hackery - it's tough to synchronously wait for the post-processing and saving
				// that happens when we closed the metadata dialog.  Instead, simply wait for "autoSaveComplete"
				// variable to be set to true, which will happen after post-processing and after the save completes.
				await new Promise((resolve) => {
					const unwatch = this.$watch(
						"autoSaveComplete",
						(newValue) => {
							if (newValue) {
								unwatch();
								resolve();
							}
						},
						{ immediate: true }
					);
				});
			} else {
				await this.autoSaveMetadata();
			}
		},
		async updateJson(json) {
			if (this.rawJsonViewerEnabled) {
				this.loading = true;
				await interfaces[this.module].loadJSON(this, JSON.parse(json));
			}
		},
	},
};
</script>

<style scoped lang="scss">
iframe {
	width: 100%;
	height: 100%;
	border: 0;
}

.v-btn--fab.v-size--small.v-btn--absolute.v-btn--bottom.menu-button {
	bottom: 25px;
	right: 25px;
	z-index: 204;
}
</style>
