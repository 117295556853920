<template>
	<v-speed-dial
		v-model="menu"
		color="secondary"
		transition="fab-transition"
	>
		<template #activator="{ props: activatorProps }">
			<v-btn
				v-bind="activatorProps"
				icon
				class="menu-button bg-secondary text-white"
				elevation="4"
				size="x-large"
			>
				<v-icon v-if="menu"> mdi-close </v-icon>
				<v-icon v-else> mdi-menu </v-icon>
			</v-btn>
		</template>

		<div
			v-if="rawJsonViewerEnabled"
			key="open-raw-data-container"
		>
			<v-tooltip
				v-if="menu"
				key="open-raw-data-tooltip"
				location="left"
				:model-value="true"
				:open-on-click="false"
				:open-on-hover="false"
				:open-on-focus="false"
				:offset="offset"
			>
				<template #activator="{ props }">
					<v-btn
						v-bind="props"
						key="open-raw-data"
						size="small"
						icon
						color="primary"
						@click="openRawDataViewer"
					>
						<v-icon>mdi-table-eye</v-icon>
					</v-btn>
				</template>
				<span>View current data</span>
			</v-tooltip>
		</div>

		<div key="user-manual-container">
			<v-tooltip
				v-if="menu"
				key="user-manual-tooltip"
				location="left"
				:model-value="true"
				:open-on-click="false"
				:open-on-hover="false"
				:open-on-focus="false"
				:offset="offset"
			>
				<template #activator="{ props }">
					<v-btn
						v-bind="props"
						key="user-manual"
						size="small"
						icon
						color="primary"
						@click="openUserManual"
					>
						<v-icon>mdi-book-information-variant</v-icon>
					</v-btn>
				</template>
				<span>User manual</span>
			</v-tooltip>
		</div>

		<div key="open-file-container">
			<v-tooltip
				v-if="menu"
				key="open-file-tooltip"
				location="left"
				:model-value="true"
				:open-on-click="false"
				:open-on-hover="false"
				:open-on-focus="false"
				:offset="offset"
			>
				<template #activator="{ props }">
					<v-btn
						v-bind="props"
						key="open-file"
						size="small"
						icon
						color="primary"
						@click="openFileInNewTab"
					>
						<v-icon>mdi-open-in-new</v-icon>
					</v-btn>
				</template>
				<span>View current file in new tab</span>
			</v-tooltip>
		</div>
	</v-speed-dial>
</template>

<script>
import { mapMutations } from "vuex";
import { useLDFlag } from "launchdarkly-vue-client-sdk";

export default {
	name: "DataEntryMenu",

	emits: ["open-file-in-new-tab", "open-raw-data-viewer"],

	setup() {
		const rawJsonViewerEnabled = useLDFlag("raw-json-viewer");

		return {
			offset: [30, 15],
			rawJsonViewerEnabled,
		};
	},

	data() {
		return {
			fileMap: {},
			menu: false,
		};
	},

	methods: {
		...mapMutations(["setHelp"]),
		openUserManual() {
			this.setHelp("#top");
		},
		openFileInNewTab() {
			this.$emit("open-file-in-new-tab");
		},
		openRawDataViewer() {
			this.$emit("open-raw-data-viewer");
		},
	},
};
</script>

<style scoped lang="scss">
.menu-button {
	height: 56px;
	width: 56px;
	min-width: 56px;
	position: absolute;
	bottom: 25px;
	right: 25px;
	z-index: 204;
}
</style>
