/**
 * Remove certain HTML elements in WPD.
 */
function apply(wpd, wpdDocument, dataEntryVM) {
	wpdDocument.getElementById("start-sidebar").style.visibility = "hidden";

	// remove all top menus ("File" and "Help" menus)
	const menus = wpdDocument.getElementsByClassName("wpd-menu");
	while (menus.length) {
		menus[0].remove();
	}

	// remove "Load Image" button
	wpdDocument.querySelector("[value='Load Image']").parentElement.remove();

	// remove "View Keyboard Shortcuts" button
	wpdDocument
		.querySelector("[value='View Keyboard Shortcuts']")
		.parentElement.remove();

	// replace acquire data sidebar to remove the automatic extraction
	replaceAcquireDataSidebar(wpdDocument);

	replaceFooterText(wpdDocument);

	addBarHeaderCalibrationSidebar(wpdDocument);

	// add left sidebar drag border area
	addLeftSidebarDragBorder(wpdDocument);

	// replace plot type popup cancel button
	replacePlotTypePopupCancelButton(wpdDocument);

	// add plot type toggle
	addPlotTypeToggle(wpdDocument);

	// add tree widgets
	addTreeWidgets(wpdDocument);

	// replace add dataset popup
	replaceAddDatasetDialog(wpdDocument);

	// insert graph axes label popup
	addGraphAxesLabelPopup(wpdDocument);

	// insert bar graph categorical axes label popup
	addBarGraphCategoricalAxesPopup(wpdDocument);

	// insert bar graph category count sidebar
	addBarGraphCategoryCountSidebar(wpdDocument);

	// insert bar graph header metadata sidebar
	addBarGraphHeaderMetadataSidebar(wpdDocument);

	// insert bar graph bar axis label popup
	addBarGraphAxesLabelPopup(wpdDocument);

	// default to no xy axis rotation and update copy
	updateXYAxisRotation(wpdDocument);

	// add ids to page previous and next buttons
	addIDsToPageNavigationButtons(wpdDocument);

	// add center container
	addCenterContainer(wpdDocument);

	// add center toolbar container
	addCenterToolbarContainer(wpdDocument);

	// add dialog to confirm data removal
	addClearAllConfirmDialog(wpdDocument);

	// add dialog to confirm study element deletion
	addDeleteStudyElementConfirmDialog(wpdDocument);
	addDeleteStudyElementEmptyDialog(wpdDocument);
	addEditNonExistentElementDialog(wpdDocument);
	addEditGraphDatasetDialog(wpdDocument);
	addAddGraphDatasetDialog(wpdDocument);

	// add pdf text layer div
	addPdfTextLayerDiv(wpdDocument);

	// update zoom fit button
	if (dataEntryVM.fitButtonsUpdateFlag) {
		updateZoomFitButton(wpd, wpdDocument);
	}
}

/**
 * Replace acquire data sidebar
 */
function replaceAcquireDataSidebar(wpdDocument) {
	const acquireDataSidebar = wpdDocument.getElementById("acquireDataSidebar");

	// replace acquire data sidebar
	acquireDataSidebar.outerHTML = `
		<div id="acquireDataSidebar" class="sidebar">
			<p class="sidebar-title">Manual Extraction</p>

			<p>
				<input type="button" value="Add Point (A)" onclick="wpd.acquireData.manualSelection();" style="width: 115px;" id="manual-select-button" class='button-disabled'>
				<input type="button" value="Adjust Point (S)" onClick="wpd.acquireData.adjustPoints();" style="width: 115px;" id="manual-adjust-button">
			</p>

			<p>
				<input type="button" value="Delete Point (D)" onclick="wpd.acquireData.deletePoint();" style="width: 115px;" id="delete-point-button">
			</p>
			<br />
			<div id="point-groups-controls" hidden>
				<br />
				<p class="sidebar-title">Point Groups</p>
				<p>Current Tuple: <span id="current-point-tuple-index" style="font-weight: bold;"></span></p>
				<p>Current Group: <span id="current-point-group-name" style="font-weight: bold;"></span></p>
				<p>
					<input type="button" value="Prev Group (,)" onclick="wpd.pointGroups.previousGroup();" style="width: 115px;">
					<input type="button" value="Next Group (.)" onclick="wpd.pointGroups.nextGroup();" style="width: 115px;">
				</p>
			</div>
		</div>
	`;
}

function replaceFooterText(wpdDocument) {
	const bottomNavBarContainer = wpdDocument.getElementById(
		"bottomNavbarContainer"
	);
	const filesContainer = bottomNavBarContainer.querySelector("span.files");
	filesContainer.outerHTML = `Source File:`;
}

/**
 * Add draggable border area to left sidebar
 */
function addLeftSidebarDragBorder(wpdDocument) {
	const leftSidebar = wpdDocument.getElementById("left-side-container");

	// insert show/hide link
	leftSidebar.insertAdjacentHTML(
		"beforeend",
		`<div id="left-sidebar-border" class="draggable">
			<div class="grip"></div>
		</div>`
	);
}

/**
 * Overwrite plot type popup to add to cancel button behavior.
 */
function replacePlotTypePopupCancelButton(wpdDocument) {
	const plotTypePopupCancel = wpdDocument
		.getElementById("axesList")
		.querySelector("input[value='Cancel']");

	plotTypePopupCancel.outerHTML = `
		<input type="button" value="Cancel" onclick="wpd.popup.close('axesList'); wpd.tree.exitWizard();">
	`;
}

/**
 * Injects show/hide plot type link and function to toggle visibility of plot types and update labels.
 */
function addPlotTypeToggle(wpdDocument) {
	// get the axis type table
	const tableBody = wpdDocument
		.getElementById("axesList")
		.querySelector("tbody");
	const tableRows = tableBody.children;

	// define always-hidden list
	const alwaysHidden = [
		"r_circular_chart_recorder",
		"r_image",
		"r_map",
		"r_table",
	];

	// define toggle list
	const toggle = ["r_polar", "r_ternary"];

	// hide image and table plot types, update xy and bar labels
	for (let i = 0; i < tableRows.length; i++) {
		const input = tableRows[i].querySelector("input");
		if (input) {
			if (alwaysHidden.includes(input.getAttribute("id"))) {
				tableRows[i].classList.add("hidden");
			}

			if (input.getAttribute("id") === "r_xy") {
				// get the text node of the label element and replace it
				input.parentNode.childNodes[1].replaceWith(
					wpdDocument.createTextNode(" Scatter or Line Plot")
				);
			}

			if (input.getAttribute("id") === "r_bar") {
				// get the text node of the label element and replace it
				input.parentNode.childNodes[1].replaceWith(
					wpdDocument.createTextNode(" Bar Plot")
				);
			}
		}
	}

	// add toggle function to wpd
	wpdDocument.togglePlotTypes = function () {
		// toggle rows
		for (let i = 0; i < tableRows.length; i++) {
			const input = tableRows[i].querySelector("input");
			if (input && toggle.includes(input.getAttribute("id"))) {
				tableRows[i].classList.toggle("hidden");
			}
		}

		// toggle label
		const label = tableBody.lastElementChild.querySelector("a > span");

		if (label.innerHTML === "- Fewer") {
			label.innerHTML = "+ More";
		} else {
			label.innerHTML = "- Fewer";
		}
	};

	// insert show/hide link
	tableBody.insertAdjacentHTML(
		"beforeend",
		`
			<tr>
				<td>
					<a href="#" onclick="togglePlotTypes()">
						<span>- Fewer</span> Plot Types
					</a>
				</td>
			</tr>
		`
	);

	// default to collapsed
	wpdDocument.togglePlotTypes();
}

/**
 * Inject elements to the tree for improved navigation and user experience.
 */
function addTreeWidgets(wpdDocument) {
	// get the tree widget container
	const treeParent =
		wpdDocument.getElementById("tree-container").parentElement;

	// insert title
	treeParent.insertAdjacentHTML(
		"afterbegin",
		`
			<div style="font-size: 13px;">
				<p class="sidebar-title" style="margin: 5px 0;">Extract from</p>
			</div>
		`
	);

	// insert new widgets
	treeParent.insertAdjacentHTML(
		"beforeend",
		`
			<div id="text-item-tree-widget" class="tree-widget" style="flex: 0 0 100px;">
				<p class="sidebar-title">Text Data</p>
				<div style="text-align: center;" class="texts">
					<p>Data Points: <span class="data-point-counter">0</span></p>
				</div>
			</div>
			<div id="graph-item-tree-widget" class="tree-widget">
				<p class="sidebar-title">Graph Data</p>
				<div style="text-align: center;">
					<p>
						<input
							type="button"
							value="+ Add New Graph"
							id="graph-add-button"
							style="width: 150px;"
							onclick="wpd.tree.addGraphAxis(this);"
						/>
					</p>
				</div>
				<div class="tree-widget-graphs-list"></div>
			</div>
			<div id="table-item-tree-widget" class="tree-widget">
				<p class="sidebar-title">Table Data</p>
				<div style="text-align: center;">
					<p>
						<input
							type="button"
							value="+ Add New Table"
							id="table-add-button"
							style="width: 150px;"
							onclick="wpd.tree.addTableAxis(this);"
						/>
					</p>
				</div>
				<div class="tree-widget-tables-list"></div>
			</div>
			<div id="study-tree-display">
				<p class="sidebar-title">Study Branches</p>
				<div class="study-list"></div>
				<div id="study-tree-border" class="draggable horizontal">
					<div class="grip horizontal"></div>
				</div>
			</div>
		`
	);
}

/**
 * Replace add dataset popup.
 *
 * Note that this is shared between XY and bar graphs
 */
function replaceAddDatasetDialog(wpdDocument) {
	// get add dataset popup element
	const addDatasetPopup = wpdDocument.getElementById("add-dataset-popup");

	addDatasetPopup.innerHTML = `
		<div class="popupheading">Graph Study Branches</div>
		<div id="add-study-branch-inputs">
			<div class="add-dataset-row" data-index="0">
				<div class="add-dataset-row-label">
					Study Branch <span class="add-dataset-number">1</span>:
				</div>

				<div class="add-dataset-row-actions">
					<input
						type="text"
						class="add-dataset-study-arm-input"
						data-type="composite-key"
						placeholder="Study branch"
					/>

					<input
						type="text"
						class="add-dataset-tag-group-input"
						data-type="tag-group"
						placeholder="Tag group"
					/>

					<label class="add-dataset-same-tag-groups">
						<input
							type="checkbox"
							class="add-dataset-same-tag-groups-checkbox"
						/>

						Same tag group for all
					</label>

					<br />

					<span class="error-message"></span>
				</div>
			</div>
		</div>

		<div style="text-align: center;">
			<a href="#" title="Shift + Enter" onclick="wpd.dataSeriesManagement.addRow();">+ Add another study branch</a>
		</div>

		<br />

		<p id="graph-add-study-branch-buttons" align="center" hidden>
			<input id="add-dataset-save-button" type="button" value="Add Study Branches" onclick="wpd.dataSeriesManagement.addDatasets();" />
			<input type="button" value="Cancel" onclick="wpd.dataSeriesManagement.cancelDatasetAdd();" />
		</p>
		<p id="graph-edit-study-branch-buttons" align="center" hidden>
			<input id="add-dataset-save-button" type="button" value="Ok" onclick="wpd.xyGraphEdit.confirmSetStudyBranches();" />
			<input type="button" value="Cancel" onclick="wpd.xyGraphEdit.closeSetStudyBranchesDialog();" />
		</p>
	`;
}

function addGraphAxesLabelPopup(wpdDocument) {
	wpdDocument.getElementById("point-group-settings-popup").insertAdjacentHTML(
		"afterend",
		`
		<div id="graph-axes-label-popup" class="popup" style="width: 600px; left: 558px; top: -5px;">
			<div class="popupheading">Axis Labeling</div>
			<p>Define the data type for each axis:</p>
			<table class="graph-axes-label-popup-table">
				<tbody>
					<tr>
						<th />
						<th>Data type</th>
						<th>Unit</th>
						<th>Change from<br />baseline?</th>
						<th>Decimal<br />places</th>
					</tr>
					<tr>
						<td>X-Axis:</td>
						<td>
							<input
								type="text"
								class="axis-data-type"
								id="x-axis-data-type"
								placeholder="e.g. Time (number) or Mean"
							/>
							<br />
							<span class="error-message"></span>
						</td>
						<td>
							<input
								type="text"
								class="axis-unit"
								id="x-axis-unit"
								placeholder="e.g. Weeks or kg"
							/>
							<br />
							<span class="error-message"></span>
						</td>
						<td>
							<input
								type="checkbox"
								class="axis-change-from-baseline"
								id="x-axis-change-from-baseline"
							/>
						</td>
						<td>
							<input
								type="number"
								class="axis-decimals"
								id="x-axis-decimals"
								value="2"
							/>
						</td>
					</tr>
					<tr>
						<td>Y-Axis:</td>
						<td>
							<input
								type="text"
								class="axis-data-type"
								id="y-axis-data-type"
							/>
							<br />
							<span class="error-message"></span>
						</td>
						<td>
							<input
								type="text"
								class="axis-unit"
								id="y-axis-unit"
							/>
							<br />
							<span class="error-message"></span>
						</td>
						<td>
							<input
								type="checkbox"
								class="axis-change-from-baseline"
								id="y-axis-change-from-baseline"
							/>
						</td>
						<td>
							<input
								type="number"
								class="axis-decimals"
								id="y-axis-decimals"
								value="2"
							/>
						</td>
					</tr>
				</tbody>
			</table>
			<br>
			<p align="center">Data type updates will be automatically applied to existing data points.</p>
			<br>
			<p id="xy-graph-axis-buttons" style="text-align: center;" hidden>
				<input id="point-group-save-button" type="button" value="OK" onclick="wpd.alignAxes.saveAxesData(true);">
				<input id="point-group-cancel-button" type="button" value="Cancel" onclick="wpd.alignAxes.closeAxesLabelPopup();">
			</p>
			<p id="xy-edit-graph-axis-buttons" style="text-align: center;" hidden>
				<input id="point-group-save-button" type="button" value="OK" onclick="wpd.xyGraphEdit.confirmSetDataTypes();">
				<input id="point-group-cancel-button" type="button" value="Cancel" onclick="wpd.xyGraphEdit.closeSetDataTypesDialog();">
			</p>
		</div>`
	);
}

function addBarGraphAxesLabelPopup(wpdDocument) {
	wpdDocument.getElementById("point-group-settings-popup").insertAdjacentHTML(
		"afterend",
		`
		<div id="bar-graph-axes-label-popup" class="popup" style="width: 600px; left: 558px; top: -5px;">
			<div class="popupheading">Axes Labeling</div>
			<p>Define the data type for the bar axis:</p>
			<table class="graph-axes-label-popup-table">
				<tbody>
					<tr>
						<th>Data type</th>
						<th>Unit</th>
						<th>Change from<br />baseline?</th>
						<th>Decimal<br />places</th>
					</tr>
					<tr>
						<td>
							<input
								type="text"
								class="axis-data-type"
								id="bar-axis-data-type"
								placeholder="e.g. Time (number) or Mean"
							/>
							<br />
							<span class="error-message"></span>
						</td>
						<td>
							<input
								type="text"
								class="axis-unit"
								id="bar-axis-unit"
								placeholder="e.g. Weeks or kg"
							/>
							<br />
							<span class="error-message"></span>
						</td>
						<td>
							<input
								type="checkbox"
								class="axis-change-from-baseline"
								id="bar-axis-change-from-baseline"
							/>
						</td>
						<td>
							<input
								type="number"
								class="axis-decimals"
								id="bar-axis-decimals"
								value="2"
							/>
						</td>
					</tr>
				</tbody>
			</table>
			<br>
			<br>
			<p id="bar-graph-datatype-buttons" style="text-align: center;" hidden>
				<input id="point-group-save-button" type="button" value="OK" onclick="wpd.alignAxes.saveAxesData(true);">
				<input id="point-group-cancel-button" type="button" value="Cancel" onclick="wpd.alignAxes.closeAxesLabelPopup();">
			</p>
			<p id="edit-bar-graph-datatype-buttons" style="text-align: center;" hidden>
				<input id="edit-point-group-save-button" type="button" value="OK" onclick="wpd.barGraphEdit.completeSetBarAxisDatatypes();">
				<input id="edit-point-group-cancel-button" type="button" value="Cancel" onclick="wpd.barGraphEdit.cancelSetBarAxisDatatypes();">
			</p>
		</div>`
	);
}

function addBarGraphCategoricalAxesPopup(wpdDocument) {
	wpdDocument.getElementById("point-group-settings-popup").insertAdjacentHTML(
		"afterend",
		`
		<div id="bar-categorical-axes-info" class="popup" style="width: 650px; left: 533px; top: 60px;">
			<div class="popupheading">Align Bar Chart Categorical Axes</div>
			<p>&nbsp;</p>
			<p align="center">Click on two known points (P1, P2) representing the start and end of the categorical axis.</p>
			<p>&nbsp;</p>
			<p align="center"><input type="button" value="Proceed" onclick="wpd.alignAxes.pickHeaderCorners();"></p>
		</div>`
	);
}

function addBarGraphHeaderMetadataSidebar(wpdDocument) {
	// get the default calibration sidebar
	const axisSidebar = wpdDocument.getElementById("axes-calibration-sidebar");

	// insert table axis calibration sidebar
	axisSidebar.insertAdjacentHTML(
		"beforebegin",
		`
		<div id="bar-header-metadata-sidebar" class="sidebar" style="display: none; height: 303px;">
			<p class="sidebar-title">Bar Graph Category Labels</p>
			<p>Fill in each category on the categorical axis. When finished, click on "Complete!" to continue.</p>
			<p align="center"><input type="button" value="Complete!" style="width: 120px;" onclick="wpd.alignAxes.completeHeaderMetadata();"></p>
			<span class="error-message"></span>
		</div>`
	);
}

function addBarGraphCategoryCountSidebar(wpdDocument) {
	// get the default calibration sidebar
	const axisSidebar = wpdDocument.getElementById("axes-calibration-sidebar");

	// insert table axis calibration sidebar
	axisSidebar.insertAdjacentHTML(
		"beforebegin",
		`
		<div id="bar-header-count-sidebar" class="sidebar" style="display: none; height: 303px;">
			<p class="sidebar-title">Bar Graph Category Count</p>
			<p>
				Enter the number of categories in the categorical axis of the bar graph.
				Drag and drop the dividers to match the span of each category. When finished, click on "Complete!" to continue.
			</p>
			<p align="center">
				<input
					value="1"
					min="1"
					step="1"
					class="bar-header-category-count"
					id="bar-header-category-count"
					type="number"
					style="width: 30px;"
					onchange="wpd.alignAxes.changeCategoryCount();"
				/>
				<br>
				<span class="error-message"></span>
			</p>
			<p align="center"><input type="button" value="Complete!" style="width: 120px;" onclick="wpd.alignAxes.completeCategoryCount();"></p>
		</div>`
	);
}

function addBarHeaderCalibrationSidebar(wpdDocument) {
	// get the default calibration sidebar
	const axisSidebar = wpdDocument.getElementById("axes-calibration-sidebar");

	// insert table axis calibration sidebar
	axisSidebar.insertAdjacentHTML(
		"beforebegin",
		`
		<div id="bar-header-calibration-sidebar" class="sidebar" style="display: none; height: 303px;">
			<p class="sidebar-title">Bar Graph Category Axis</p>
			<p>Click points to select and use cursor keys to adjust positions. Use Shift+Arrow for faster movement. Click complete when finished.</p>
			<p align="center"><input type="button" value="Complete!" style="width: 120px;" onclick="wpd.alignAxes.completeHeaderCorners();"></p>
		</div>`
	);
}

/**
 * Default WPD XY axis no axis rotation to checked and update checkbox label.
 */
function updateXYAxisRotation(wpdDocument) {
	const checkbox = wpdDocument.getElementById("xy-axes-no-rotation");

	// check the checkbox
	checkbox.checked = true;

	// update the label text
	checkbox.parentElement.lastChild.replaceWith(
		"This document is properly aligned (computer generated or well-scanned)."
	);
}

/**
 * Add IDs to page navigation previous and next buttons.
 */
function addIDsToPageNavigationButtons(wpdDocument) {
	const imageAndPageNavbar = wpdDocument.getElementById("imageAndPageNavbar");

	imageAndPageNavbar
		.querySelector(".paged input[value='Previous']")
		.setAttribute("id", "previous-page-button");
	imageAndPageNavbar
		.querySelector(".paged input[value='Next']")
		.setAttribute("id", "next-page-button");
}

/**
 * Add wrapper for graphicsContainer and center toolbar. Moves graphicsContainer.
 */
function addCenterContainer(wpdDocument) {
	const graphicsContainer = wpdDocument.getElementById("graphicsContainer");

	graphicsContainer.insertAdjacentHTML(
		"beforebegin",
		`<div id="center-container"></div>`
	);

	// move graphicsContainer into new element
	wpdDocument.getElementById("center-container").append(graphicsContainer);
}

/**
 * Add center toolbar container, just below the graphics container.
 */
function addCenterToolbarContainer(wpdDocument) {
	const graphicsContainer = wpdDocument.getElementById("graphicsContainer");

	// define tag group selection inputs
	const tagGroupSelect = `
		<div style="text-align: start; display: none;" class="combobox">
			<input
				type="text"
				title="To begin collecting data points, select a tag group"
				class="tag-group-combobox-input dropdown-input"
				placeholder="Search for data type"
				autocomplete="off"
			/>
		</div>
	`;

	graphicsContainer.insertAdjacentHTML(
		"afterend",
		`
			<div id="center-toolbar-container">
				<div id="center-toolbar-content">
					<div class="name">
						<div class="title">
							Tag group
						</div>
						<div class="content">
							${tagGroupSelect}
						</div>
					</div>
					<div class="tags">
						<div class="title"></div>
						<div class="content"></div>
					</div>
					<div class="data">
						<div class="title">
							Collected data
						</div>
						<div class="content"></div>
					</div>
				</div>
				<div id="center-toolbar-border" class="draggable horizontal">
					<div class="grip horizontal"></div>
				</div>
			</div>
		`
	);
}

/**
 * Add data deletion confirmation popup
 */
function addClearAllConfirmDialog(wpdDocument) {
	wpdDocument.getElementById("point-group-settings-popup").insertAdjacentHTML(
		"afterend",
		`
		<div id="clear-all-tags-confirm-dialog" class="popup" style="width: 650px; left: 533px; top: 60px;">
			<div class="popupheading">Confirmation</div>
			<p>&nbsp;</p>
			<p align="center">Are you sure you want to clear all collected data?</p>
			<p>&nbsp;</p>
			<p align="center">
				<input type="button" value="OK" onclick="wpd.tagGroups.clearTags();">
				<input type="button" value="Cancel" onclick="wpd.tagGroups.closeClearAllDialog();">
			</p>
		</div>`
	);
}

/**
 * Add study element deletion confirmation popup
 */
function addDeleteStudyElementConfirmDialog(wpdDocument) {
	wpdDocument.getElementById("point-group-settings-popup").insertAdjacentHTML(
		"afterend",
		`
		<div id="delete-study-element-confirm-dialog" class="popup" style="width: 650px; left: 533px; top: 60px;">
			<div class="popupheading">Confirmation</div>
			<p>&nbsp;</p>
			<p align="center">Are you sure you want to delete the selected study element from this page?  Associated tagged data and child elements will also be removed.</p>
			<p>&nbsp;</p>
			<p align="center">
				<input type="button" value="OK" onclick="wpd.tree.deleteStudyElement(this);">
				<input type="button" value="Cancel" onclick="wpd.tree.closeDeleteStudyElementPopup();">
			</p>
		</div>`
	);
}

/**
 * Add study element deletion (empty dataset) popup
 * TODO: instead of a popup, just disable the button when there are no elements on the page
 */
function addDeleteStudyElementEmptyDialog(wpdDocument) {
	wpdDocument.getElementById("point-group-settings-popup").insertAdjacentHTML(
		"afterend",
		`
		<div id="delete-study-element-empty-dialog" class="popup" style="width: 650px; left: 533px; top: 60px;">
			<div class="popupheading">Confirmation</div>
			<p>&nbsp;</p>
			<p align="center">No elements deleted; there is no data associated with the selected element on the current page.  Click the element to cycle between pages in which it appears.</p>
			<p>&nbsp;</p>
			<p align="center">
				<input type="button" value="OK" onclick="wpd.tree.closeDeleteStudyElementEmptyPopup(this);">
			</p>
		</div>`
	);
}

/**
 * Add study element edit (non-existent) popup
 * TODO: instead of a popup, just disable the button when there are no elements on the page
 */
function addEditNonExistentElementDialog(wpdDocument) {
	wpdDocument.getElementById("point-group-settings-popup").insertAdjacentHTML(
		"afterend",
		`
		<div id="edit-non-existent-abbreviation-dialog" class="popup" style="width: 650px; left: 533px; top: 60px;">
			<div class="popupheading">Confirmation</div>
			<p>&nbsp;</p>
			<p align="center">The selected element is not present on the current page; click the element to cycle between pages in which it appears.</p>
			<p>&nbsp;</p>
			<p align="center">
				<input type="button" value="OK" onclick="wpd.tree.closeEditNonExistentElementPopup(this);">
			</p>
		</div>`
	);
}

function addEditGraphDatasetDialog(wpdDocument) {
	wpdDocument.getElementById("point-group-settings-popup").insertAdjacentHTML(
		"afterend",
		`
		<div id="edit-graph-dataset-dialog" class="popup" style="width: 650px; left: 533px; top: 60px;">
			<div class="popupheading">Edit Study Branch</div>
			<div id="edit-dataset-study-arm-inputs">
				<div id="edit-graph-dataset-row" class="edit-dataset-row" data-index="0">
					<div class="edit-dataset-row-label">
						Study Branch:
					</div>
					<div class="edit-dataset-row-actions">
						<input
							type="text"
							class="edit-dataset-study-arm-input"
							data-type="composite-key"
							placeholder="Study branch"
						/>

						<input
							type="text"
							class="edit-dataset-tag-group-input"
							data-type="tag-group"
							placeholder="Tag group"
						/>
						<br />
						<span class="error-message"></span>
					</div>
				</div>
			</div>
			<p align="center">
				<input id="edit-dataset-save-button" type="button" value="OK" onclick="wpd.dataSeriesManagement.saveEditDataset(this);">
				<input type="button" value="Close" onclick="wpd.dataSeriesManagement.cancelEditDataset(this);"
			</p>
		</div>`
	);
}

function addAddGraphDatasetDialog(wpdDocument) {
	wpdDocument.getElementById("point-group-settings-popup").insertAdjacentHTML(
		"afterend",
		`
		<div id="add-graph-dataset-dialog" class="popup" style="width: 650px; left: 533px; top: 60px;">
			<div class="popupheading">Add Study Branch</div>
			<div id="add-new-dataset-study-arm-inputs">
				<div id="add-new-dataset-row" class="add-dataset-row" data-index="0">
					<div class="add-dataset-row-label">
						Study Branch:
					</div>
					<div class="add-dataset-row-actions">
						<input
							type="text"
							class="add-dataset-study-arm-input"
							data-type="composite-key"
							placeholder="Study branch"
						/>

						<input
							type="text"
							class="add-dataset-tag-group-input"
							data-type="tag-group"
							placeholder="Tag group"
						/>
						<br />
						<span class="error-message"></span>
					</div>
				</div>
			</div>
			<p align="center">
				<input id="add-new-dataset-save-button" type="button" value="OK" onclick="wpd.dataSeriesManagement.saveAddDataset(this);">
				<input type="button" value="Close" onclick="wpd.dataSeriesManagement.cancelAddDataset(this);"
			</p>
		</div>`
	);
}

function addPdfTextLayerDiv(wpdDocument) {
	wpdDocument.getElementById("canvasDiv").insertAdjacentHTML(
		"afterend",
		`
		<div id="pdf-text-layer" class="textLayer"></div>`
	);
}

function updateZoomFitButton(wpd, wpdDocument) {
	const button = wpdDocument
		.getElementById("topContainer")
		.querySelector("button[title='Fit to graphics area']");

	const toggleTo = {
		height: (element) => {
			element.innerText = "⭥";
			element.title = "Fit to document height";
		},
		width: (element) => {
			element.innerText = "⭤";
			element.title = "Fit to document width";
		},
	};

	// update default zoom fit to width and remove onclick
	toggleTo.width(button);
	button.onclick = "";

	// add click handler
	const handler = (event) => {
		const button = event.target;

		let mode;
		switch (button.innerText) {
			case "⭥":
				mode = "height";

				// toggle button text
				toggleTo.width(button);
				break;
			case "⭤":
			default:
				mode = "width";

				// toggle button text
				toggleTo.height(button);
		}

		wpd.graphicsWidget.zoomFit(mode);
	};

	button.removeEventListener("click", handler);
	button.addEventListener("click", handler);
}

export { apply };
