import { stringify } from "uuid";

/**
 * Posts events from the iframe to the main window.
 * @param {Object} wpdWindow - Main window object
 */
function forwardEvents(wpdWindow) {
	const handler = (event) => {
		window.postMessage(event.type);
	};

	wpdWindow.removeEventListener("mousemove", handler);
	wpdWindow.removeEventListener("scroll", handler);
	wpdWindow.removeEventListener("keydown", handler);
	wpdWindow.removeEventListener("resize", handler);

	wpdWindow.addEventListener("mousemove", handler);
	wpdWindow.addEventListener("scroll", handler);
	wpdWindow.addEventListener("keydown", handler);
	wpdWindow.addEventListener("resize", handler);
}

/**
 * Handles left sidebar resize
 */
function leftSidebarResizeEvents(wpdWindow, wpdDocument) {
	const sidebar = wpdDocument.getElementById("left-side-container");
	const sidebarContent = sidebar.children[0];
	const hitBox = wpdDocument.getElementById("left-sidebar-border");

	let mouseDown = false;

	// remove sidebar flex-basis and set width to the original flex-basis
	sidebar.style.flex = "0 0 auto";
	sidebar.style.width = "200px";

	const mouseDownHandler = (event) => {
		event.preventDefault();

		mouseDown = true;
	};
	const mouseUpHandler = (event) => {
		event.preventDefault();

		mouseDown = false;
	};

	const mouseMoveHandler = (event) => {
		event.preventDefault();

		if (mouseDown) {
			if (event.pageX >= 200) {
				// update left sidebar width
				sidebar.style.width = `${event.pageX}px`;
				sidebarContent.style.width = `${event.pageX - 4}px`;

				wpdWindow.dispatchEvent(new Event("resize"));
			}
		}
	};

	hitBox.removeEventListener("mousedown", mouseDownHandler);
	hitBox.addEventListener("mousedown", mouseDownHandler);

	wpdDocument.removeEventListener("mouseup", mouseUpHandler);
	wpdDocument.addEventListener("mouseup", mouseUpHandler);

	wpdDocument.removeEventListener("mousemove", mouseMoveHandler);
	wpdDocument.addEventListener("mousemove", mouseMoveHandler);
}

/**
 * Handles center toolbar resize
 */
function centerToolbarResizeEvents(wpdWindow, wpdDocument) {
	const toolbar = wpdDocument.getElementById("center-toolbar-container");
	const toolbarContent = toolbar.children[0];
	const hitBox = wpdDocument.getElementById("center-toolbar-border");

	let mouseDown = false;

	const mouseDownHandler = (event) => {
		event.preventDefault();

		mouseDown = true;
	};
	const mouseUpHandler = (event) => {
		event.preventDefault();

		mouseDown = false;
	};

	const mouseMoveHandler = (event) => {
		event.preventDefault();

		if (mouseDown) {
			// calculate minimum y-value
			// this toolbar should not ever have a scrollTop
			const { bottom } = toolbar.getBoundingClientRect();
			const min = bottom - 125;

			if (event.pageY <= min) {
				const height = bottom - event.pageY;
				// update left toolbar height
				toolbar.style.height = `${height}px`;
				toolbarContent.style.height = `${height}px`;

				wpdWindow.dispatchEvent(new Event("resize"));
			}
		}
	};

	hitBox.removeEventListener("mousedown", mouseDownHandler);
	hitBox.addEventListener("mousedown", mouseDownHandler);

	wpdDocument.removeEventListener("mouseup", mouseUpHandler);
	wpdDocument.addEventListener("mouseup", mouseUpHandler);

	wpdDocument.removeEventListener("mousemove", mouseMoveHandler);
	wpdDocument.addEventListener("mousemove", mouseMoveHandler);
}

/**
 * Handles center toolbar resize
 */
function studyTreeResizeEvents(wpdDocument) {
	const studies = wpdDocument.getElementById("study-tree-display");
	const hitBox = wpdDocument.getElementById("study-tree-border");

	let mouseDown = false;

	const mouseDownHandler = (event) => {
		event.preventDefault();

		mouseDown = true;
	};
	const mouseUpHandler = (event) => {
		event.preventDefault();

		mouseDown = false;
	};

	const mouseMoveHandler = (event) => {
		event.preventDefault();

		if (mouseDown) {
			// calculate minimum y-value
			const { bottom } = studies.getBoundingClientRect();
			const min = bottom - 125;

			if (event.pageY <= min) {
				const height = bottom - event.pageY;
				// update left toolbar height
				studies.style.height = `${height - 5}px`;
			}
		}
	};

	hitBox.removeEventListener("mousedown", mouseDownHandler);
	hitBox.addEventListener("mousedown", mouseDownHandler);

	wpdDocument.removeEventListener("mouseup", mouseUpHandler);
	wpdDocument.addEventListener("mouseup", mouseUpHandler);

	wpdDocument.removeEventListener("mousemove", mouseMoveHandler);
	wpdDocument.addEventListener("mousemove", mouseMoveHandler);
}

/**
 * Handles navigation events
 */
function studyNavigationEvents(wpd, wpdDocument, dataEntryVM) {
	const selectDataPoint = async (event) => {
		const data = event.detail;

		if (dataEntryVM.rawJsonViewerEnabled && data.tagGroupInstanceUuid) {
			Object.assign(
				data,
				wpd.utils.findDetailsByTagGroupInstanceUuid(
					data.tagGroupInstanceUuid
				)
			);
		}

		// switch file
		const fileManager = wpd.appData.getFileManager();
		const datasetFileMap = fileManager.getDatasetNameMap();
		const currentFileIndex = fileManager.currentFileIndex();
		const datasetFileIndex = datasetFileMap[data.datasetName];
		if (currentFileIndex !== datasetFileIndex) {
			await fileManager.switch(datasetFileIndex);
		}

		// switch page
		if (wpd.appData.isMultipage()) {
			const pageManager = wpd.appData.getPageManager();
			const currentPage = pageManager.currentPage();
			if (currentPage != data.page) {
				await pageManager.switch(data.page);
			}
		}

		wpd.tagGroups.refreshAll();

		// for tables, open the table containing the erroneous data
		// for other data points and study branches, open the metadata dialog
		if (dataEntryVM.rawJsonViewerEnabled) {
			if (data.type === "table") {
				wpd.tree.selectPath("/Table");
				wpd.tree.switchDataset(data.datasetName);
			} else if (data.type === "image" || data.type === "branch") {
				wpd.tree.selectPath("/Text");
				wpd.tree.switchDataset(data.datasetName);
				const dataset = wpd.tree.getActiveDataset();
				const axis = wpd.appData
					.getPlotData()
					.getAxesForDataset(dataset);

				if (data.x && data.y) {
					if (data.type === "branch") {
						const dataPointIndex = dataset?.findNearestPixel(
							data.x,
							data.y
						);
						wpd.tagGroups.loadDataPointTagGroup(dataPointIndex);
						const index = dataset.addPixel();
						await wpd.custom.processDataPoint(
							dataset,
							index,
							axis,
							true,
							true
						);
					} else {
						const index = dataset?.findNearestPixel(data.x, data.y);
						dataset.selectPixel(index);
						await wpd.custom.processDataPoint(dataset, index, axis);
					}
				} else {
					// find and load the tag group
					const tagGroup = dataset.findTagGroup(
						data.tagGroupInstanceUuid
					);
					wpd.tagGroups.loadTagGroup(tagGroup);
				}
			} else {
				// assume graph types here
				wpd.tree.selectPath("/Graph");
				wpd.tree.switchDataset(data.datasetName);
			}
		} else {
			if (data.type === "table") {
				wpd.tree.selectPath("/Table");
				wpd.tree.switchDataset(data.datasetName);
				const axisTree = wpd.tree.getAxisTree();
				axisTree.selectPath(`/${data.axesName}`);
			} else {
				wpd.tree.onTextSelection();
				wpd.tree.switchDataset(data.datasetName);
				const dataset = wpd.tree.getActiveDataset();
				const axis = wpd.appData
					.getPlotData()
					.getAxesForDataset(dataset);

				if (data.type === "branch") {
					const dataPointIndex = dataset?.findNearestPixel(
						data.x,
						data.y
					);
					wpd.tagGroups.loadDataPointTagGroup(dataPointIndex);
					const index = dataset.addPixel();
					await wpd.custom.processDataPoint(
						dataset,
						index,
						axis,
						true,
						true
					);
				} else {
					const index = dataset?.findNearestPixel(data.x, data.y);
					dataset.selectPixel(index);
					await wpd.custom.processDataPoint(dataset, index, axis);
				}
			}
		}
	};

	wpdDocument.removeEventListener("select-data-point", selectDataPoint);
	wpdDocument.addEventListener("select-data-point", selectDataPoint);
}

function apply(wpd, wpdWindow, wpdDocument, dataEntryVM) {
	// forward events to the parent frame
	forwardEvents(wpdWindow);

	// handler for left sidebar resize functionality
	leftSidebarResizeEvents(wpdWindow, wpdDocument);

	// handler for center toolbar resize functionality
	centerToolbarResizeEvents(wpdWindow, wpdDocument);

	// handler for study list resize functionality
	studyTreeResizeEvents(wpdDocument);

	// handler for study navigation
	studyNavigationEvents(wpd, wpdDocument, dataEntryVM);
}

export { apply };
