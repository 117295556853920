<template>
	<v-card class="d-flex flex-column pb-5">
		<v-toolbar
			color="primary"
			class="pr-3"
			dark
			flat
		>
			<v-toolbar-title>
				{{
					showDownload && downloadMode
						? "Select papers to download"
						: title
				}}
			</v-toolbar-title>

			<v-spacer v-if="!queueMode" />

			<v-btn
				v-if="!queueMode"
				color="grey-lighten-5"
				class="mr-3 bg-white text-primary"
				elevation="2"
				@click="displayDeleted = !displayDeleted"
			>
				<template v-if="!displayDeleted">
					<v-icon start> mdi-eye </v-icon>

					Show deleted
				</template>

				<template v-else>
					<v-icon start> mdi-eye-off </v-icon>

					Hide deleted
				</template>
			</v-btn>

			<v-btn
				v-if="!queueMode && showDownload && downloadMode"
				color="secondary"
				class="mr-3 bg-secondary text-white"
				elevation="2"
				:disabled="downloads.length < 1"
				@click="downloadPaperData"
			>
				<v-icon start> mdi-download </v-icon>

				Download selected
			</v-btn>

			<v-btn
				v-if="!queueMode && showDownload"
				:class="
					downloadMode
						? 'mr-3 bg-primary text-white'
						: 'mr-3 bg-white text-primary'
				"
				elevation="2"
				@click="downloadMode = !downloadMode"
			>
				<v-icon
					v-if="!downloadMode"
					start
				>
					mdi-download
				</v-icon>

				{{ downloadMode ? "Cancel download" : "Download data" }}
			</v-btn>

			<v-btn
				v-if="!queueMode"
				color="grey-lighten-5"
				to="/home/file-manager"
				class="mr-3 bg-white text-primary"
				elevation="2"
				:disabled="downloadMode"
			>
				<v-icon start> mdi-file-document </v-icon>

				View all files
			</v-btn>

			<v-dialog
				v-if="!queueMode"
				v-model="dialog"
				max-width="800px"
				transition="fade-transition"
				@click:outside="clearSelectedPaper"
			>
				<template #activator="{ props }">
					<v-btn
						v-bind="props"
						class="bg-secondary text-white"
						elevation="2"
						:disabled="downloadMode"
					>
						<v-icon start> mdi-plus </v-icon>

						Add new paper
					</v-btn>
				</template>

				<paper-edit
					ref="paperEdit"
					:default-paper="selectedPaper"
					:dois="dois"
					:pmids="pmids"
					:pmcids="pmcids"
					@cancel-paper-edit="closeDialog"
					@refresh="closeDialogAndRefresh"
				/>
			</v-dialog>

			<v-dialog
				v-if="!queueMode"
				v-model="deleteDialog"
				max-width="800px"
				transition="fade-transition"
				@click:outside="clearSelectedPaper"
			>
				<v-card>
					<v-toolbar
						color="primary"
						dark
						flat
					>
						<v-toolbar-title>Delete paper</v-toolbar-title>
						<v-hover-icon class="mr-3" />
					</v-toolbar>

					<v-card-title class="text-center py-4">
						Are you sure you want to delete this paper?
					</v-card-title>

					<v-divider />

					<v-card-subtitle class="text-center py-4">
						{{ selectedPaper.name }}
					</v-card-subtitle>

					<v-divider />

					<v-card-actions class="py-5">
						<v-spacer />

						<v-btn
							class="bg-secondary text-white"
							elevation="2"
							@click="deletePaper"
						>
							Yes
						</v-btn>

						<v-btn
							class="bg-grey"
							elevation="2"
							@click="closeDeleteDialog"
						>
							No
						</v-btn>

						<v-spacer />
					</v-card-actions>
				</v-card>
			</v-dialog>
			<v-dialog
				v-model="restoreDialog"
				max-width="800px"
				transition="fade-transition"
			>
				<v-card>
					<v-toolbar
						color="primary"
						dark
						flat
					>
						<v-toolbar-title>Restore paper</v-toolbar-title>
						<v-hover-icon class="mr-3" />
					</v-toolbar>

					<v-card-title class="text-center py-4">
						Are you sure you want to restore this paper?
					</v-card-title>

					<v-divider />

					<v-card-subtitle class="text-center py-4">
						{{ selectedPaper.name }}
					</v-card-subtitle>

					<v-divider />

					<v-card-actions class="py-5">
						<v-spacer />
						<v-btn
							class="bg-secondary text-white"
							elevation="2"
							@click="restorePaper"
						>
							Yes
						</v-btn>
						<v-btn
							class="bg-grey"
							elevation="2"
							@click="closeRestoreDialog"
						>
							No
						</v-btn>
						<v-spacer />
					</v-card-actions>
				</v-card>
			</v-dialog>
		</v-toolbar>
		<v-text-field
			v-if="searchEnabled"
			v-model="searchKey"
			prepend-inner-icon="mdi-magnify"
			label="Search papers"
			density="comfortable"
			clearable
			class="paper-search mx-4 mt-4"
			hide-details
			max-width="280px"
		/>
		<v-data-table-server
			v-model="downloads"
			v-model:options="options"
			v-model:items-per-page="options.itemsPerPage"
			v-model:page="options.page"
			class="paper-table"
			item-key="uuid"
			:row-props="getRowProps"
			:headers="headers"
			:items="papers"
			:items-length="totalPapers"
			:loading="loading"
			:disable-sort="queueMode"
			:show-select="showSelect"
			return-object
			:disable-pagination="sdeQueueMode || trainingQueueMode"
			:hide-default-footer="sdeQueueMode || trainingQueueMode"
		>
			<template
				v-if="queueMode && isAdmin"
				#item.queue_admin_actions="{ item }"
			>
				<v-tooltip
					location="top"
					open-delay="500"
				>
					<template #activator="{ props }">
						<v-text-field
							v-bind="props"
							v-model="item.priority"
							class="mt-0 pt-0 paper-table-priority-text"
							type="number"
							density="compact"
							hide-details
							@change="setPriority(item)"
						/>
					</template>
					<span>Assign priority</span>
				</v-tooltip>
			</template>

			<template #item.name="{ item }">
				<span v-if="item.name">
					{{ item.name }}
				</span>

				<span
					v-else
					class="font-italic text--disabled"
				>
					Unnamed
				</span>
			</template>

			<template #item.projects="{ item }">
				<div class="d-flex justify-center flex-wrap my-2">
					<v-chip
						v-for="project of item.projects"
						:key="project.uuid"
						size="x-small"
					>
						{{ project.name }}
					</v-chip>

					<v-tooltip
						v-if="!item.projects.length"
						location="bottom"
						open-delay="500"
					>
						<template #activator="{ props }">
							<v-icon
								v-bind="props"
								color="error"
								class="cursor-default"
							>
								mdi-alert-circle
							</v-icon>
						</template>

						<span>No associated projects</span>
					</v-tooltip>
				</div>
			</template>

			<template #item.file_count="{ item }">
				<v-menu
					v-if="!queueMode && item.files.length > 0"
					close-on-content-click
				>
					<template #activator="{ props }">
						<a
							v-bind="props"
							class="text-decoration-underline text-primary cursor-pointer"
						>
							{{ getFileCount(item) }}
						</a>
					</template>

					<v-list density="compact">
						<v-list-item
							v-for="file in item.files"
							:key="file.id"
						>
							<a
								class="text-decoration-underline cursor-pointer text-primary"
								@click="downloadFile(file)"
							>
								{{ file.name }}
							</a>
						</v-list-item>
					</v-list>
				</v-menu>

				<div v-else>
					{{ getFileCount(item) }}
				</div>
			</template>

			<template #item.created_at="{ item }">
				{{ getCreatedTimestamp(item) }}
			</template>

			<template #item.in_progress="{ item }">
				<div class="d-flex justify-center flex-wrap my-2">
					<v-chip
						v-for="dataEntry of inProgressDataEntries(item)"
						:key="dataEntry.uuid"
						size="x-small"
					>
						{{ dataEntry.user.email }}
					</v-chip>
					<v-tooltip
						v-if="!inProgressDataEntries(item).length"
						location="bottom"
						open-delay="500"
					>
						<template #activator="{ props }">
							<v-icon
								v-bind="props"
								class="cursor-default"
								size="large"
							>
								mdi-account-off-outline
							</v-icon>
						</template>

						<span>No extractions in-progress</span>
					</v-tooltip>
				</div>
			</template>

			<template #item.completed="{ item }">
				<div class="d-flex justify-center flex-wrap my-2">
					<v-chip
						v-for="dataEntry of completedDataEntries(item)"
						:key="dataEntry.uuid"
						size="x-small"
					>
						{{ dataEntry.user.email }}
					</v-chip>
					<v-tooltip
						v-if="!completedDataEntries(item).length"
						location="bottom"
						open-delay="500"
					>
						<template #activator="{ props }">
							<v-icon
								v-bind="props"
								class="cursor-default"
								size="large"
							>
								mdi-account-off-outline
							</v-icon>
						</template>

						<span>No extractions completed</span>
					</v-tooltip>
				</div>
			</template>

			<template
				v-if="!queueMode"
				#item.admin_actions="{ item }"
			>
				<v-tooltip
					v-if="!item.deleted_at"
					location="bottom"
					open-delay="500"
				>
					<template #activator="{ props }">
						<v-hover v-slot="{ hover }">
							<v-icon
								v-bind="props"
								class="mr-2"
								:color="hover ? 'secondary' : ''"
								:disabled="downloadMode"
								:class="{ 'cursor-not-allowed': downloadMode }"
								@click="edit(item)"
							>
								mdi-pencil
							</v-icon>
						</v-hover>
					</template>

					<span>Edit</span>
				</v-tooltip>
				<v-tooltip
					v-if="!item.deleted_at"
					location="bottom"
					open-delay="500"
				>
					<template #activator="{ props }">
						<v-hover v-slot="{ hover }">
							<v-icon
								v-bind="props"
								:color="hover ? 'error' : ''"
								:disabled="downloadMode"
								:class="{ 'cursor-not-allowed': downloadMode }"
								@click="remove(item)"
							>
								mdi-delete
							</v-icon>
						</v-hover>
					</template>

					<span>Delete</span>
				</v-tooltip>

				<v-tooltip
					v-if="item.deleted_at"
					location="bottom"
					open-delay="500"
				>
					<template #activator="{ props }">
						<v-hover v-slot="{ hover }">
							<v-icon
								v-bind="props"
								:color="hover ? 'secondary' : ''"
								:disabled="downloadMode"
								:class="{ 'cursor-not-allowed': downloadMode }"
								@click="restore(item)"
							>
								mdi-restore
							</v-icon>
						</v-hover>
					</template>

					<span>Restore</span>
				</v-tooltip>
			</template>

			<template
				v-else
				#item.queue_actions="{ item }"
			>
				<v-tooltip
					location="bottom"
					open-delay="500"
				>
					<template #activator="{ props }">
						<v-hover
							v-if="getFileCount(item)"
							v-slot="{ hover }"
						>
							<v-icon
								v-bind="props"
								:color="hover ? 'secondary' : ''"
								:disabled="downloadMode"
								:class="{ 'cursor-not-allowed': downloadMode }"
								@click="selectPaper(item)"
							>
								mdi-file-send
							</v-icon>
						</v-hover>

						<v-icon
							v-else
							v-bind="props"
							class="v-icon--disabled cursor-not-allowed"
							:disabled="downloadMode"
						>
							mdi-file-send
						</v-icon>
					</template>

					<span v-if="getFileCount(item)">{{
						qcMode
							? "Begin quality control"
							: "Begin source data extraction"
					}}</span>
					<span v-else>Paper does not have associated files</span>
				</v-tooltip>
			</template>
		</v-data-table-server>
	</v-card>
</template>

<script>
import _ from "lodash";
import he from "he";
import fileDownload from "js-file-download";
import { useLDFlag } from "launchdarkly-vue-client-sdk";
import { v4 as uuidv4 } from "uuid";

import { mapMutations } from "vuex";

import PaperEdit from "@/pages/PaperManager/PaperEdit";
import VHoverIcon from "@/components/VHoverIcon";

export default {
	name: "PaperTable",

	components: {
		PaperEdit,
		VHoverIcon,
	},

	props: {
		training: Boolean,
		qcMode: Boolean,
		adminQueueMode: Boolean,
		sdeQueueMode: Boolean,
		trainingQueueMode: Boolean,
	},

	setup() {
		const searchEnabled = useLDFlag("enable-paper-table-search");
		return {
			searchEnabled,
		};
	},

	data() {
		return {
			deleteDialog: false,
			dialog: false,
			displayDeleted: false,
			downloads: [],
			downloadMode: false,
			fileDialog: false,
			loading: false,
			isAdmin: false,
			options: {
				itemsPerPage: 10,
				page: 1,
			},
			papers: [],
			restoreDialog: false,
			selectedPaper: {},
			showDownload: false,
			showSelect: false,
			totalPapers: 0,
			searchKey: "",
			queueMode:
				this.adminQueueMode ||
				this.sdeQueueMode ||
				this.trainingQueueMode ||
				this.qcMode,
			allHeaders: [
				{
					title: "Priority",
					value: "queue_admin_actions",
					align: "center",
					sortable: false,
					width: "50px",
					modes: ["queue-admin"],
				},
				{
					title: "Paper name",
					value: "name",
					align: "left",
					modes: ["admin", "queue", "queue-admin", "training"],
				},
				{
					title: "DOI",
					value: "doi",
					width: "200px",
					modes: ["admin"],
				},
				{
					title: "Projects",
					value: "projects",
					align: "center",
					width: "150px",
					modes: ["admin", "queue", "queue-admin"],
				},
				{
					title: "File count",
					value: "file_count",
					align: "center",
					width: "100px",
					modes: ["admin", "queue", "queue-admin", "training"],
				},
				{
					title: "Created at",
					value: "created_at",
					align: "center",
					divider: true,
					width: "200px",
					modes: ["admin", "queue", "queue-admin", "training"],
				},
				{
					title: "In-progress",
					value: "in_progress",
					align: "center",
					divider: true,
					width: "200px",
					modes: ["queue", "queue-admin"],
				},
				{
					title: "Completed",
					value: "completed",
					align: "center",
					divider: true,
					width: "200px",
					modes: ["queue", "queue-admin"],
				},
				{
					title: "Actions",
					value: "admin_actions",
					align: "center",
					width: "95px",
					sortable: false,
					modes: ["admin"],
				},
				{
					title: "Actions",
					value: "queue_actions",
					align: "center",
					width: "80px",
					sortable: false,
					modes: ["queue", "queue-admin", "training"],
				},
			],
		};
	},

	computed: {
		dois() {
			return this.papers.map((paper) => paper.doi);
		},
		pmids() {
			return this.papers.map((paper) => paper.pmid);
		},
		pmcids() {
			return this.papers.map((paper) => paper.pmcid);
		},
		headers() {
			const headers = this.allHeaders.filter((header) =>
				header.modes.includes(this.mode)
			);

			return headers;
		},
		mode() {
			let mode = "admin";

			if (this.training) {
				mode = "training";
			} else if (this.queueMode) {
				if (this.isAdmin) {
					mode = "queue-admin";
				} else {
					mode = "queue";
				}
			}

			return mode;
		},
		paper() {
			return this.qcMode
				? this.$store.state.qcPaper
				: this.$store.state.paper;
		},
		title() {
			return this.queueMode ? "Work Queue" : "Papers";
		},
		user() {
			return this.$store.getters.user;
		},
	},

	watch: {
		options: {
			deep: true,
			handler() {
				this.sdeQueueMode
					? this.getExtractionQueue()
					: this.getPapers();
			},
		},
		downloadMode(val) {
			if (val) {
				this.showSelect = true;
			} else {
				this.showSelect = false;
				this.downloads = [];
			}
		},
		displayDeleted() {
			this.options.page = 1;
			this.getPapers();
		},
		searchKey(oldVal, newVal) {
			if (newVal.length >= 2 || oldVal.length >= 2) {
				this.options.page = 1;
				this.sdeQueueMode
					? this.getExtractionQueue()
					: this.getPapers();
			}
		},
	},

	mounted() {
		this.checkUserDownloadPermission();

		// default sort order when not in queue mode
		if (!this.queueMode) {
			this.options.sortBy = ["created_at"];
			this.options.sortDesc = [true];
		}
	},

	methods: {
		...mapMutations([
			"setPaper",
			"setQCPaper",
			"setSaveUUID",
			"showNotification",
		]),
		getRowProps(paper) {
			return {
				class: this.isDeleted(paper),
			};
		},
		isDeleted(paper) {
			return paper.deleted_at ? "deleted" : "";
		},
		getFileCount(paper) {
			// filter out save data
			const count = _.filter(paper.files, (doc) => {
				return doc.mime_type !== "application/json";
			}).length;

			return count;
		},
		getCreatedTimestamp(paper) {
			return new Date(paper.created_at).toLocaleString();
		},
		checkUserDownloadPermission() {
			this.$http
				.get(`/users/${this.user.uuid}/can-all`, {
					params: {
						permissions: ["download_data"],
					},
				})
				.then((response) => {
					if (response.data.allowed) {
						this.showDownload = true;
					} else {
						this.showDownload = false;
					}
				})
				.catch(() => {
					// do not display errors on these permission checks
					this.showDownload = false;
				});
		},
		checkUserEditPermission(rawPapers) {
			return this.$http
				.get(`/users/${this.user.uuid}/can-all`, {
					params: {
						permissions: ["delete_file", "upload_file"],
					},
				})
				.then((response) => {
					if (response.data.allowed) {
						this.isAdmin = true;
					} else {
						this.isAdmin = false;
					}

					this.papers = rawPapers;
				})
				.catch(() => {
					// do not display errors on these permission checks
					this.isAdmin = false;
				});
		},
		clearSelectedPaper() {
			setTimeout(() => {
				this.selectedPaper = {};

				if (this.$refs.paperEdit) {
					this.$refs.paperEdit.clear();
				}
			}, 300);
		},
		remove(item) {
			this.selectedPaper = Object.assign({}, item);

			this.deleteDialog = true;
		},
		closeDeleteDialog() {
			this.deleteDialog = false;

			this.clearSelectedPaper();
		},
		restore(item) {
			this.selectedPaper = Object.assign({}, item);

			this.restoreDialog = true;
		},
		closeRestoreDialog() {
			this.restoreDialog = false;

			this.clearSelectedPaper();
		},
		deletePaper() {
			this.loading = true;

			if (this.selectedPaper) {
				this.$http
					.delete(`/papers/${this.selectedPaper.uuid}`)
					.then((response) => {
						// display success
						this.showNotification(response.data);

						// refresh table
						this.getPapers();
					})
					.catch((error) => {
						// display error
						if (error.response) {
							if (error.response.data.message) {
								this.showNotification(error.response.data);
							} else {
								this.showNotification(
									error.response.data.error
								);
							}
						}

						this.loading = false;
					});
			} else {
				this.loading = false;
			}

			this.closeDeleteDialog();
		},
		restorePaper() {
			this.loading = true;

			if (this.selectedPaper) {
				this.$http
					.put(`/papers/${this.selectedPaper.uuid}`, {
						deletedAt: null,
					})
					.then((response) => {
						this.showNotification(response.data);
						this.getPapers();
					})
					.catch((error) => {
						if (error.response) {
							if (error.response.data.message) {
								this.showNotification(error.response.data);
							} else {
								this.showNotification(
									error.response.data.error
								);
							}
						}

						this.loading = false;
					});
			} else {
				this.loading = false;
			}

			this.closeRestoreDialog();
		},
		edit(item) {
			this.selectedPaper = Object.assign({}, item);

			this.dialog = true;
		},
		closeDialog() {
			this.dialog = false;

			setTimeout(() => {
				this.clearSelectedPaper();
			}, 250);
		},
		closeDialogAndRefresh() {
			this.closeDialog();

			this.$nextTick(() => {
				this.getPapers();
			});
		},
		getPapers() {
			this.loading = true;

			let filter = undefined;
			if (this.qcMode) {
				filter = "readyForQC";
			}

			const { itemsPerPage, page, sortBy, sortDesc } = this.options;
			this.$http
				.get("/papers", {
					params: {
						...(this.displayDeleted ? { deletedOnly: true } : {}),
						...(!this.displayDeleted ? { omitDeleted: true } : {}),
						...(this.training ? { trainingOnly: true } : {}),
						filter: filter,
						itemsPerPage: itemsPerPage,
						page: page,
						searchKey:
							this.searchKey && this.searchKey.length >= 2
								? this.searchKey
								: "",
						sortBy: sortBy,
						sortDesc: sortDesc,
					},
				})
				.then(async (response) => {
					// only check access level in queue mode
					if (this.queueMode) {
						await this.checkUserEditPermission(
							response.data.papers.data
						);
					} else {
						this.papers = response.data.papers.data;
					}

					this.papers.forEach((paper) => {
						const dataEntries = paper.dataEntries;
						if (!_.isEmpty(dataEntries)) {
							const firstEntry = dataEntries.find(
								(de) =>
									de.user.uuid === this.user.uuid &&
									!de.completed_at
							);

							if (firstEntry) {
								paper.nextDataEntry = firstEntry;
							}
						}
					});
					this.totalPapers = parseInt(
						response.data.papers.meta.total,
						10
					);
				})
				.catch((error) => {
					// display error
					if (error.response) {
						if (error.response.data.message) {
							this.showNotification(error.response.data);
						} else {
							this.showNotification(error.response.data.error);
						}
					}
				})
				.finally(() => {
					this.loading = false;
				});
		},
		getExtractionQueue() {
			this.loading = true;
			this.$http
				.get("/papers/extraction-queue", {
					params: {
						searchKey:
							this.searchKey && this.searchKey.length >= 2
								? this.searchKey
								: "",
						userUUID: this.user.uuid,
					},
				})
				.then(async (response) => {
					this.papers = response.data.papers;
					this.papers.forEach((paper) => {
						const dataEntries = paper.dataEntries;
						if (!_.isEmpty(dataEntries)) {
							const firstEntry = dataEntries.find(
								(de) =>
									de.user.uuid === this.user.uuid &&
									!de.completed_at
							);

							if (firstEntry) {
								paper.nextDataEntry = firstEntry;
							}
						}
					});
					this.totalPapers = parseInt(response.data.count, 10);
				})
				.catch((error) => {
					// display error
					if (error.response) {
						if (error.response.data.message) {
							this.showNotification(error.response.data);
						} else {
							this.showNotification(error.response.data.error);
						}
					}
				})
				.finally(() => {
					this.loading = false;
				});
		},
		selectPaper(paper) {
			if (this.qcMode) {
				this.setQCPaper(paper);
				this.startQualityControl(paper);
			} else {
				this.setPaper(paper);
				this.startDataEntry(paper);
			}
		},
		startDataEntry(paper) {
			const sdeUuid = paper.nextDataEntry
				? paper.nextDataEntry.uuid
				: uuidv4();

			this.setSaveUUID(sdeUuid);

			this.$router.push(
				this.training
					? `/data-entry-training/${paper.uuid}/${sdeUuid}`
					: `/data-entry/${paper.uuid}/${sdeUuid}`
			);
		},
		startQualityControl() {
			// TODO get in-progress quality control work
			this.$router.push("/quality-control");
		},

		setPriority(paper) {
			this.$http
				.put(`papers/${paper.uuid}`, { priority: paper.priority })
				.then(() => {
					// refresh table
					this.getPapers();
				})
				.catch((error) => {
					// display error
					if (error.response) {
						if (error.response.data.message) {
							this.showNotification(error.response.data);
						} else {
							this.showNotification(error.response.data.error);
						}
					}
				});
		},
		downloadFile(file) {
			this.loading = true;

			this.$http
				.get(`/files/download/${file.uuid}`, {
					responseType: "blob",
				})
				.then((response) => {
					const regExp = /filename=(.+).*$/;
					const fileName = he.decode(
						regExp.exec(response.headers["content-disposition"])[1]
					);
					fileDownload(response.data, fileName);
				})
				.catch((error) => {
					if (error.message) {
						this.showNotification({
							message: "File not found",
							status: "error",
						});
					} else if (error.response) {
						if (error.response.data.message) {
							this.showNotification(error.response.data);
						} else {
							this.showNotification(error.response.data.error);
						}
					}
				})
				.finally(() => {
					this.loading = false;
				});
		},
		downloadPaperData() {
			this.$http
				.get("/papers/download", {
					params: {
						paperUUIDs: this.downloads.map((paper) => paper.uuid),
					},
					responseType: "blob",
				})
				.then((response) => {
					const regExp = /filename=(.+).*$/;
					const fileName = he.decode(
						regExp.exec(response.headers["content-disposition"])[1]
					);
					fileDownload(response.data, fileName);
				})
				.catch((error) => {
					// display error
					if (error.message) {
						this.showNotification({
							message: "File not found",
							status: "error",
						});
					} else if (error.response) {
						if (error.response.data.message) {
							this.showNotification(error.response.data);
						} else {
							this.showNotification(error.response.data.error);
						}
					}
				})
				.finally(() => {
					this.downloadMode = false;
					this.loading = false;
				});
		},
		inProgressDataEntries(paper) {
			return _.uniqBy(
				paper.dataEntries.filter(
					(de) =>
						!de.completed_at &&
						!de.user.isAdmin &&
						!de.user.deleted_at
				),
				"user_id"
			);
		},
		completedDataEntries(paper) {
			return _.uniqBy(
				paper.dataEntries.filter(
					(de) => !!de.completed_at && !de.user.isAdmin
				),
				"user_id"
			);
		},
	},
};
</script>

<style scoped lang="scss">
:deep(.paper-table) {
	.v-icon--disabled {
		pointer-events: all !important;
	}

	&.v-data-table .v-table__wrapper td {
		font-size: 12px;
	}

	&.v-data-table .v-table__wrapper th {
		font-size: 12px;
		font-weight: 600;
		color: rgba(0, 0, 0, 0.6);
		height: 48px;
	}
}

:deep(.paper-table-priority-text) {
	width: 50px;
	height: 100% !important;
	font-size: 14px !important;
}

:deep(.paper-search .v-field__outline::before) {
	border-color: rgba(0, 0, 0, 0.5);
}
</style>
