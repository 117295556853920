<template>
	<v-dialog
		v-model="dialog"
		class="raw-data-viewer"
		transition="dialog-bottom-transition"
		z-index="2000"
		fullscreen
	>
		<v-card>
			<v-toolbar
				color="primary"
				dark
				flat
			>
				<v-toolbar-title>
					{{ title }}
				</v-toolbar-title>

				<v-spacer />

				<v-row
					align="center"
					justify="end"
					class="pr-2"
				>
					<template v-if="isAdmin && jsonEditor">
						<v-col cols="auto">
							<v-btn
								variant="elevated"
								color="secondary"
								class="text-white"
								prepend-icon="mdi-content-save"
								:disabled="!dirty || !$refs.jsonEditor?.isValid"
								@click="updateJson"
							>
								Update JSON

								<v-tooltip
									location="bottom"
									activator="parent"
								>
									Note: Only applies, does not save
								</v-tooltip>
							</v-btn>
						</v-col>

						<v-col
							cols="auto"
							class="mr-5"
						>
							<v-btn
								variant="elevated"
								color="secondary"
								class="text-white"
								prepend-icon="mdi-code-json"
								@click="hideRawJsonEditor"
							>
								<v-badge
									color="accent"
									icon="mdi-shield-account"
									offset-x="-7"
									offset-y="-3"
									floating
								>
									Toggle JSON editor
								</v-badge>

								<v-tooltip
									location="bottom"
									activator="parent"
								>
									Admin only
								</v-tooltip>
							</v-btn>
						</v-col>
					</template>

					<template v-else>
						<v-col cols="auto">
							<v-btn
								variant="elevated"
								color="secondary"
								class="text-white"
								prepend-icon="mdi-expand-all"
								:disabled="isAllExpanded"
								@click="expandAllPanels"
							>
								Expand all
							</v-btn>
						</v-col>

						<v-col cols="auto">
							<v-btn
								variant="elevated"
								color="secondary"
								class="text-white"
								prepend-icon="mdi-collapse-all"
								:disabled="isAllCollapsed"
								@click="collapseAllPanels"
							>
								Collapse all
							</v-btn>
						</v-col>

						<v-col
							v-if="isAdmin"
							cols="auto"
							class="mr-5"
						>
							<v-btn
								variant="elevated"
								color="secondary"
								class="text-white"
								prepend-icon="mdi-code-json"
								@click="showRawJsonEditor"
							>
								<v-badge
									color="accent"
									icon="mdi-shield-account"
									offset-x="-7"
									offset-y="-3"
									floating
								>
									Toggle JSON editor
								</v-badge>

								<v-tooltip
									location="bottom"
									activator="parent"
								>
									Admin only
								</v-tooltip>
							</v-btn>
						</v-col>
					</template>

					<v-divider vertical />

					<v-col cols="auto">
						<v-btn
							icon="mdi-close"
							@click="dialog = false"
						></v-btn>
					</v-col>
				</v-row>
			</v-toolbar>

			<v-col style="overflow-y: auto; flex: 1">
				<v-row v-if="jsonEditor">
					<v-col>
						<v-textarea
							ref="jsonEditor"
							v-model="json"
							bg-color="black"
							class="json-editor"
							validate-on="eager input"
							:rules="[validateJson]"
						/>
					</v-col>
				</v-row>

				<template v-else>
					<v-row>
						<v-col cols="3">
							<v-text-field
								v-model="search"
								label="Filter"
								prepend-inner-icon="mdi-magnify"
								variant="outlined"
								class="ma-3"
								color="secondary"
								density="compact"
								hide-details
								clearable
							></v-text-field>
						</v-col>
					</v-row>

					<v-row>
						<v-col>
							<v-expansion-panels
								v-model="panels"
								multiple
							>
								<v-expansion-panel
									v-for="{
										tagGroupName,
										headers,
										items,
									} in tableData"
									:key="tagGroupName"
									:value="tagGroupName"
									color="grey-lighten-4"
									static
									@group:selected="
										(...args) =>
											panelSelected(tagGroupName, ...args)
									"
								>
									<template #title>
										<h4 class="text-primary mr-10">
											{{ tagGroupName }}
										</h4>

										<span
											v-if="items.length >= itemThreshold"
											class="text-error"
										>
											> {{ itemThreshold }} items ({{
												items.length
											}}
											items)
										</span>

										<v-tooltip
											v-if="items.length >= itemThreshold"
											location="bottom start"
											activator="parent"
										>
											Closes all other tables when
											displayed
										</v-tooltip>
									</template>

									<template #text>
										<v-data-table-virtual
											v-if="panels.includes(tagGroupName)"
											:headers="headers"
											:items="items"
											:search="search"
											:sort-by="sortBy"
											class="raw-data-table"
											fixed-header
											multi-sort
											hover
										>
											<template
												#header.tagGroupInstanceUuid=""
											></template>

											<template
												#item.tagGroupInstanceUuid=""
											></template>

											<template #item.actions="{ item }">
												<v-btn
													icon
													variant="text"
													size="small"
													color="secondary"
													@click="
														loadTagGroup(
															item.tagGroupInstanceUuid
														)
													"
												>
													<v-icon>
														mdi-target-variant
													</v-icon>

													<v-tooltip
														activator="parent"
													>
														Go to tag group
													</v-tooltip>
												</v-btn>
											</template>
										</v-data-table-virtual>
									</template>
								</v-expansion-panel>
							</v-expansion-panels>
						</v-col>
					</v-row>
				</template>
			</v-col>
		</v-card>
	</v-dialog>
</template>

<script>
import _ from "lodash";
import utils from "@/utils";

// priority ordering for study branch headers
const studyBranchOrder = [
	"Study abbreviation",
	"Study part",
	"Study arm abbreviation",
	"Population",
	"Subgroup",
];

export default {
	name: "VRawDataViewer",

	props: {
		modelValue: {
			type: Boolean,
			required: true,
			default: false,
		},
		rawData: {
			type: Object,
			required: true,
			default: () => ({}),
		},
		fileNames: {
			type: Array,
			required: true,
			default: () => [],
		},
		moduleWindow: {
			type: Object,
			required: true,
		},
	},

	emits: ["update:modelValue", "updateJson"],

	setup() {
		return {
			itemThreshold: 500,
			title: "Raw Data Viewer",
		};
	},

	data() {
		return {
			dirty: false,
			isAdmin: false,
			json: "",
			jsonEditor: false,
			panels: [],
			search: "",
			sortBy: studyBranchOrder.map((header) => ({
				key: header,
				order: "asc",
			})),
		};
	},

	computed: {
		dialog: {
			get() {
				return this.modelValue;
			},
			set(newValue) {
				this.$emit("update:modelValue", newValue);
			},
		},
		isAllCollapsed() {
			return this.panels.length === 0;
		},
		isAllExpanded() {
			return (
				this.panels.length ===
				this.tableData.filter(
					(table) => table.items.length <= this.itemThreshold
				).length
			);
		},
		tableData() {
			return this.transformDataToTables(this.rawData);
		},
		user() {
			return this.$store.getters.user;
		},
	},

	watch: {
		dialog(newValue) {
			if (!newValue) {
				this.clearAll();
			}
		},
	},

	mounted() {
		this.isAdminUser();
		this.json = JSON.stringify(this.rawData, null, "\t");
		this.expandAllPanels();
	},

	methods: {
		isAdminUser() {
			return this.$http
				.get(`/users/${this.user.uuid}/is-admin`)
				.then(({ data }) => {
					this.isAdmin = data;
				})
				.catch((error) => {
					console.error(error);
					this.isAdmin = false;
				});
		},
		clearAll() {
			this.dirty = false;
			this.isAdmin = false;
			this.json = "";
			this.jsonEditor = false;
			this.clearSearch();
			this.clearPanels();
		},
		clearSearch() {
			this.search = "";
		},
		clearPanels() {
			this.panels = [];
		},
		expandAllPanels() {
			this.panels = this.tableData
				// do not expand tables with more than threshold items
				.filter((table) => table.items.length <= this.itemThreshold)
				.map((table) => table.tagGroupName);
		},
		collapseAllPanels() {
			this.clearPanels();
		},
		panelSelected(tagGroupName, { value: expanded }) {
			const table = this.tableData.find(
				(table) => table.tagGroupName === tagGroupName
			);
			if (expanded && table.items.length > this.itemThreshold) {
				// if a "large" panel is selected, close all other panels
				this.collapseAllPanels();
				this.panels.push(tagGroupName);
			}
		},
		showRawJsonEditor() {
			this.jsonEditor = true;
		},
		hideRawJsonEditor() {
			this.jsonEditor = false;
		},
		updateJson() {
			this.$emit("updateJson", this.json);
			this.dialog = false;
		},
		validateJson() {
			try {
				JSON.parse(this.json);
				this.checkDirty();
			} catch (error) {
				return error.message;
			}
		},
		checkDirty: _.debounce(function () {
			if (
				JSON.stringify(JSON.parse(this.json)) ===
				JSON.stringify(this.rawData)
			) {
				this.dirty = false;
			} else {
				this.dirty = true;
			}
		}, 500),
		transformDataToTables(rawData) {
			const { datasetColl } = rawData;

			// short-circuit if datasetColl is empty
			if (!Array.isArray(datasetColl) || datasetColl.length === 0) {
				return [];
			}

			// structure:
			//   tablesMap[tagGroupUuid] = {
			//     tagGroupName,
			//     tagGroupUuid,
			//     rows: Map of (instanceUuid => row),
			//   }
			const tablesMap = new Map();

			datasetColl.forEach((dataset) => {
				const page = dataset.page;
				const file = this.fileNames[dataset.file];

				dataset.data.forEach((dataPoint) => {
					const { metadata } = dataPoint;

					if (!metadata) {
						return;
					}

					let mainTagGroupName = null;
					let mainTagGroupUuid = null;
					let mainTagGroupInstanceUuid = null;

					// collect all tag-value pairs from this dataPoint
					const newTagValues = {};

					for (const [key, data] of Object.entries(metadata)) {
						if (typeof data === "object") {
							if (utils.isNumber(key) && data.tag) {
								// tag-value pair
								const tagName = data.tag.name;
								const tagValue = data.value ?? null;

								// collect tag-value pair
								newTagValues[tagName] = tagValue;
							} else if (key === "tagGroup") {
								// tag group info
								// only collect it once per dataPoint
								if (!mainTagGroupUuid) {
									mainTagGroupName = data.name;
									mainTagGroupUuid = data.uuid;
									mainTagGroupInstanceUuid =
										data.instanceUuid;
								}
							}
						}
					}

					// never found a mainTagGroupUuid or instanceUuid, skip
					if (!mainTagGroupUuid || !mainTagGroupInstanceUuid) return;

					// insert/merge into tablesMap
					if (!tablesMap.has(mainTagGroupUuid)) {
						tablesMap.set(mainTagGroupUuid, {
							tagGroupName: mainTagGroupName,
							tagGroupUuid: mainTagGroupUuid,
							rows: new Map(),
						});
					}
					const tableData = tablesMap.get(mainTagGroupUuid);

					// get/create the row for this instanceUuid
					if (!tableData.rows.has(mainTagGroupInstanceUuid)) {
						tableData.rows.set(mainTagGroupInstanceUuid, {});
					}
					const row = tableData.rows.get(mainTagGroupInstanceUuid);

					// merge newTagValues into row
					for (const [tagName, tagValue] of Object.entries(
						newTagValues
					)) {
						row[tagName] = tagValue;
					}

					// set tag group instance uuid, page, and file on each row
					row["tagGroupInstanceUuid"] = mainTagGroupInstanceUuid;
					row["File"] = file;
					row["Page"] = page;
				});
			});

			// build the final array of tables
			const tables = [];

			for (const [
				tagGroupUuid,
				{ tagGroupName, rows },
			] of tablesMap.entries()) {
				// rows is a Map(instanceUuid => {...rowData})

				// gather all distinct header names from these row objects
				const allHeaderNames = new Set();
				for (const row of rows.values()) {
					Object.keys(row).forEach((headerName) => {
						allHeaderNames.add(headerName);
					});
				}

				// convert to array
				let headers = Array.from(allHeaderNames);

				// enforce study-branch ordering at the front
				const orderedStudyBranches = studyBranchOrder.filter((header) =>
					headers.includes(header)
				);
				const locationHeaders = ["File", "Page"];
				const otherHeaders = headers
					.filter(
						(header) =>
							!studyBranchOrder.includes(header) &&
							!locationHeaders.includes(header)
					)
					.sort();

				headers = [...orderedStudyBranches, ...otherHeaders].map(
					(header) => {
						return {
							title: header,
							key: header,
							width: "auto",
						};
					}
				);

				// append actions header
				headers.push(
					...locationHeaders.map((header) => {
						const width = header === "File" ? "150px" : "75px";
						return {
							title: header,
							key: header,
							...(header === "File" ? {} : { align: "center" }),
							width,
						};
					}),
					{
						title: "Actions",
						key: "actions",
						align: "center",
						width: "75px",
						sortable: false,
					}
				);

				// build the items array, skipping “empty” rows
				// i.e., skipping rows where *no* headers outside studyBranchOrder have a non-null value
				const items = [];
				for (const row of rows.values()) {
					// check if row has any non-null header outside studyBranchOrder
					const hasValues = Object.entries(row).some(
						([header, val]) => {
							if (val == null) {
								return false;
							}
							return (
								!studyBranchOrder.includes(header) &&
								!locationHeaders.includes(header) &&
								header !== "tagGroupInstanceUuid"
							);
						}
					);

					if (!hasValues) {
						// skip this row
						continue;
					}

					// otherwise, build the row for the table
					const item = {};
					headers.forEach((header) => {
						item[header.key] = row[header.key] ?? null;
					});
					items.push(item);
				}

				tables.push({
					tagGroupName,
					tagGroupUuid,
					headers,
					items,
				});
			}

			return tables;
		},
		loadTagGroup(tagGroupInstanceUuid) {
			this.dialog = false;

			this.moduleWindow.document.dispatchEvent(
				new CustomEvent("select-data-point", {
					detail: {
						tagGroupInstanceUuid,
					},
				})
			);
		},
	},
};
</script>

<style lang="scss">
.raw-data-viewer {
	.raw-data-table {
		th {
			font-weight: bold !important;
		}

		.v-table__wrapper {
			max-height: 500px;
		}
	}

	.json-editor textarea {
		font-family: "Fira Code Variable", monospace;
		height: 80vh;
	}
}
</style>
